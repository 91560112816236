/**
 * @file: HistoryTableList.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.20 ~
 * @description: 접수내역 테이블 리스트
 */

import { useState } from 'react';
import { IReceiptRouteData } from '@data/receiptHistoryData';
import classNames from 'classnames';
import dayjs from 'dayjs';

import Button from '@components/atom/Button';
import Badge from '@components/atom/Badge';
import HistoryRouteDetail from '@components/organisms/modal/HistoryRouteDetail';

interface IProps extends IReceiptRouteData {
  className?: string;
}

export default function HistoryTableList({
  NO,
  ROUTE_NAME,
  ORDER_NUM,
  ARTICLE_NUM,
  IS_SHOW,
  DELIVERY_STATUS,
  RIDER_IN_CHARGE,
  VEHICLE_NUM,
  // STARTED_AT,
  COMPLETE_AT,
  LIST_ITEM,
  className,
}: IProps) {
  /** 경로 상세 state */
  const [isOpenRouteDetial, setIsOpenRouteDetail] = useState<boolean>(false);

  const detailItem = {
    route_name: ROUTE_NAME,
    list_item: LIST_ITEM,
  };

  return (
    <>
      <div
        className={classNames(
          'flex items-center px-3 text-xs bg-white',
          className,
        )}
      >
        <div className="px-2 py-1 text-xs w-[96px]">{NO}</div>
        <div className="px-2 py-1 text-xs w-[140px]">{''}</div>
        <div className="px-2 py-1 text-xs w-[338px]">{ROUTE_NAME}</div>
        <div className="px-2 py-1 text-xs w-[96px]">{ORDER_NUM}</div>
        <div className="px-2 py-1 text-xs w-[96px]">{ARTICLE_NUM}</div>
        <div className="px-2 py-1 text-xs w-[96px]">
          {IS_SHOW ? (
            <Badge useShowStatus isShow={true} status="노출" />
          ) : (
            <Badge useShowStatus isShow={false} status="미노출" />
          )}
        </div>
        <div className="px-2 py-1 text-xs w-[96px]">
          <Badge useDeliveryStatus status={DELIVERY_STATUS} />
        </div>
        <div className="px-2 py-1 text-xs w-[96px]">{RIDER_IN_CHARGE}</div>
        <div className="px-2 py-1 text-xs w-[96px]">{VEHICLE_NUM}</div>
        <div className="px-2 py-1 text-xs w-[140px]">
          {DELIVERY_STATUS === '운송 중' ? dayjs().format('YYYY-MM-DD') : '-'}
        </div>
        <div className="px-2 py-1 text-xs w-[230px] flex justify-between">
          {COMPLETE_AT ? dayjs(COMPLETE_AT).format('YYYY-MM-DD') : '-'}
          <div className="text-right">
            <Button
              buttonClassName="text-xs bg-white text-primary-01 h-[28px]"
              text="상세정보"
              grayBorder
              smallPadding
              onClick={() => setIsOpenRouteDetail(true)}
            />
          </div>
        </div>
      </div>
      <HistoryRouteDetail
        isOpen={isOpenRouteDetial}
        detailItem={detailItem}
        closeModal={() => setIsOpenRouteDetail(false)}
      />
    </>
  );
}
