export const SNB_ROUTE_LIST = {
  ROUTE: {
    CODE: 'route-optimization',
    LABEL: '경로 최적화',
  },
  RECEIPT: {
    CODE: 'receipt-history',
    LABEL: '접수 내역',
  },
  TRANSPORT: {
    CODE: 'transport-status',
    LABEL: '운송 현황',
  },
  RIDER: {
    CODE: 'rider-management',
    LABEL: '기사 관리',
  },
  VEHICLE: {
    CODE: 'vehicle-management',
    LABEL: '차량 관리',
  },
  ACCOUNT: {
    CODE: 'account-management',
    LABEL: '계정 관리',
  },
} as const;
export type SnbListType = (typeof SNB_ROUTE_LIST)[keyof typeof SNB_ROUTE_LIST];
