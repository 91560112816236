/**
 * @file DaumPostCode.tsx
 * @author chad / chad@o2pluss.com
 * @description 다음 주소 검색 컴포넌트
 */

import DaumPostcode from 'react-daum-postcode';

import ModalTemplate from '@components/templates/ModalTemplate';
import Button from '@components/atom/Button';

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
  getPost: (address: IGetAddressInfo) => void;
}

export interface IGetAddressInfo {
  roadAddress: string;
  sido: string;
  sigungu: string;
  dong: string;
}

export default function DaumPostCode({ isOpen, onCancel, getPost }: IProps) {
  const handleComplete = (data: any) => {
    const addressInfo: IGetAddressInfo = {
      roadAddress: data.roadAddress,
      sido: data.sido,
      sigungu: data.sigungu,
      dong: data.bname,
    };

    getPost(addressInfo);
    onCancel();
  };

  return (
    <ModalTemplate isOpen={isOpen}>
      <DaumPostcode
        onComplete={handleComplete}
        style={{ width: '500px', height: '600px' }}
      />
      <div className="text-right pt-4">
        <Button
          buttonClassName="text-white bg-primary-01 py-2"
          text={'닫기'}
          onClick={onCancel}
        />
      </div>
    </ModalTemplate>
  );
}
