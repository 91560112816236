import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { SNB_ROUTE_LIST } from '@constants/navigation';

import SideNavigation from '@components/organisms/SideNavigation';
import ContentHeader from '@components/atom/ContentHeader';
import RouteCreateWithMap from '@components/organisms/RouteCreateWithMap';
import TransportStatusWithMap from '@components/organisms/TransportStatusWithMap';

export default function MainTemplate() {
  const { state } = useLocation();

  const [changeUiByLocation, setChangeUiByLocation] = useState<string>('');

  useEffect(() => {
    switch (state) {
      case 'routeCreate':
        setChangeUiByLocation('routeCreate');
        break;
      case SNB_ROUTE_LIST.TRANSPORT.CODE:
        setChangeUiByLocation(SNB_ROUTE_LIST.TRANSPORT.CODE);
        break;
      default:
        setChangeUiByLocation('');
        break;
    }
  }, [state]);

  return (
    <div className="flex h-[100vh]">
      <SideNavigation />
      <div
        className={`px-12 py-8 overflow-x-hidden ${
          changeUiByLocation ? 'w-[45%] scroll-hidden' : 'w-full'
        }`}
      >
        <ContentHeader />
        <Outlet />
      </div>
      {changeUiByLocation === 'routeCreate' && <RouteCreateWithMap />}
      {changeUiByLocation === SNB_ROUTE_LIST.TRANSPORT.CODE && (
        <TransportStatusWithMap />
      )}
    </div>
  );
}
