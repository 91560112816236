/**
 * @file RouteTableHeader.tsx
 * @author chad / chad@o2pluss.com
 * @description 경로 최적화 테이블 헤더 컴포넌트
 */

import Card from '@components/atom/Card';

import { ROUTE_TABLE_HEADER } from '@constants/routeOptimization';

export default function RouteTableHeader() {
  const TABLE_HEADER: string[] = [];

  for (const value of Object.values(ROUTE_TABLE_HEADER)) {
    TABLE_HEADER.push(value);
  }

  return (
    <Card cardClassName="flex mb-3 !px-3 py-2 !bg-[#FCFCFC] w-min">
      {TABLE_HEADER.map((value, index) => {
        return (
          <div
            className={`mx-1 px-2 text-secondary-01 text-xs ${
              index === 0
                ? 'w-[96px]'
                : index === 1 || index === 7 || index === 8
                ? 'w-[280px]'
                : index === 9
                ? 'w-[360px]'
                : index === 10
                ? 'w-[440px]'
                : index === 11
                ? 'w-[200px]'
                : 'w-[150px]'
            }`}
            key={value}
          >
            {value}
          </div>
        );
      })}
    </Card>
  );
}
