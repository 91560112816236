/**
 * @file ItemSelect.tsx
 * @author chad / chad@o2pluss.com
 * @description 기사 배정, 차량 배정 모달
 */

import { useState, useCallback, useEffect } from 'react';
import { riderInfoData } from '@data/riderData';
import { vehicleInfoData } from '@data/vehicleData';
import { IRiderVehicleInfo } from '@interfaces/info.interface';

import Button from '@components/atom/Button';
import ModalTemplate from '@components/templates/ModalTemplate';
import Select from '@components/atom/Select';

type SelectType = 'rider' | 'vehicle';

export interface ISelectedValue {
  type: SelectType | undefined;
  value: string;
}

interface IProps {
  type?: SelectType;
  isOpen: boolean;
  title?: string;
  setValue: (value: ISelectedValue) => void;
  onCancel: () => void;
}

export default function ItemSelect({
  type,
  isOpen,
  title,
  setValue,
  onCancel,
}: IProps) {
  const [optionItems, setOptionItems] = useState<IRiderVehicleInfo[]>([]);

  useEffect(() => {
    type === 'rider'
      ? setOptionItems(riderInfoData)
      : setOptionItems(vehicleInfoData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectValue, setSelectValue] = useState<string>('');

  /** 저장하기 클릭 */
  const onClickSaveBtn = useCallback(() => {
    setValue({ type, value: selectValue });
    onCancel();
  }, [onCancel, selectValue, setValue, type]);

  /** 취소 클릭 */
  const onClickCancelBtn = useCallback(() => {
    setSelectValue('');
    onCancel();
  }, [onCancel]);

  return (
    <ModalTemplate isOpen={isOpen} className="w-[500px]">
      <p className="text-xl font-bold">{title}</p>

      <Select
        selectOption={selectValue}
        setSelectOption={(value) => setSelectValue(value)}
        optionList={optionItems}
        placeholder={`배정할 ${
          type === 'rider' ? '기사를' : '차량을'
        } 선택해주세요`}
      />

      <div className="text-right mt-6">
        <Button
          buttonClassName="h-[36px] text-sm mr-2"
          text="취소"
          smallPadding
          blackBorder
          onClick={onClickCancelBtn}
        />
        <Button
          buttonClassName={`${
            selectValue ? 'bg-primary-01' : 'bg-primary-02'
          } h-[36px] text-sm text-white`}
          text="저장하기"
          smallPadding
          disabled={!selectValue}
          onClick={onClickSaveBtn}
        />
      </div>
    </ModalTemplate>
  );
}
