/**
 * @file: TransportStatusWithMap.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.25 ~
 * @description: 운송 현황 페이지 with kakaoMap 컴포넌트
 */

import { useSelectedTransportData } from '@hooks/recoil/useTransportStatus';
import dayjs from 'dayjs';

import KakaoMap from '@components/atom/KakaoMap';

export default function TransportStatusWithMap() {
  const { selectedTransport: data } = useSelectedTransportData();

  /** 완료된 배송 수 구하기 */
  const getCompletedDelivery = () => {
    const filtered = data?.LIST_ITEM?.filter(
      (item: any) => item.isDelivery === true,
    );

    return filtered?.length;
  };

  return (
    <div className="bg-white w-[55%] px-12 py-8">
      <div className="flex justify-between items-center mb-8">
        <p className="font-bold">{data?.ROUTE_NAME ?? '-'}</p>
        <p className="text-[#676767]">{data?.RECEIPT_NUM ?? '-'}</p>
      </div>

      {/* kakaoMap */}
      <div
        className="rounded-xl overflow-hidden"
        style={{ height: 'calc(100% - 347px)' }}
      >
        <KakaoMap
          className="relative flex justify-center items-end"
          deliveryStatus={data?.DELIVERY_STATUS}
          riderInfo={data?.RIDER_INFO}
          routeList={data?.LIST_ITEM}
        >
          <div className="absolute flex items-center justify-center px-4 py-3 gap-6 bg-white z-10 rounded-xl shadow-lg mb-7 text-sm text-[#676767]">
            <div>
              <span className="mr-1">총</span>
              <span className="font-bold">
                {data?.LIST_ITEM?.length ?? 0}건
              </span>
            </div>
            <div>
              <span className="mr-1">완료</span>
              <span className="font-bold text-blue-600">
                {getCompletedDelivery() ?? 0}건
              </span>
            </div>
            <div>
              <span className="mr-1">미완료</span>
              <span className="font-bold text-primary-01">
                {data?.LIST_ITEM?.length
                  ? data?.LIST_ITEM?.length - getCompletedDelivery()
                  : 0}
                건
              </span>
            </div>
          </div>
        </KakaoMap>
      </div>

      <div className="flex justify-between items-center my-8">
        <p className="font-bold">배정정보</p>
        <div className="text-[#676767] flex gap-3 min-w-[50%]">
          <div className="w-[220px]">
            <span className="font-bold mr-2">운송 시작일</span>
            <span>
              {data?.DELIVERY_STATUS === '운송 중'
                ? dayjs().format('YYYY-MM-DD')
                : '-'}
            </span>
          </div>
          <div className="w-[220px]">
            <span className="font-bold mr-2">운송 종료일</span>
            <span>{data?.COMPLETE_AT ?? '-'}</span>
          </div>
        </div>
      </div>

      <div className="flex p-4 bg-[#fcfcfc] rounded-lg h-[204px]">
        <AssignInfo
          name={data?.RIDER_INFO?.name ?? '-'}
          category="연락처"
          categoryInfo={data?.RIDER_INFO?.phone ?? '-'}
          memo={data?.RIDER_INFO?.memo ?? '-'}
        />

        <div className="h-full border-l border-gray-200 mx-3" />

        <AssignInfo
          name={data?.VEHICLE_INFO?.name ?? '-'}
          category="차량번호"
          categoryInfo={data?.VEHICLE_INFO?.vehicle_num ?? '-'}
          memo={data?.VEHICLE_INFO?.memo ?? '-'}
        />
        <div></div>
      </div>
    </div>
  );
}

/** 배정정보 컴포넌트 */
const AssignInfo = ({
  name,
  category,
  categoryInfo,
  memo,
}: {
  name: string;
  category: string;
  categoryInfo: number | string;
  memo: string;
}) => {
  return (
    <div className="w-[50%]">
      <p className="mb-4 font-bold">{name}</p>
      <div className="mb-2">
        <span className="text-[#676767] mr-2">{category}</span>
        <span>{categoryInfo}</span>
      </div>
      <div className="text-[#676767]">{memo}</div>
    </div>
  );
};
