/**
 * @file CreateRouteInfoDetail.tsx
 * @author chad / chad@o2pluss.com
 * @description 경로 최적화 경로 정보 상세 모달
 */

import Button from '@components/atom/Button';
import TotalCount from '@components/atom/TotalCount';
import RouteTableHeader from '@components/molecules/tableHeader/RouteTableHeader';
import ModalTemplate from '@components/templates/ModalTemplate';
import { ISelectedRouteInfo } from '../RouteCreateWithMap';
import RouteTableList from '@components/molecules/tableList/RouteTableList';
import Separator from '@components/atom/Separator';
import Card from '@components/atom/Card';

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  detailInfo: ISelectedRouteInfo;
}

export default function CreateRouteInfoDetail({
  isOpen,
  closeModal,
  detailInfo,
}: IProps) {
  return (
    <ModalTemplate isOpen={isOpen} isFullScreen>
      <div className="flex justify-between items-center mb-8">
        <h6 className="text-2xl font-bold">루트플로우</h6>
        <Button
          text="닫기"
          buttonClassName="bg-primary-01 text-white w-[80px] h-[48px]"
          onClick={closeModal}
        />
      </div>
      <p className="text-md font-bold mb-4">{detailInfo.routeName}</p>

      <TotalCount className="mb-4" total={detailInfo.orderCount} />

      <div
        className="overflow-x-scroll"
        style={{ height: 'calc(100% - 160px)' }}
      >
        <RouteTableHeader />
        <Card cardClassName="overflow-y-scroll w-max !px-3 !py-0">
          {detailInfo.groupOrderInfo.map((list, index) => {
            return (
              <div key={index}>
                <RouteTableList
                  no={String(index + 1).padStart(2, '0')}
                  id={list.id}
                  order_num={list.order_num}
                  order_name={list.order_name}
                  order_contact={list.order_contact}
                  reciever={list.reciever}
                  reciever_contact={list.reciever_contact}
                  add_contact={list.add_contact}
                  recieve_address_1={list.recieve_address_1}
                  recieve_address_2={list.recieve_address_2}
                  delivery_req={list.delivery_req}
                  article={list.article}
                  quantity={list.quantity}
                />
                {index + 1 < detailInfo.groupOrderInfo.length && <Separator />}
              </div>
            );
          })}
        </Card>
      </div>
    </ModalTemplate>
  );
}
