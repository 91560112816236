/**
 * @file ModalTemplate.tsx
 * @author chad / chad@o2pluss.com
 * @description 모달 템플릿
 */

import React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

interface IProps {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  isFullScreen?: boolean;
}

ReactModal.setAppElement('#root');

export default function ModalTemplate({
  isOpen,
  children,
  className,
  isFullScreen,
}: IProps) {
  return (
    <ReactModal
      isOpen={isOpen}
      className={classNames(
        'rounded-lg bg-white p-6 focus:outline-none',
        className,
        `${isFullScreen ? 'w-full h-full !bg-[#f8f8f8]' : 'max-w-[800px]'}`,
      )}
      overlayClassName="w-full h-full fixed top-0 right-0 bg-[#2B2B2B60] z-[999] flex items-center justify-center"
    >
      {children}
    </ReactModal>
  );
}
