/**
 * @file: TransportStatusCard.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.22 ~
 * @description: 운송 현황 리스트 카드 컴포넌트
 */

import { IReceiptRouteData } from '@data/receiptHistoryData';

import Badge from '@components/atom/Badge';
import Card from '@components/atom/Card';
import TransportStatusCardList from '@components/atom/TransportStatusCardList';
import { Dispatch, SetStateAction } from 'react';

import { useSelectedTransportData } from '@hooks/recoil/useTransportStatus';

interface IProps {
  data: IReceiptRouteData;
  selectedCard: number;
  setSelectedCard: Dispatch<SetStateAction<number>>;
}

export default function TransportStatusCard({
  data,
  selectedCard,
  setSelectedCard,
}: IProps) {
  const { setSelectedTranport } = useSelectedTransportData();

  /** 운송 현황 카드 클릭 */
  const onClickTransportCard = (id: number) => {
    setSelectedCard(id);
    setSelectedTranport(data);
  };

  return (
    <Card
      cardClassName={`mb-4 cursor-pointer ${
        selectedCard === data.id && 'shadow-md'
      }`}
      onClick={() => onClickTransportCard(data.id)}
    >
      <div className="flex justify-between items-center">
        <span className="font-bold">{data?.ROUTE_NAME ?? '-'}</span>
        <Badge
          useDeliveryStatus
          status={data?.DELIVERY_STATUS ?? '-'}
          size="md"
        />
      </div>

      <div className="bg-gray-100 rounded h-[6px] mt-4 mb-6">
        <div
          className={`h-full bg-state-info rounded ${
            data?.DELIVERY_STATUS === '운송 중' ? 'w-[2%]' : 'w-0'
          }`}
        />
      </div>

      <div className="overflow-y-scroll h-[100px]">
        {data?.LIST_ITEM?.map((list, index) => (
          <TransportStatusCardList
            key={index}
            index={index + 1}
            data={list}
            totalCount={data.LIST_ITEM?.length}
          />
        ))}
      </div>
    </Card>
  );
}
