import { Routes, Route } from 'react-router-dom';

import LoginTemplate from '@components/templates/LoginTemplate';
import Login from '@pages/user-login/Login';
import SignUp from '@pages/user-login/SignUp';
import FindAccount from '@pages/user-login/FindAccount';

import MainTemplate from '@components/templates/MainTemplate';
import AccountMgt from '@pages/user-main/AccountMgt';
import ReceiptHistory from '@pages/user-main/history/ReceiptHistory';
import ReceiptDetails from '@pages/user-main/history/ReceiptDetails';
import RiderMgt from '@pages/user-main/RiderMgt';
import RouteOptimization from '@pages/user-main/optimization/RouteOptimization';
import RouteCreate from '@pages/user-main/optimization/RouteCreate';
import TransportStatus from '@pages/user-main/TransportStatus';
import VehicleMgt from '@pages/user-main/VehicleMgt';

import { SNB_ROUTE_LIST } from '@constants/navigation';

const Router = () => {
  return (
    <>
      <Routes>
        {/* 로그인 페이지 */}
        <Route path="/" element={<LoginTemplate />}>
          <Route index element={<Login />} />
          {/* 회원가입 */}
          <Route path="sign-up" element={<SignUp />} />
          {/* 계정 찾기 */}
          <Route path="find-account" element={<FindAccount />} />
        </Route>
        {/* 메인 페이지 */}
        <Route element={<MainTemplate />}>
          {/* 경로 최적화 */}
          <Route path={SNB_ROUTE_LIST.ROUTE.CODE}>
            <Route index element={<RouteOptimization />} />
            <Route path="create" element={<RouteCreate />} />
          </Route>
          {/* 접수 내역 */}
          <Route path={SNB_ROUTE_LIST.RECEIPT.CODE}>
            <Route index element={<ReceiptHistory />} />
            <Route path=":id" element={<ReceiptDetails />} />
          </Route>
          {/* 운송 현황 */}
          <Route
            path={SNB_ROUTE_LIST.TRANSPORT.CODE}
            element={<TransportStatus />}
          />
          {/* 기사 관리 */}
          <Route path={SNB_ROUTE_LIST.RIDER.CODE} element={<RiderMgt />} />
          {/* 차량 관리 */}
          <Route path={SNB_ROUTE_LIST.VEHICLE.CODE} element={<VehicleMgt />} />
          {/* 계정 관리 */}
          <Route path={SNB_ROUTE_LIST.ACCOUNT.CODE} element={<AccountMgt />} />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
