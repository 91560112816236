/**
 * @file DndFileUpload.tsx
 * @author chad / chad@o2pluss.com
 * @description 드래그 & 드롭 파일 업로드 컴포넌트
 */

import {
  useCallback,
  useState,
  DragEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import classNames from 'classnames';
import { TiPlus } from 'react-icons/ti';
import { FiX } from 'react-icons/fi';

interface IProps {
  className?: string;
  uploadFileName: string | null;
  setUploadFileName: Dispatch<SetStateAction<string | null>>;
}

export default function DndFileUpload({
  className,
  uploadFileName,
  setUploadFileName,
}: IProps) {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  /** 파일 업로드 */
  const onSelectFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const target = e.currentTarget;
      target.files && setUploadFileName(target.files[0].name);
    },
    [setUploadFileName],
  );

  /** drag EVENT */
  const onDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const onDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const onDragDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const dataTarget = e.dataTransfer;
      setUploadFileName(dataTarget.files[0].name);
    },
    [setUploadFileName],
  );
  /** // drag EVENT */

  // 업로드 하려는 파일 삭제
  const onClickUploadFileDelete = () => setUploadFileName('');

  return (
    <>
      <div
        className={classNames(
          `p-3 border-2 rounded-lg h-[180px] ${
            isDragging
              ? 'bg-primary-02 text-primary-01 border-dashed border-primary-01'
              : 'bg-[#F8F8F8] text-secondary-01 border-solid'
          }`,
          className,
        )}
      >
        {uploadFileName ? (
          <div className="h-full">
            <div className="flex items-center">
              <span className="text-primary-01 text-sm mr-1">
                {uploadFileName}
              </span>
              <FiX
                className="cursor-pointer"
                onClick={onClickUploadFileDelete}
              />
            </div>
          </div>
        ) : (
          <div
            className="h-full"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDragDrop}
            role="presentation"
          >
            <input
              id="fileUpload"
              className="hidden"
              type="file"
              accept=".xls, .xlsx"
              aria-hidden
              onChange={onSelectFile}
              disabled={uploadFileName ? true : false}
            />
            <label
              htmlFor="fileUpload"
              className="flex flex-col items-center justify-center h-full cursor-pointer"
            >
              <TiPlus size={24} className="mb-3" />
              파일을 선택하거나 드래그하여 추가해주세요
              {isDragging}
            </label>
          </div>
        )}
      </div>
    </>
  );
}
