/**
 * @file Checkbox.tsx
 * @author chad / chad@o2pluss.com
 * @description 체크박스 컴포넌트
 */

import classNames from 'classnames';
import { ChangeEvent } from 'react';

interface ICheckboxProps {
  className?: string;
  inputClassName?: string;
  label?: string;
  labelClassName?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function Checkbox({
  className,
  inputClassName,
  labelClassName,
  label,
  checked,
  onChange,
}: ICheckboxProps) {
  return (
    <div className={classNames('flex items-center', className)}>
      <input
        className={classNames(
          'mr-1 w-[20px] h-[20px] cursor-pointer',
          inputClassName,
        )}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {label && <span className={classNames(labelClassName)}>{label}</span>}
    </div>
  );
}
