import React, { useEffect, useState } from 'react';
import { DELIVERY_STATUS_LIST, DELIVERY_STATUS } from '@constants/common';
import { receiptData, IReceiptRouteData } from '@data/receiptHistoryData';
import { useCompleteOptimization } from '@hooks/recoil/useRouteOrder';

import Button from '@components/atom/Button';
import TotalCount from '@components/atom/TotalCount';
import HistoryTableHeader from '@components/molecules/tableHeader/HistoryTableHeader';
import HistoryTableList from '@components/molecules/tableList/HistoryTableList';
import Separator from '@components/atom/Separator';

export default function ReceiptHistory() {
  const { isCompleteOptimization } = useCompleteOptimization();

  const [selectDeliveryStatus, setSelectDeliveryStatus] =
    useState<DELIVERY_STATUS>(DELIVERY_STATUS.ALL);
  const [useReceiptRouteData, setUseReceiptRouteData] =
    useState<IReceiptRouteData[]>();

  /** 접수 내역 필터 */
  const filteredReceiptData = () => {
    receiptData.forEach((data) => {
      setUseReceiptRouteData(
        data.ROUTE_DATA.filter(
          (item) =>
            item.DELIVERY_STATUS === selectDeliveryStatus ||
            selectDeliveryStatus === '전체',
        ),
      );
    });
  };

  useEffect(() => {
    filteredReceiptData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDeliveryStatus]);

  useEffect(() => {
    receiptData.forEach((data) => {
      setUseReceiptRouteData(data.ROUTE_DATA);
    });
  }, []);

  return (
    <>
      <div className="pb-6">
        {DELIVERY_STATUS_LIST.map(({ key, label }) => (
          <Button
            key={key}
            buttonClassName="h-[32px] mr-1"
            text={label}
            chip
            grayBorder
            blackBorder={selectDeliveryStatus === label ? true : false}
            smallPadding
            bold={false}
            onClick={() => {
              setSelectDeliveryStatus(label);
            }}
          />
        ))}
      </div>

      <TotalCount
        className="mb-4"
        total={isCompleteOptimization ? useReceiptRouteData?.length : 0}
      />

      {isCompleteOptimization ? (
        <div
          className="overflow-x-scroll"
          style={{ height: 'calc(100% - 250px)' }}
        >
          <HistoryTableHeader />
          <div className="overflow-y-scroll !w-max">
            {receiptData.map((data, index) => (
              <div key={index}>
                <div
                  className={`flex items-center bg-primary-02 px-2 py-1 font-bold text-xs h-[36px] ${
                    index === 0 ? 'rounded-t-lg' : ''
                  }`}
                >
                  <span className="px-3 w-[96px]">{data.NO}</span>
                  <span className="px-3 w-[478px]">{data.RECEIPT_NUM}</span>
                  <span className="px-3 w-[96px]">{data.ORDER_NUM}</span>
                  <span className="px-3 w-[96px]">{data.ARTICLE_NUM}</span>
                  <span className="px-3 w-[754px]" />
                </div>
                {useReceiptRouteData?.length ? (
                  <>
                    {useReceiptRouteData?.map((item, index) => (
                      <div key={index}>
                        <HistoryTableList
                          id={item.id}
                          NO={item.NO}
                          RECEIPT_NUM={item.RECEIPT_NUM}
                          ROUTE_NAME={item.ROUTE_NAME}
                          ORDER_NUM={item.ORDER_NUM}
                          ARTICLE_NUM={item.ARTICLE_NUM}
                          IS_SHOW={item.IS_SHOW}
                          DELIVERY_STATUS={item.DELIVERY_STATUS}
                          RIDER_IN_CHARGE={item.RIDER_IN_CHARGE}
                          VEHICLE_NUM={item.VEHICLE_NUM}
                          STARTED_AT={item.STARTED_AT}
                          COMPLETE_AT={item.COMPLETE_AT}
                          LIST_ITEM={item.LIST_ITEM}
                          className={
                            index === data.ROUTE_DATA.length - 1
                              ? 'rounded-b-lg'
                              : ''
                          }
                        />
                        {index !== data.ROUTE_DATA.length - 1 && <Separator />}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="h-[250px] flex items-center justify-center text-primary-01 w-auto">
                    해당하는 운송 상태가 없습니다.
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full h-[300px] flex items-center justify-center">
          <span className="text-[#676767]">접수내역이 없습니다</span>
        </div>
      )}
    </>
  );
}
