/**
 * @file Card.tsx
 * @author chad / chad@o2pluss.com
 * @description 카드 컴포넌트 children 으로 자식 컴포넌트를 받는다.
 */

import React from 'react';
import classNames from 'classnames';

interface IProps {
  children: React.ReactNode;
  cardClassName?: string;
  onClick?: () => void;
}

export default function Card({ children, cardClassName, onClick }: IProps) {
  return (
    <div
      className={classNames('w-full rounded-lg p-4 bg-white', cardClassName)}
      role="presentation"
      onClick={onClick}
    >
      {children}
    </div>
  );
}
