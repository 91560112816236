/**
 * @file Select.tsx
 * @author chad / chad@o2pluss.com
 * @description select 컴포넌트
 */

import { useState } from 'react';

import { PiCaretUpDownBold } from 'react-icons/pi';
import { FaCheck } from 'react-icons/fa6';
import classNames from 'classnames';

interface ISelectProps {
  selectClassName?: string;
  optionList: any;
  placeholder?: string;
  selectOption: string;
  setSelectOption: (value: string) => void;
}

export default function Select({
  selectClassName,
  optionList,
  placeholder,
  selectOption,
  setSelectOption,
}: ISelectProps) {
  const [isOptionOpen, setIsOptionOpen] = useState<boolean>(false);

  /** 옵션 선택 */
  const selectOptionValue = (value: string) => {
    setSelectOption(value);
    setIsOptionOpen(false);
  };

  return (
    <div className={classNames(selectClassName)}>
      <div className="relative mt-6 mb-1">
        <button
          className="flex justify-between items-center border border-primary-01 w-full px-3 rounded-lg h-[48px]"
          type="button"
          onClick={() => setIsOptionOpen(!isOptionOpen)}
        >
          <span className="text-sm">
            {selectOption ? (
              selectOption
            ) : (
              <span className="text-secondary-01">{placeholder}</span>
            )}
          </span>
          <PiCaretUpDownBold className="text-secondary-02" size="20px" />
        </button>
      </div>

      {isOptionOpen && (
        <div className="absolute w-[452px] bg-white rounded-lg overflow-hidden shadow-lg z-[1] border">
          <ul className="w-full">
            {optionList.map((option: any, index: number) => {
              return (
                <li
                  key={index}
                  className="w-full flex items-center px-3 py-2 hover:bg-primary-01 hover:text-white cursor-pointer"
                  role="presentation"
                  onClick={() => selectOptionValue(option.value)}
                >
                  {selectOption === option.value && (
                    <FaCheck className="mr-2" />
                  )}
                  {option.value}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
