/**
 * @file: useTranportStatus.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.26 ~
 * @description:
 */

import { useRecoilState, RecoilEnv } from 'recoil';
import { selectedTransportAtom } from '@recoil/transportStatus';

export const useSelectedTransportData = () => {
  const [selectedTransport, setSelectedTranport] = useRecoilState<any>(
    selectedTransportAtom,
  );

  return {
    selectedTransport,
    setSelectedTranport,
  };
};

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
