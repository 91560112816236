/**
 * @file RouteCreate.tsx
 * @author chad / chad@o2pluss.com
 * @description 경로 최적화 생성 페이지
 */

import { useNavigate } from 'react-router-dom';
import {
  useReadyToRouteCreate,
  useRouteGroup,
  useRouteSelected,
} from '@hooks/recoil/useRouteOrder';

import Button from '@components/atom/Button';
import TotalCount from '@components/atom/TotalCount';
import CreateRouteTableHeader from '@components/molecules/tableHeader/CreateRouteTableHeader';
import CreateRouteList from '@components/molecules/CreateRouteList';
import { useModal } from '@hooks/useModal';
import { useCallback } from 'react';

export default function RouteOptimizationCreate() {
  const { orderGroupList } = useRouteGroup();
  const { selectedList } = useRouteSelected();
  const { setIsReadyToRouteCreate } = useReadyToRouteCreate();
  const { showModal } = useModal();
  const navigate = useNavigate();

  /** 지역 갯수 구하기 */
  const getAreaCount = () => {
    let areaCount: number = 0;

    orderGroupList.forEach((sido: any) => {
      sido.sigungu.forEach((sigungu: any) => {
        areaCount += sigungu.dong.length;
      });
    });

    return areaCount;
  };

  /** 이전 버튼 클릭 */
  const onClickPrevBtn = useCallback(() => {
    showModal({
      type: 'confirm',
      title: '화면을 나가시겠어요?',
      message: '모든 경로 최적화가 초기화 됩니다.',
      onOk: () => navigate(-1),
    });
  }, [navigate, showModal]);

  return (
    <>
      <div className="flex justify-end mb-6">
        <Button
          text="이전"
          buttonClassName="text-primary-01 bg-white mr-3 h-[48px]"
          grayBorder
          onClick={onClickPrevBtn}
        />
        <Button
          text="경로 최적화"
          buttonClassName={`h-[48px] text-white ${
            selectedList.length > 0 ? 'bg-primary-01' : 'bg-primary-02'
          }`}
          disabled={selectedList.length > 0 ? false : true}
          onClick={() => setIsReadyToRouteCreate(true)}
        />
      </div>

      <div className="flex justify-between">
        <TotalCount className="mb-4" total={getAreaCount()} unit="지역" />
        {selectedList.length > 0 && (
          <span className="text-blue-500">{selectedList.length}지역 선택</span>
        )}
      </div>

      <CreateRouteTableHeader />

      <div className="bg-white rounded-lg overflow-hidden">
        {orderGroupList.map((list: any, index: number) => {
          return <CreateRouteList key={index} index={index} list={list} />;
        })}
      </div>
    </>
  );
}
