import React from 'react';
import { Outlet } from 'react-router-dom';

// eslint-disable-next-line import/no-named-as-default
import styled from 'styled-components';

import LoginPageImage from '@assets/images/login.png';

const LoginTemplateContainer = styled.div`
  height: 100vh;
  background-image: url('${LoginPageImage}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export default function LoginTemplate() {
  return (
    <LoginTemplateContainer>
      <div className="w-[560px] bg-white h-[100vh] p-12 pt-40 overflow-auto">
        <Outlet />
      </div>
    </LoginTemplateContainer>
  );
}
