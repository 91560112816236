/**
 * @file Accordion.tsx
 * @author chad / chad@o2pluss.com
 * @description
 */

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import Checkbox from '@components/atom/Checkbox';
import {
  useRouteSelected,
  useRouteGroup,
  useRouteAllSelected,
} from '@hooks/recoil/useRouteOrder';
import Separator from '@components/atom/Separator';

interface IProps {
  sigungu: any;
}

const RouteAccordion = ({ sigungu }: IProps) => {
  const { selectedList, setSelectedList } = useRouteSelected();
  const { orderGroupList } = useRouteGroup();
  const { isAllSelected, setIsAllSelected } = useRouteAllSelected();

  const [isOpenAccordion, setIsOpenAccordion] = useState<boolean>(false);
  const [isSigunguChecked, setIsSigunguChecked] = useState<boolean>(false);

  const [selectedDongInSigungu, setSelectedDongInSigungu] = useState<string[]>(
    [],
  );

  /**
   * 선택한 리스트에 포함되어 있는지 확인
   * @returns boolean
   */
  const isIncludesSelectedItem = useCallback(
    (list: string) => {
      return selectedList.includes(list);
    },
    [selectedList],
  );

  const deleteItem: string[] = [];
  /** 체크된 동 값 넣기 */
  const checkedItemHandler = useCallback(
    (value: string, isChecked: boolean) => {
      if (isChecked) {
        setSelectedList((prev) => [...prev, value]);
        setSelectedDongInSigungu((prev) => [...prev, value]);
        return;
      }

      if (!isChecked && isIncludesSelectedItem(value)) {
        deleteItem.push(value);
        const filterd = selectedList.filter((x) => !deleteItem.includes(x));

        setSelectedList(filterd);
        setSelectedDongInSigungu(filterd);
      }
    },
    [deleteItem, isIncludesSelectedItem, selectedList, setSelectedList],
  );

  /** 시군구 체크박스 클릭 */
  const onClickSigunguCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>, sigungu: string) => {
      const checked = e.target.checked;

      let selectedSigungu: any = null;
      orderGroupList.forEach((sido_item: any) => {
        sido_item.sigungu.forEach((sigungu_item: any) => {
          if (sigungu_item.sigunguName === sigungu)
            selectedSigungu = sigungu_item;
        });
      });

      selectedSigungu &&
        selectedSigungu.dong.forEach((dong: any) => {
          checkedItemHandler(dong.dongName, checked);
        });
      setIsSigunguChecked(checked);
    },
    [checkedItemHandler, orderGroupList],
  );

  /** 동 체크박스 클릭 */
  const onClickDongCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>, dong: string) => {
      checkedItemHandler(dong, e.target.checked);
    },
    [checkedItemHandler],
  );

  /** 지역 갯수 구하기 */
  const getAreaCount = useCallback(() => {
    let areaCount: number = 0;

    orderGroupList.forEach((sido: any) => {
      sido.sigungu.forEach((sigungu: any) => {
        areaCount += sigungu.dong.length;
      });
    });
    return areaCount;
  }, [orderGroupList]);

  /** 전체 클릭시 동 정보 다 넣기 */
  const allCheckedDong = useCallback(() => {
    sigungu.dong.forEach((dong: any) => {
      setSelectedDongInSigungu((prev) => [...prev, dong.dongName]);
    });
  }, [sigungu.dong]);

  /** 전체선택 시 */
  useEffect(() => {
    if (getAreaCount() === selectedList.length) setIsAllSelected(true);
    else setIsAllSelected(false);
  }, [getAreaCount, selectedList.length, setIsAllSelected]);

  /** 전체선택 시 개별 동 정보 다 넣기 */
  useEffect(() => {
    isAllSelected
      ? // FIXME: selectedDongInSigungu.length 하면 1개만 선택되어 있어도 전체선택 안됨..
        !selectedDongInSigungu.length && allCheckedDong()
      : setSelectedDongInSigungu([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCheckedDong, isAllSelected]);

  /** 동 체크박스 모두 선택 시 */
  useEffect(() => {
    if (sigungu.dong.length === selectedDongInSigungu.length)
      setIsSigunguChecked(true);
    else setIsSigunguChecked(false);
  }, [selectedDongInSigungu.length, sigungu.dong.length]);

  return (
    <>
      <div
        className={`flex items-center px-3 py-1 h-[36px] text-xs relative bg-primary-02`}
      >
        <Checkbox
          checked={isSigunguChecked}
          onChange={(e) => onClickSigunguCheckbox(e, sigungu.sigunguName)}
        />
        <span className="w-[15%] px-2">{''}</span>
        <span className="w-[45%] px-2">{sigungu.sigunguName}</span>
        <span className="w-[20%] px-2">{sigungu.orderCount}</span>
        <span className="w-[20%] px-2">{sigungu.quantityCount}</span>
        <IoIosArrowDown
          className={`cursor-pointer absolute right-3 ${
            isOpenAccordion ? 'rotate-180' : ''
          }`}
          onClick={() => setIsOpenAccordion(!isOpenAccordion)}
        />
      </div>

      {sigungu.dong.map((dong: any, index: number) => {
        return (
          <div key={index}>
            <div
              className={`overflow-hidden transition-all ${
                isOpenAccordion ? 'h-[36px]' : 'h-[0px]'
              } ${
                isIncludesSelectedItem(dong.dongName) ? 'bg-state-infoBg' : ''
              }`}
            >
              <div className={`flex items-center px-3 py-1 text-xs h-[36px]`}>
                <span className="w-[20px] mr-1" />
                <div className="w-[15%]">
                  <Checkbox
                    checked={isIncludesSelectedItem(dong.dongName)}
                    onChange={(e) => onClickDongCheckbox(e, dong.dongName)}
                  />
                </div>
                <span className="w-[45%] px-2">{dong.dongName}</span>
                <span className="w-[20%] px-2">{dong.orderCount}</span>
                <span className="w-[20%] px-2">{dong.quantityCount}</span>
              </div>
            </div>
            <Separator className={`${isOpenAccordion ? '' : 'hidden'}`} />
          </div>
        );
      })}
    </>
  );
};

export default RouteAccordion;
