/**
 * @file RouteTableList.tsx
 * @author chad / chad@o2pluss.com
 * @description 경로 최적화 오더정보 테이블 리스트 컴포넌트
 */

import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { IRouteExcelData } from '@data/routeExcelUploadData';
import { useRouteOrder } from '@hooks/recoil/useRouteOrder';

import Input from '../Input';
import Button from '@components/atom/Button';
import DaumPostCode, {
  IGetAddressInfo,
} from '@components/organisms/modal/DaumPostCode';

interface IProps extends IRouteExcelData {
  isAddOrder?: boolean;
  setIsAddOrder?: Dispatch<SetStateAction<boolean>>;
  no?: string;
}

interface IRouteTableList extends IRouteExcelData {
  [key: string]: string | number | boolean | undefined | null | ArrayBuffer;
}

export default function RouteTableList({
  isAddOrder,
  setIsAddOrder,
  no,
  id,
  order_num,
  order_name,
  order_contact,
  reciever,
  reciever_contact,
  add_contact,
  recieve_address_1,
  recieve_address_2,
  delivery_req,
  article,
  quantity,
}: IProps) {
  const propsData = {
    id,
    order_num,
    order_name,
    order_contact,
    reciever,
    reciever_contact,
    add_contact,
    recieve_address_1,
    recieve_address_2,
    delivery_req,
    article,
    quantity,
  };

  const { orderInfoList, setOrderInfoList } = useRouteOrder();

  const [isOpenPost, setIsOpenPost] = useState<boolean>(false);

  const [tableRowsInput, setTableRowsInput] =
    useState<IRouteTableList>(propsData);

  const [cacheTableRowsInput, setCacheTableRowsInput] =
    useState<IRouteTableList>(propsData);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    setTableRowsInput(propsData);
    setCacheTableRowsInput(propsData);
    // eslint-disable-next-line
  }, [orderInfoList]);

  /**
   * input 값 받기
   */
  const onChangeInputValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setTableRowsInput({
        ...tableRowsInput,
        id: isUpdate
          ? id
          : Number(orderInfoList[orderInfoList.length - 1].id) + 1,
        [name]: value,
      });
    },
    [id, isUpdate, orderInfoList, tableRowsInput],
  );

  /**
   * 필수입력 값 체크
   * @returns boolean
   */
  const isRequireInputs = useCallback(() => {
    return tableRowsInput.order_num && tableRowsInput.recieve_address_1
      ? true
      : false;
  }, [tableRowsInput.order_num, tableRowsInput.recieve_address_1]);

  /**
   * 오더 리스트 저장
   */
  const saveListItem = useCallback(() => {
    if (!isRequireInputs()) return;

    setOrderInfoList((prev) => [...prev, tableRowsInput]);
    setIsAddOrder?.(false);
  }, [isRequireInputs, setOrderInfoList, setIsAddOrder, tableRowsInput]);

  /**
   * 오더 리스트 수정
   */
  const updateListItme = useCallback(
    (id: number | undefined) => {
      const findItem = orderInfoList.findIndex((list) => list.id === id);

      const updateRowsInfo = [...orderInfoList];
      updateRowsInfo[findItem] = tableRowsInput;

      setOrderInfoList(updateRowsInfo);
      setIsUpdate(false);
    },
    [orderInfoList, setOrderInfoList, tableRowsInput],
  );

  /**
   * 오더 리스트 삭제
   */
  const deleteListItem = useCallback(
    (id: number | undefined) => {
      setOrderInfoList((prev) => prev.filter((list) => list.id !== id));
    },
    [setOrderInfoList],
  );

  /**
   * 저장 / 수정 버튼
   */
  const onClickSaveUpdateBtn = useCallback(
    (id: number | undefined) => {
      // 저장
      if (isAddOrder) {
        saveListItem();
        return;
      }

      // 수정
      if (!isUpdate) setIsUpdate(true);
      else updateListItme(id);
    },
    [isAddOrder, isUpdate, saveListItem, updateListItme],
  );

  /**
   * 취소 / 삭제 버튼
   */
  const onClickCancelDeleteBtn = useCallback(
    (id: number | undefined) => {
      // 수정 취소
      if (isUpdate) {
        setIsUpdate(false);
        setTableRowsInput(cacheTableRowsInput);
        return;
      }
      // 오더 추가 취소
      if (isAddOrder) {
        setIsAddOrder?.(false);
        return;
      }
      // 삭제
      deleteListItem(id);
    },
    [isUpdate, isAddOrder, deleteListItem, cacheTableRowsInput, setIsAddOrder],
  );

  /** 주소 정보 가져오기 */
  const getAddress = useCallback(
    (address: IGetAddressInfo) => {
      setTableRowsInput({
        ...tableRowsInput,
        recieve_address_1: address.roadAddress,
        sido: address.sido,
        sigungu: address.sigungu,
        dong: address.dong,
      });
    },
    [tableRowsInput],
  );

  return (
    <div className="flex items-center py-2">
      {Object.keys(tableRowsInput).map((key, index) => {
        if (key === 'sido' || key === 'sigungu' || key === 'dong') return;
        return (
          <div
            key={index}
            className={`mx-1 ${
              index === 0
                ? 'w-[96px]'
                : index === 1 || index === 7 || index === 8
                ? 'w-[280px]'
                : index === 9
                ? 'w-[360px]'
                : index === 10
                ? 'w-[440px]'
                : index === 11
                ? 'w-[85px]'
                : 'w-[150px]'
            }`}
          >
            {index === 0 ? (
              <div className="px-2 text-xs">{no}</div>
            ) : (
              <Input
                className="rounded-md"
                inputClassName="text-xs"
                name={key}
                value={
                  tableRowsInput[key]
                    ? tableRowsInput[key]
                    : isAddOrder || isUpdate
                    ? ''
                    : '-'
                }
                border={
                  (recieve_address_1 !== tableRowsInput[key] && isUpdate) ||
                  isAddOrder
                    ? true
                    : false
                }
                readOnly={
                  (recieve_address_1 !== tableRowsInput[key] && isUpdate) ||
                  (isAddOrder && index !== 7)
                    ? false
                    : true
                }
                onChange={onChangeInputValue}
                onClick={() =>
                  index === 7 && isAddOrder ? setIsOpenPost(true) : null
                }
              />
            )}
          </div>
        );
      })}

      <Button
        buttonClassName={`text-xs text-white h-[28px] mx-1 ${
          isRequireInputs() ? 'bg-primary-01' : 'bg-primary-02'
        }`}
        text={isAddOrder || isUpdate ? '저장' : '수정'}
        bold={false}
        disabled={!isRequireInputs()}
        onClick={() => onClickSaveUpdateBtn(id)}
      />
      <Button
        buttonClassName="text-xs bg-white text-primary-01 h-[28px]"
        text={isAddOrder || isUpdate ? '취소' : '삭제'}
        bold={false}
        grayBorder
        onClick={() => onClickCancelDeleteBtn(id)}
      />
      <DaumPostCode
        isOpen={isOpenPost}
        getPost={getAddress}
        onCancel={() => setIsOpenPost(false)}
      />
    </div>
  );
}
