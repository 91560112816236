/**
 * @file GlobalModal
 * @author chad / chad@o2pluss.com
 * @description
 */

import { useRecoilValue } from 'recoil';
import { modalAtom } from '@recoil/modal';
import Modal from './Modal';

export default function GlobalModal() {
  const modalProps = useRecoilValue(modalAtom);

  const renderComponent = () => {
    if (!modalProps) {
      return null;
    }

    return <Modal {...modalProps} />;
  };

  return <div>{renderComponent()}</div>;
}
