/**
 * @file: HitoryDetailTableHeader.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.22 ~
 * @description: 접수 내역 상세 테이블 헤더 컴포넌트
 */

import classNames from 'classnames';
import { HISTORY_DETAIL_TABLE_HEADER } from '@constants/receiptHistory';

import Card from '@components/atom/Card';

export default function HitoryDetailTableHeader() {
  const TABLE_HEADER: HISTORY_DETAIL_TABLE_HEADER[] = [];
  for (const value of Object.values(HISTORY_DETAIL_TABLE_HEADER)) {
    TABLE_HEADER.push(value);
  }

  return (
    <Card cardClassName="flex mb-3 !px-3 py-2 !bg-[#FCFCFC] w-min">
      {TABLE_HEADER.map((value, index) => (
        <div
          className={classNames(
            'px-2 text-secondary-01 text-xs',
            index === 0 || index === 2 || index === 12
              ? 'w-[96px]'
              : index === 1 ||
                index === 8 ||
                index === 9 ||
                index === 10 ||
                index === 11
              ? 'w-[280px]'
              : 'w-[140px]',
          )}
          key={index}
        >
          {value}
        </div>
      ))}
    </Card>
  );
}
