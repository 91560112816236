/**
 * @file RouteListUpdate.tsx
 * @author chad / chad@o2pluss.com
 * @description 경로 정보 리스트 수정 모달
 */

import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { riderInfoData } from '@data/riderData';
import { vehicleInfoData } from '@data/vehicleData';
import { useModal } from '@hooks/useModal';

import ModalTemplate from '@components/templates/ModalTemplate';
import Button from '@components/atom/Button';
import Input from '@components/molecules/Input';
import Select from '@components/atom/Select';
import { ISelectedRouteInfo } from '../RouteCreateWithMap';

export interface IUpdateRouteInfo
  extends Omit<
    ISelectedRouteInfo,
    'sigunguName' | 'orderCount' | 'quantityCount' | 'groupOrderInfo'
  > {}

interface IProps {
  isOpen: boolean;
  index: number;
  defaultValue: IUpdateRouteInfo;
  selectedRouteList: ISelectedRouteInfo[];
  setSelectedRouteList: Dispatch<SetStateAction<ISelectedRouteInfo[]>>;
  closeModal: () => void;
}

export default function RouteListUpdate({
  isOpen,
  index,
  defaultValue,
  selectedRouteList,
  setSelectedRouteList,
  closeModal,
}: IProps) {
  const { showModal } = useModal();

  const [updateRouteName, setUpdateRouteName] = useState<string>('');
  const [updateAssignRider, setUpdateAssignrider] = useState<string>('');
  const [updateAssignVehicle, setUpdateAssignVehicle] = useState<string>('');

  useEffect(() => {
    setUpdateRouteName(defaultValue.routeName);
    setUpdateAssignrider(defaultValue.assignRider);
    setUpdateAssignVehicle(defaultValue.assignVehicle);
  }, [
    defaultValue.assignRider,
    defaultValue.assignVehicle,
    defaultValue.routeName,
  ]);

  /**
   * 경로명, 기사, 차량 값이 없으면 true 반환
   * @returns boolean
   */
  const isNoValue = useCallback(() => {
    return !updateRouteName || !updateAssignRider || !updateAssignVehicle
      ? true
      : false;
  }, [updateAssignRider, updateAssignVehicle, updateRouteName]);

  useEffect(() => {
    isNoValue();
  }, [isNoValue]);

  /** 경로명 input */
  const onChangeRouteNameInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setUpdateRouteName(value);
    },
    [],
  );

  /** 저장하기 버튼 (수정) */
  const onClickSaveBtn = useCallback(() => {
    const updateValue: any = selectedRouteList.map((list, listIndex) => {
      if (index === listIndex) {
        return {
          ...list,
          routeName: updateRouteName,
          assignRider: updateAssignRider,
          assignVehicle: updateAssignVehicle,
        };
      } else {
        return {
          ...list,
        };
      }
    });

    showModal({
      title: '정보 수정',
      message: '수정이 완료되었습니다.',
    });

    setSelectedRouteList(updateValue);
    closeModal();
  }, [
    closeModal,
    index,
    selectedRouteList,
    setSelectedRouteList,
    showModal,
    updateAssignRider,
    updateAssignVehicle,
    updateRouteName,
  ]);

  /** 취소 버튼 */
  const onClickCancelBtn = useCallback(() => {
    setUpdateRouteName(defaultValue.routeName);
    setUpdateAssignrider(defaultValue.assignRider);
    setUpdateAssignVehicle(defaultValue.assignVehicle);
    closeModal();
  }, [
    closeModal,
    defaultValue.assignRider,
    defaultValue.assignVehicle,
    defaultValue.routeName,
  ]);

  return (
    <ModalTemplate isOpen={isOpen} className="w-[500px]">
      <p className="text-xl font-bold">경로 정보 수정</p>

      <div className="my-6">
        <Input
          className="h-[48px] border-primary-01 text-sm"
          value={updateRouteName}
          onChange={onChangeRouteNameInput}
        />
        <Select
          selectClassName="my-3"
          selectOption={updateAssignRider}
          setSelectOption={(value) => setUpdateAssignrider(value)}
          optionList={riderInfoData}
        />
        <Select
          selectOption={updateAssignVehicle}
          setSelectOption={(value) => setUpdateAssignVehicle(value)}
          optionList={vehicleInfoData}
        />
      </div>

      <div className="text-right mt-6">
        <Button
          buttonClassName="h-[36px] text-sm mr-2"
          text="취소"
          smallPadding
          blackBorder
          onClick={onClickCancelBtn}
        />
        <Button
          buttonClassName={`${
            !isNoValue() ? 'bg-primary-01' : 'bg-primary-02'
          } h-[36px] text-sm text-white`}
          text="저장하기"
          smallPadding
          disabled={isNoValue()}
          onClick={onClickSaveBtn}
        />
      </div>
    </ModalTemplate>
  );
}
