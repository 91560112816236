import classNames from 'classnames';
// import { useParams } from 'react-router-dom';

import { INavItem } from 'interfaces/sideNav.interface';
import { SNB_ROUTE_LIST } from '@constants/navigation';

import { AiFillFlag, AiTwotoneSetting } from 'react-icons/ai';
import { FaListUl } from 'react-icons/fa';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import { LiaUserCogSolid } from 'react-icons/lia';
import { FiTruck } from 'react-icons/fi';

interface IProps extends INavItem {
  className?: string;
}

export default function SnbLinkItem({
  className,
  code,
  label,
  onClick,
}: IProps) {
  return (
    <>
      <div
        className={classNames(
          'flex p-4 items-center cursor-pointer',
          className,
        )}
        role="presentation"
        onClick={onClick}
      >
        <span className="pr-2">
          {code === SNB_ROUTE_LIST.ROUTE.CODE ? (
            <AiFillFlag />
          ) : code === SNB_ROUTE_LIST.RECEIPT.CODE ? (
            <FaListUl />
          ) : code === SNB_ROUTE_LIST.TRANSPORT.CODE ? (
            <RiLayoutMasonryFill />
          ) : code === SNB_ROUTE_LIST.RIDER.CODE ? (
            <LiaUserCogSolid />
          ) : code === SNB_ROUTE_LIST.VEHICLE.CODE ? (
            <FiTruck />
          ) : (
            <AiTwotoneSetting />
          )}
        </span>
        <span>{label}</span>
      </div>
    </>
  );
}
