/**
 * @file TotalCount
 * @author chad / chad@o2pluss.com
 * @description
 */

interface IProps {
  className?: string;
  total: number | undefined;
  unit?: string;
}

export default function TotalCount({ total, className, unit = '건' }: IProps) {
  return (
    <div className={className}>
      <span>
        총<span className="font-bold px-2">{total}</span>
        {unit}
      </span>
    </div>
  );
}
