export const DELIVERY_STATUS = {
  ALL: '전체',
  DELIVERY: '운송 중',
  WAIT: '운송 대기',
  COMPLETE: '운송 완료',
} as const;
export type DELIVERY_STATUS =
  (typeof DELIVERY_STATUS)[keyof typeof DELIVERY_STATUS];

export const VEHICLE_STATUS = {
  ALL: '전체',
  DELIVERY: '운행 중',
  WAIT: '대기 중',
} as const;
export type VEHICLE_STATUS =
  (typeof VEHICLE_STATUS)[keyof typeof VEHICLE_STATUS];

export const DELIVERY_STATUS_LIST = [
  { key: 'ALL', label: DELIVERY_STATUS.ALL },
  { key: 'DELIVERY', label: DELIVERY_STATUS.DELIVERY },
  { key: 'WAIT', label: DELIVERY_STATUS.WAIT },
  { key: 'COMPLETE', label: DELIVERY_STATUS.COMPLETE },
] as const;
