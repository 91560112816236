/**
 * @file: Badge.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.21 ~
 * @description: 상태 노출 부분에 사용되는 badge 컴포넌트
 */

import { DELIVERY_STATUS } from '@constants/common';
import classNames from 'classnames';
import { useMemo } from 'react';

interface IProps {
  status?: string;
  isShow?: boolean;
  useShowStatus?: boolean;
  useDeliveryStatus?: boolean;
  size?: 'sm' | 'md';
}

export default function Badge({
  useShowStatus = false,
  useDeliveryStatus = false,
  status,
  isShow,
  size = 'sm',
}: IProps) {
  const showItemClassName = useMemo(() => {
    if (!useShowStatus) '';
    else {
      return isShow
        ? 'text-state-info bg-state-infoBg'
        : 'text-gray-400 bg-[#f8f8f8]';
    }
  }, [useShowStatus, isShow]);

  const deliverStatusClassName = useMemo(() => {
    if (!useDeliveryStatus) '';

    switch (status) {
      case DELIVERY_STATUS.COMPLETE:
        return 'text-gray-400 bg-[#f8f8f8]';
      case DELIVERY_STATUS.DELIVERY:
        return 'text-state-info bg-state-infoBg';
      case DELIVERY_STATUS.WAIT:
        return 'text-state-warning bg-state-warningBg';
    }
  }, [status, useDeliveryStatus]);

  const sizeClassName = useMemo(() => {
    switch (size) {
      case 'md':
        return 'h-[24px]';
      case 'sm':
        return 'h-[18px] text-xs';
    }
  }, [size]);

  return (
    <div
      className={classNames(
        'rounded w-max font-bold px-2',
        showItemClassName,
        deliverStatusClassName,
        sizeClassName,
      )}
    >
      {status}
    </div>
  );
}
