/**
 * @file: TransportStatus.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.22 ~
 * @description: 운송현황 페이지
 */

import { useState, useEffect } from 'react';
import { DELIVERY_STATUS_LIST, DELIVERY_STATUS } from '@constants/common';
import { receiptData, IReceiptRouteData } from '@data/receiptHistoryData';
import { useCompleteOptimization } from '@hooks/recoil/useRouteOrder';

import Button from '@components/atom/Button';
import TotalCount from '@components/atom/TotalCount';
import TransportStatusCard from '@components/molecules/TransportStatusCard';

import { useSelectedTransportData } from '@hooks/recoil/useTransportStatus';

export default function TransportStatus() {
  const [selectDeliveryStatus, setSelectDeliveryStatus] =
    useState<DELIVERY_STATUS>(DELIVERY_STATUS.ALL);
  const [useReceiptRouteData, setUseReceiptRouteData] =
    useState<IReceiptRouteData[]>();
  const [selectedCard, setSelectedCard] = useState<number>(1);

  const { setSelectedTranport } = useSelectedTransportData();
  const { isCompleteOptimization } = useCompleteOptimization();

  /** 접수 내역 필터 */
  const filteredReceiptData = () => {
    receiptData.forEach((data) => {
      const filtered = data.ROUTE_DATA.filter(
        (item) =>
          item.DELIVERY_STATUS === selectDeliveryStatus ||
          selectDeliveryStatus === '전체',
      );

      setUseReceiptRouteData(filtered);
      setSelectedTranport(filtered[0]);
    });
  };

  useEffect(() => {
    if (isCompleteOptimization) filteredReceiptData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDeliveryStatus]);

  useEffect(() => {
    receiptData.forEach((data) => {
      setUseReceiptRouteData(data.ROUTE_DATA);
    });
  }, []);

  return (
    <>
      <div className="pb-6">
        {DELIVERY_STATUS_LIST.map(({ key, label }) => (
          <Button
            key={key}
            buttonClassName="h-[32px] mr-1"
            text={label}
            chip
            grayBorder
            blackBorder={selectDeliveryStatus === label ? true : false}
            smallPadding
            bold={false}
            onClick={() => {
              setSelectDeliveryStatus(label);
            }}
          />
        ))}
      </div>

      <TotalCount
        className="mb-4"
        total={isCompleteOptimization ? useReceiptRouteData?.length : 0}
      />

      {isCompleteOptimization && useReceiptRouteData?.length ? (
        useReceiptRouteData?.map((data, index) => (
          <TransportStatusCard
            key={index}
            data={data}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />
        ))
      ) : (
        <div className="h-[300px] flex items-center justify-center text-[#676767]">
          운송 상태가 없습니다.
        </div>
      )}
    </>
  );
}
