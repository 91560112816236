/**
 * @file useRouteOrder.tsx
 * @author chad / chad@o2pluss.com
 * @description 리코일에 유저 정보 저장
 */

import { useRecoilState } from 'recoil';
import { IUserInfo } from '@data/userInfoData';
import { userInfoAtom } from '@recoil/userInfo';

export const useUserInfo = () => {
  const [userInfo, setUserInfo] = useRecoilState<IUserInfo>(userInfoAtom);

  return {
    userInfo,
    setUserInfo,
  };
};
