import { atom } from 'recoil';
import { IUserInfo } from '@data/userInfoData';
import { RECOIL_KEY } from '@constants/recoilKeys';

export const userInfoAtom = atom<IUserInfo>({
  key: RECOIL_KEY.USER_INFO_KEY,
  default: {
    id: 0,
    email: '',
    password: '',
    businessAddress: '',
    businessAddressDetail: '',
    bAddressX: 0,
    bAddressY: 0,
    warehouseAddress: '',
    warehouseAddressDetail: '',
    wAddressX: 0,
    wAddressY: 0,
  },
});
