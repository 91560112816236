import { DELIVERY_STATUS } from '@constants/common';
import { IRouteExcelData } from './routeExcelUploadData';

export interface IRiderInfo {
  name: string;
  phone: string | number;
  memo: string;
  rider_lng?: string | number | null;
  rider_lat?: string | number | null;
}

export interface IVehiclInfo {
  name: string;
  vehicle_num: string;
  memo: string;
}

export interface IReceiptRouteData {
  id: number;
  NO: number | string;
  ROUTE_NAME?: string;
  RECEIPT_NUM?: string;
  ORDER_NUM: number;
  ARTICLE_NUM: number;
  IS_SHOW?: boolean;
  DELIVERY_STATUS?: string;
  RIDER_IN_CHARGE?: string;
  VEHICLE_NUM?: string;
  STARTED_AT?: string | null;
  COMPLETE_AT?: string | null;
  RIDER_INFO?: IRiderInfo;
  VEHICLE_INFO?: IVehiclInfo;
  LIST_ITEM?: IRouteExcelData[];
}

export interface IReceiptData {
  NO: number | string;
  RECEIPT_NUM: string | null;
  ORDER_NUM?: number;
  ARTICLE_NUM: number;
  ROUTE_DATA: IReceiptRouteData[];
}

export const receiptData: IReceiptData[] = [
  {
    NO: 1,
    RECEIPT_NUM: 'TA240306-01',
    ORDER_NUM: 51,
    ARTICLE_NUM: 423,
    ROUTE_DATA: [
      {
        id: 1,
        NO: '1-1',
        RECEIPT_NUM: 'TA240306-01',
        ROUTE_NAME: '신/구 제주',
        ORDER_NUM: 29,
        ARTICLE_NUM: 230,
        IS_SHOW: true,
        DELIVERY_STATUS: DELIVERY_STATUS.DELIVERY,
        RIDER_IN_CHARGE: '이찬우 9384',
        VEHICLE_NUM: '92무 5784',
        STARTED_AT: null,
        COMPLETE_AT: null,
        RIDER_INFO: {
          name: '이찬우',
          phone: '010-2343-9384',
          memo: '연락 잘되시고, 누구보다 빠르게 배송',
          rider_lng: 126.530125,
          rider_lat: 33.5003515,
        },
        VEHICLE_INFO: {
          name: '윙바디 1호기',
          vehicle_num: '92무 5784',
          memo: '냉동 / 냉장 / 덮개 있음',
        },
        LIST_ITEM: [
          {
            id: 1,
            order_num: '20231201-01',
            order_name: '유미연',
            order_contact: '010-3232-3332',
            reciever: '홍재성',
            reciever_contact: '010-3390-4447',
            add_contact: '010-6142-3094',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '외도1동',
            recieve_address_1: '제주특별자치도 제주시 우정로 60',
            recieve_address_2: '지하 3층 입구',
            jibun: '제주특별자치도 제주시 외도1동 541-13',
            x: 126.4306693,
            y: 33.48750436,
            delivery_req: '로비에 맡겨주세요.',
            article: '핸드폰',
            quantity: 6,
            isDelivery: true,
            imageUrl: '',
            status: DELIVERY_STATUS.COMPLETE,
            completed_at: null,
          },
          {
            id: 2,
            order_num: '20231201-02',
            order_name: '예민우',
            order_contact: '010-2462-5846',
            reciever: '김찬우',
            reciever_contact: '010-3863-7133',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '외도1동',
            recieve_address_1: '제주특별자치도 제주시 우정로15길 5',
            recieve_address_2: 'A-3동 2층',
            jibun: '제주특별자치도 제주시 외도1동 639-3',
            x: 126.4317287,
            y: 33.48609192,
            delivery_req: '오시기 전에 연락 주세요.',
            article: '노트북',
            quantity: 13,
            isDelivery: true,
            imageUrl: '',
            status: DELIVERY_STATUS.COMPLETE,
            completed_at: null,
          },
          {
            id: 3,
            order_num: '20231201-03',
            order_name: '표명숙',
            order_contact: '010-8638-0575',
            reciever: '서영숙',
            reciever_contact: '010-6125-3249',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '외도1동',
            recieve_address_1: '제주특별자치도 제주시 우정로11길 22',
            recieve_address_2: '외도부영1차 104동 1011호',
            jibun: '제주특별자치도 제주시 외도1동 560-1',
            x: 126.433703,
            y: 33.48644204,
            delivery_req: '',
            article: '가방',
            quantity: 3,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.DELIVERY,
            completed_at: null,
          },
          {
            id: 4,
            order_num: '20231201-04',
            order_name: '안기혁',
            order_contact: '010-2583-3205',
            reciever: '임윤희',
            reciever_contact: '010-9376-8598',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이호2동',
            recieve_address_1: '제주특별자치도 제주시 오도11길 19-1',
            recieve_address_2: '1층',
            jibun: '제주특별자치도 제주시 이호2동 1095',
            x: 126.4599767,
            y: 33.4899977,
            delivery_req: '없어요',
            article: '책상',
            quantity: 7,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 5,
            order_num: '20231201-05',
            order_name: '권영숙',
            order_contact: '010-6052-9092',
            reciever: '안기혁',
            reciever_contact: '010-7243-1286',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이호2동',
            recieve_address_1: '제주특별자치도 제주시 대동길 17-1',
            recieve_address_2: '402호',
            jibun: '제주특별자치도 제주시 이호이동 116-3',
            x: 126.4635886,
            y: 33.4933673,
            delivery_req: '',
            article: '과일',
            quantity: 4,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 6,
            order_num: '20231201-07',
            order_name: '황광호',
            order_contact: '010-6843-1582',
            reciever: '예은용',
            reciever_contact: '010-7576-6338',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '도두1동',
            recieve_address_1: '제주특별자치도 제주시 도공로 2',
            recieve_address_2: '3차 503호',
            jibun: '제주특별자치도 제주시 도두일동 2614-5',
            x: 126.4649822,
            y: 33.50612901,
            delivery_req: '',
            article: '옷',
            quantity: 8,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 7,
            order_num: '20231201-06',
            order_name: '홍현식',
            order_contact: '010-7576-6338',
            reciever: '류보영',
            reciever_contact: '010-5488-9194',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이호2동',
            recieve_address_1: '제주특별자치도 제주시 월랑로 91',
            recieve_address_2: '나동 302호',
            jibun: '제주특별자치도 제주시 이호이동 22',
            x: 126.4705035,
            y: 33.49228629,
            delivery_req: '',
            article: '가전제품',
            quantity: 6,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 8,
            order_num: '20231201-08',
            order_name: '설대영',
            order_contact: '010-5071-7664',
            reciever: '최우준',
            reciever_contact: '010-6843-1582',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '도두1동',
            recieve_address_1: '제주특별자치도 제주시 일주서로 7714',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 도두일동 2288',
            x: 126.4706157,
            y: 33.49347795,
            delivery_req: '연락주시면 1층으로 나옵니다',
            article: '애견용품',
            quantity: 10,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 9,
            order_num: '20231201-09',
            order_name: '장수민',
            order_contact: '010-7636-1857',
            reciever: '김현수',
            reciever_contact: '010-2529-7634',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '도두2동',
            recieve_address_1: '제주특별자치도 제주시 오일장중길 128',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 도두이동 1106-1',
            x: 126.4800192,
            y: 33.4997582,
            delivery_req: '',
            article: '음향기기',
            quantity: 8,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 10,
            order_num: '20231201-16',
            order_name: '풍연석',
            order_contact: '010-4638-1516',
            reciever: '전서우',
            reciever_contact: '010-5933-7363',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담2동',
            recieve_address_1: '제주특별자치도 제주시 공항로 2',
            recieve_address_2: '1층 1번게이트',
            jibun: '제주특별자치도 제주시 용담2동 2002',
            x: 126.4938576,
            y: 33.50579497,
            delivery_req: '',
            article: '가방',
            quantity: 15,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 11,
            order_num: '20231201-13',
            order_name: '홍재성',
            order_contact: '010-5957-3508',
            reciever: '김진영',
            reciever_contact: '010-8804-0081',
            add_contact: '010-3548-0857',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담2동',
            recieve_address_1: '제주특별자치도 제주시 용해로 92',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담2동 855',
            x: 126.5014587,
            y: 33.50901749,
            delivery_req: '',
            article: '안경',
            quantity: 5,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 12,
            order_num: '20231201-15',
            order_name: '설진미',
            order_contact: '010-4206-6902',
            reciever: '노만옥',
            reciever_contact: '010-2513-5297',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담2동',
            recieve_address_1: '제주특별자치도 제주시 용문로 157',
            recieve_address_2: '641동 1호 반석텔레콤',
            jibun: '제주특별자치도 제주시 용담2동 641-1',
            x: 126.509957,
            y: 33.50890083,
            delivery_req: '',
            article: '노트북',
            quantity: 7,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 13,
            order_num: '20231201-14',
            order_name: '손하현',
            order_contact: '010-2261-6922',
            reciever: '손원철',
            reciever_contact: '010-7326-9997',
            add_contact: '010-3956-1701',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담2동',
            recieve_address_1: '제주특별자치도 제주시 용담로 90',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담2동 359-58',
            x: 126.5121006,
            y: 33.51202158,
            delivery_req: '',
            article: '핸드폰',
            quantity: 10,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 14,
            order_num: '20231201-10',
            order_name: '김성환',
            order_contact: '010-7958-7628',
            reciever: '유미연',
            reciever_contact: '010-6270-3794',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담1동',
            recieve_address_1: '제주특별자치도 제주시 서문로 29',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담1동 301-5',
            x: 126.5145717,
            y: 33.51056634,
            delivery_req: '3층 유미연 고객님',
            article: '태블릿',
            quantity: 7,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 15,
            order_num: '20231201-12',
            order_name: '권윤미',
            order_contact: '010-8151-1689',
            reciever: '문영은',
            reciever_contact: '010-8638-0575',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담1동',
            recieve_address_1: '제주특별자치도 제주시 서문로 57',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담1동 129-6',
            x: 126.5172774,
            y: 33.51167761,
            delivery_req: '',
            article: '신발',
            quantity: 14,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 16,
            order_num: '20231201-21',
            order_name: '윤효원',
            order_contact: '010-5178-9746',
            reciever: '손영훈',
            reciever_contact: '010-2462-5846',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도2동',
            recieve_address_1: '제주특별자치도 제주시 무근성5길 18',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도2동 1139-1',
            x: 126.5195577,
            y: 33.51426863,
            delivery_req: '',
            article: '애견용품',
            quantity: 4,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 17,
            order_num: '20231201-22',
            order_name: '문영은',
            order_contact: '010-1431-7237',
            reciever: '한창민',
            reciever_contact: '010-4111-3755',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이도1동',
            recieve_address_1: '제주특별자치도 제주시 중앙로 70',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 이도1동 1369-1',
            x: 126.5244737,
            y: 33.51202281,
            delivery_req: '',
            article: '음향기기',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 18,
            order_num: '20231201-11',
            order_name: '배종식',
            order_contact: '010-6535-1533',
            reciever: '안원기',
            reciever_contact: '010-3828-6343',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담1동',
            recieve_address_1: '제주특별자치도 제주시 남성로4길 1',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담1동 2818-9',
            x: 126.5132579,
            y: 33.50217715,
            delivery_req: '',
            article: '서류',
            quantity: 1,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 19,
            order_num: '20231201-18',
            order_name: '유태훈',
            order_contact: '010-4824-0445',
            reciever: '신윤재',
            reciever_contact: '010-2583-3205',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도1동',
            recieve_address_1: '제주특별자치도 제주시 서사로 96',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도1동 530-23',
            x: 126.5196457,
            y: 33.50387836,
            delivery_req: '',
            article: '과일',
            quantity: 3,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 20,
            order_num: '20231201-20',
            order_name: '표태용',
            order_contact: '010-8469-4292',
            reciever: '유승환',
            reciever_contact: '010-7816-7524',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도2동',
            recieve_address_1: '제주특별자치도 제주시 중앙로26길 22',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도2동 1239-10',
            x: 126.5233215,
            y: 33.50553709,
            delivery_req: '',
            article: '옷',
            quantity: 13,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 21,
            order_num: '20231201-17',
            order_name: '홍은정',
            order_contact: '010-9353-9339',
            reciever: '복준하',
            reciever_contact: '010-4206-6902',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도1동',
            recieve_address_1: '제주특별자치도 제주시 서광로 229',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도1동 552-8',
            x: 126.5209751,
            y: 33.5000362,
            delivery_req: '',
            article: '책상',
            quantity: 10,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 22,
            order_num: '20231201-19',
            order_name: '유윤준',
            order_contact: '010-1485-0190',
            reciever: '정민석',
            reciever_contact: '010-9598-4959',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도1동',
            recieve_address_1: '제주특별자치도 제주시 서광로 237-1',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도1동 552-24',
            x: 126.5218884,
            y: 33.50000302,
            delivery_req: '',
            article: '가전제품',
            quantity: 1,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 23,
            order_num: '20231201-23',
            order_name: '권진우',
            order_contact: '010-7967-2146',
            reciever: '하철민',
            reciever_contact: '010-4024-1221',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이도2동',
            recieve_address_1: '제주특별자치도 제주시 중앙로 279',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 이도2동 1057-3',
            x: 126.5332468,
            y: 33.49546277,
            delivery_req: '',
            article: '태블릿',
            quantity: 13,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 24,
            order_num: '20231201-24',
            order_name: '임정숙',
            order_contact: '010-1245-4380',
            reciever: '오형수',
            reciever_contact: '010-4074-7833',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '화북1동',
            recieve_address_1: '제주특별자치도 제주시 일주동로 254',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 화북1동 1230',
            x: 126.5723692,
            y: 33.51988238,
            delivery_req: '',
            article: '서류',
            quantity: 7,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 25,
            order_num: '20231201-25',
            order_name: '고희수',
            order_contact: '010-1352-7413',
            reciever: '조동일',
            reciever_contact: '010-9210-8551',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼양동',
            recieve_address_1: '제주특별자치도 제주시 화삼북로 90',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼양동 1909',
            x: 126.582654,
            y: 33.51655645,
            delivery_req: '',
            article: '신발',
            quantity: 8,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 26,
            order_num: '20231201-26',
            order_name: '조규원',
            order_contact: '010-3405-9385',
            reciever: '김미숙',
            reciever_contact: '010-1448-5435',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '아라1동',
            recieve_address_1: '제주특별자치도 제주시 제주대학로 102',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 아라1동 1',
            x: 126.560822,
            y: 33.45265161,
            delivery_req: '',
            article: '안경',
            quantity: 4,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 27,
            order_num: '20231201-27',
            order_name: '노만옥',
            order_contact: '010-3548-0857',
            reciever: '조하영',
            reciever_contact: '010-1851-7664',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '아라2동',
            recieve_address_1: '제주특별자치도 제주시 신설로11길 2-10',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 아라2동 3009-5',
            x: 126.5443405,
            y: 33.49098938,
            delivery_req: '',
            article: '핸드폰',
            quantity: 3,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 28,
            order_num: '20231201-28',
            order_name: '허남혁',
            order_contact: '010-1914-8082',
            reciever: '유연웅',
            reciever_contact: '010-4638-1516',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '오라3동',
            recieve_address_1: '제주특별자치도 제주시 연삼로 120',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 오라3동 2811-3',
            x: 126.5051068,
            y: 33.49285768,
            delivery_req: '',
            article: '노트북',
            quantity: 15,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 29,
            order_num: '20231201-29',
            order_name: '이용욱',
            order_contact: '010-3932-8936',
            reciever: '허태욱',
            reciever_contact: '010-2743-5775',
            add_contact: '010-1448-5435',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '노형동',
            recieve_address_1: '제주특별자치도 제주시 노형로 375',
            recieve_address_2: '베테랑모바일',
            jibun: '제주특별자치도 제주시 노형동 2536-11',
            x: 126.4757322,
            y: 33.48380372,
            delivery_req: '',
            article: '가방',
            quantity: 13,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
        ],
      },
      {
        id: 2,
        NO: '1-2',
        RECEIPT_NUM: 'TA240306-01',
        ROUTE_NAME: '제주 서쪽 지역',
        ORDER_NUM: 22,
        ARTICLE_NUM: 193,
        IS_SHOW: true,
        DELIVERY_STATUS: DELIVERY_STATUS.WAIT,
        RIDER_IN_CHARGE: '김길수 4885',
        VEHICLE_NUM: '91배 6255',
        STARTED_AT: null,
        COMPLETE_AT: null,
        RIDER_INFO: {
          name: '김길수',
          phone: '010-2343-9384',
          memo: '연락 잘되시고, 누구보다 빠르게 배송',
          rider_lng: null,
          rider_lat: null,
        },
        VEHICLE_INFO: {
          name: '탑차 3호기',
          vehicle_num: '91배 6255',
          memo: '언덕에서 좀 힘듦',
        },
        LIST_ITEM: [
          {
            id: 30,
            order_num: '20231201-35',
            order_name: '심영훈',
            order_contact: '010-8804-0081',
            reciever: '송지우',
            reciever_contact: '010-7636-1857',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 하귀로 56',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 하귀2리 1879-2',
            x: 126.4033069,
            y: 33.48028194,
            delivery_req: '',
            article: '음향기기',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 31,
            order_num: '20231201-31',
            order_name: '강창진',
            order_contact: '010-9376-8598',
            reciever: '오경희',
            reciever_contact: '010-7738-8923',
            add_contact: '010-9910-1309',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 고성서3길 15-5',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 고성리 1488-1',
            x: 126.4107586,
            y: 33.46598232,
            delivery_req: '',
            article: '과일',
            quantity: 3,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 32,
            order_num: '20231201-32',
            order_name: '전서우',
            order_contact: '010-3956-1701',
            reciever: '허기현',
            reciever_contact: '010-3405-9385',
            add_contact: '010-7426-8590',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 애조로 405',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 광령리 3672-1',
            x: 126.4264867,
            y: 33.47320957,
            delivery_req: '',
            article: '가전제품',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 33,
            order_num: '20231201-34',
            order_name: '김태주',
            order_contact: '010-5685-3516',
            reciever: '송윤미',
            reciever_contact: '010-4902-4995',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 유수암평화길 14-3',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 유수암리 1894',
            x: 126.4027879,
            y: 33.44113598,
            delivery_req: '',
            article: '애견용품',
            quantity: 11,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 34,
            order_num: '20231201-33',
            order_name: '하철민',
            order_contact: '010-6273-1340',
            reciever: '설대영',
            reciever_contact: '010-4424-8664',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 소길리 154',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 소길리 154',
            x: 126.3895793,
            y: 33.41937209,
            delivery_req: '',
            article: '옷',
            quantity: 9,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 35,
            order_num: '20231201-30',
            order_name: '박은미',
            order_contact: '010-9534-3263',
            reciever: '김태주',
            reciever_contact: '010-8917-5951',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 신엄안3길 99',
            recieve_address_2: '애월차이나',
            jibun: '제주특별자치도 제주시 애월읍 고내리 11-6',
            x: 126.357377,
            y: 33.47229128,
            delivery_req: '빠른배송부탁드립니다',
            article: '책상',
            quantity: 2,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 36,
            order_num: '20231201-36',
            order_name: '유승환',
            order_contact: '010-6142-3094',
            reciever: '손하현',
            reciever_contact: '010-3195-2873',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한림읍',
            recieve_address_1: '제주특별자치도 제주시 한림읍 귀덕14길 14-5',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한림읍 귀덕리 1157',
            x: 126.2934545,
            y: 33.44241942,
            delivery_req: '',
            article: '태블릿',
            quantity: 5,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 37,
            order_num: '20231201-37',
            order_name: '예은용',
            order_contact: '010-7738-8923',
            reciever: '고희수',
            reciever_contact: '010-1352-7413',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한림읍',
            recieve_address_1: '제주특별자치도 제주시 한림읍 한수풀로 146',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한림읍 대림리 431',
            x: 126.2769396,
            y: 33.42151129,
            delivery_req: '',
            article: '서류',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 38,
            order_num: '20231201-38',
            order_name: '한창민',
            order_contact: '010-3110-4747',
            reciever: '안석주',
            reciever_contact: '010-8395-2988',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한림읍',
            recieve_address_1: '제주특별자치도 제주시 한림읍 강구로 1',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한림읍 한림리 968',
            x: 126.2690672,
            y: 33.4120918,
            delivery_req: '',
            article: '신발',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 39,
            order_num: '20231201-39',
            order_name: '성동훈',
            order_contact: '010-1396-1467',
            reciever: '이용욱',
            reciever_contact: '010-7630-6133',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한림읍',
            recieve_address_1: '제주특별자치도 제주시 한림읍 한림로 329-10',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한림읍 협재리 2447',
            x: 126.2391566,
            y: 33.39386651,
            delivery_req: '',
            article: '안경',
            quantity: 14,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 40,
            order_num: '20231201-41',
            order_name: '강해빈',
            order_contact: '010-7243-1286',
            reciever: '권윤미',
            reciever_contact: '010-7958-7628',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한경면',
            recieve_address_1: '제주특별자치도 제주시 한경면 신한로 76',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한경면 신창리 18-2',
            x: 126.190287,
            y: 33.34664181,
            delivery_req: '',
            article: '노트북',
            quantity: 4,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 41,
            order_num: '20231201-40',
            order_name: '안원기',
            order_contact: '010-2041-4482',
            reciever: '복영수',
            reciever_contact: '010-7426-8590',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한경면',
            recieve_address_1: '제주특별자치도 제주시 한경면 신한로 176-6',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한경면 두모리 1830-2',
            x: 126.194196,
            y: 33.33941204,
            delivery_req: '',
            article: '핸드폰',
            quantity: 2,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 42,
            order_num: '20231201-43',
            order_name: '유원진',
            order_contact: '010-6270-3794',
            reciever: '심영훈',
            reciever_contact: '010-5071-7664',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '대정읍',
            recieve_address_1: '제주특별자치도 서귀포시 대정읍 에듀시티로 148',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 대정읍 보성리 2424',
            x: 126.2752703,
            y: 33.2846186,
            delivery_req: '',
            article: '책상',
            quantity: 7,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 43,
            order_num: '20231201-50',
            order_name: '정석환',
            order_contact: '010-7759-5453',
            reciever: '홍은정',
            reciever_contact: '010-1372-6873',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 녹차분재로 81',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 서광리 1395',
            x: 126.2986303,
            y: 33.28978228,
            delivery_req: '',
            article: '서류',
            quantity: 15,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 44,
            order_num: '20231201-44',
            order_name: '김현수',
            order_contact: '010-8627-1744',
            reciever: '심성원',
            reciever_contact: '010-6142-3094',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '대정읍',
            recieve_address_1:
              '제주특별자치도 서귀포시 대정읍 글로벌에듀로 382',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 대정읍 보성리 2476-13',
            x: 126.2771664,
            y: 33.28016132,
            delivery_req: '',
            article: '과일',
            quantity: 3,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 45,
            order_num: '20231201-45',
            order_name: '박태지',
            order_contact: '010-1563-8275',
            reciever: '민규원',
            reciever_contact: '010-8343-3499',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '대정읍',
            recieve_address_1: '제주특별자치도 서귀포시 대정읍 신영로 120',
            recieve_address_2: '1층',
            jibun: '제주특별자치도 서귀포시 대정읍 하모리 820-15',
            x: 126.25462,
            y: 33.22247165,
            delivery_req: '고가제품입니다 배송에 유의해주세요.',
            article: '가전제품',
            quantity: 5,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 46,
            order_num: '20231201-42',
            order_name: '권광현',
            order_contact: '010-6285-2405',
            reciever: '임유정',
            reciever_contact: '010-4453-5070',
            add_contact: '010-6886-4339',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '대정읍',
            recieve_address_1:
              '제주특별자치도 서귀포시 대정읍 하모항구로10번길 23',
            recieve_address_2: '103호',
            jibun: '제주특별자치도 서귀포시 대정읍 하모리 2128',
            x: 126.2510504,
            y: 33.21829891,
            delivery_req: '긴급배송입니다.',
            article: '가방',
            quantity: 9,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 47, // 17
            order_num: '20231201-47',
            order_name: '손지희',
            order_contact: '010-9002-2357',
            reciever: '최도윤',
            reciever_contact: '010-5944-1765',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 사계남로 89',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 사계리 2172-1',
            x: 126.2993738,
            y: 33.22749046,
            delivery_req: '',
            article: '애견용품',
            quantity: 15,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 48,
            order_num: '20231201-51',
            order_name: '조하영',
            order_contact: '010-4111-3755',
            reciever: '황광호',
            reciever_contact: '010-3070-5803',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 화순로 105',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 화순리 1075-3',
            x: 126.3351455,
            y: 33.24649634,
            delivery_req: '',
            article: '신발',
            quantity: 8,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 49,
            order_num: '20231201-46',
            order_name: '복영수',
            order_contact: '010-6744-9885',
            reciever: '강만석',
            reciever_contact: '010-6001-6176',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 감산중로 5',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 감산리 391-1',
            x: 126.3521162,
            y: 33.26016804,
            delivery_req: '',
            article: '옷',
            quantity: 15,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 50,
            order_num: '20231201-48',
            order_name: '추인혁',
            order_contact: '010-6535-5095',
            reciever: '설수근',
            reciever_contact: '010-5685-3516',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1:
              '제주특별자치도 서귀포시 안덕면 중산간서로1615번길 258-5',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 상창리 2466-2',
            x: 126.3492974,
            y: 33.26276693,
            delivery_req: '',
            article: '음향기기',
            quantity: 6,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
          {
            id: 51,
            order_num: '20231201-49',
            order_name: '유연웅',
            order_contact: '010-7426-8590',
            reciever: '박태지',
            reciever_contact: '010-8151-1689',
            add_contact: '010-8804-0081',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 산록남로 863',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 상천리 산 62-3',
            x: 126.3885872,
            y: 33.31528848,
            delivery_req: '',
            article: '태블릿',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
            status: DELIVERY_STATUS.WAIT,
            completed_at: null,
          },
        ],
      },
    ],
  },
];
