/**
 * @file KakaoMap
 * @author chad / chad@o2pluss.com
 * @description 카카오맵
 */

import { useEffect } from 'react';
import classNames from 'classnames';
import { useUserInfo } from '@hooks/recoil/useUserInfo';
import { IRouteData } from '@data/routeExcelUploadData';
import { DELIVERY_STATUS } from '@constants/common';
import { useCompleteOptimization } from '@hooks/recoil/useRouteOrder';
import { IRiderInfo } from '@data/receiptHistoryData';

declare global {
  interface Window {
    kakao: any;
  }
}

interface IPosition {
  x: string | number | undefined;
  y: string | number | undefined;
  address1?: string;
  address2?: string;
  isDelivery?: boolean;
  status?: string;
}

interface IProps {
  className?: string;
  children: React.ReactNode;
  deliveryStatus?: string;
  riderInfo?: IRiderInfo;
  routeList?: IRouteData[];
}

export default function KakaoMap({
  className,
  children,
  routeList,
  riderInfo,
}: IProps) {
  const { userInfo } = useUserInfo();
  const { isCompleteOptimization } = useCompleteOptimization();

  useEffect(() => {
    const mapScript = document.createElement('script');
    mapScript.async = true;
    mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAOMAP_KEY}&autoload=false`;
    document.head.appendChild(mapScript);

    const onLoadKakaoMap = () => {
      const { kakao } = window;

      kakao.maps.load(() => {
        /** 지도 생성 */
        const mapContainer = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(userInfo.bAddressX, userInfo.bAddressY),
          level: 3,
        };
        const map = new kakao.maps.Map(mapContainer, options);

        /** 마커 좌표 */
        const positions: IPosition[] = [
          {
            x: userInfo.bAddressX,
            y: userInfo.bAddressY,
            address1: userInfo.businessAddress,
            address2: userInfo.businessAddressDetail,
          },
        ];
        routeList &&
          routeList.forEach((list) => {
            positions.push({
              x: list.x,
              y: list.y,
              address1: list.recieve_address_1,
              address2: list.recieve_address_2,
              isDelivery: list.isDelivery,
              status: list.status,
            });
          });

        /** 마커 생성 */
        positions.map((list, index) => {
          const customOverlay = new kakao.maps.CustomOverlay({
            position: new kakao.maps.LatLng(list.y, list.x),
            content: `
              <div
                class="w-[30px] h-[30px] flex items-center justify-center
                ${
                  index === 0
                    ? 'bg-green-500 text-white'
                    : list.status === DELIVERY_STATUS.COMPLETE
                    ? 'bg-gray-300 text-white'
                    : list.status === DELIVERY_STATUS.DELIVERY
                    ? 'bg-primary-01 text-white'
                    : 'bg-white border-solid border-2 border-primary-01'
                } rounded-3xl"
              >
                <span class="p-1 text-sm font-bold">
                  ${index === 0 ? '출발' : index}
                </span>
              </div>`,
          });

          customOverlay.setMap(map);
        });

        /** 기사 현위치 */
        const riderPosition = new kakao.maps.CustomOverlay({
          position: new kakao.maps.LatLng(
            riderInfo?.rider_lat,
            riderInfo?.rider_lng,
          ),
          content: `
              <div
                class="flex items-center justify-center w-[20px] h-[20px] border-solid border-2 border-red-500 rounded-3xl"
              >
                <span class="bg-red-500 w-[12px] h-[12px] rounded-3xl" />
              </div>`,
        });
        // deliveryStatus === DELIVERY_STATUS.DELIVERY &&
        riderInfo?.rider_lat &&
          isCompleteOptimization &&
          riderPosition.setMap(map);

        /** 선 연결 */
        const polylinePath: any[] = [];
        positions.length > 1 &&
          positions.map((latlng) => {
            polylinePath.push(new kakao.maps.LatLng(latlng.y, latlng.x));
          });
        const polyline = new kakao.maps.Polyline({
          path: polylinePath,
          strokeWeight: 3,
          strokeColor: 'black',
          strokeStyle: 'dashed',
        });
        polyline.setMap(map);

        /** 지도 범위 재설정 */
        const bounds = new kakao.maps.LatLngBounds();
        positions.map((latlng) => {
          bounds.extend(new kakao.maps.LatLng(latlng.y, latlng.x));
        });
        map.setBounds(bounds);
      });
    };
    mapScript.addEventListener('load', onLoadKakaoMap);
    return () => mapScript.removeEventListener('load', onLoadKakaoMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeList]);

  return (
    <div id="map" className={classNames('w-full h-full', className)}>
      {children}
    </div>
  );
}

// const MARKER_IMAGE_SRC =
//   'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png';
// const markerSize = new kakao.maps.Size(20, 20);
// const arriveMarkerOption = {
//   offset: new kakao.maps.Point(10, 15),
// };

// const markerOptios = new kakao.maps.MarkerImage(
//   MARKER_IMAGE_SRC,
//   markerSize,
//   arriveMarkerOption,
// );

// const marker = new kakao.maps.Marker({
//   map: map,
//   position: new kakao.maps.LatLng(list.y, list.x),
//   image: markerOptios,
// });

// const infoWindow = new kakao.maps.InfoWindow({
//   position: new kakao.maps.LatLng(list.y, list.x),
//   content: `
//     <div>${list.address1}${list.address2}</div>
//   `,
// });

// kakao.maps.event.addListener(marker, 'mouseover', () =>
//   infoWindow.open(map, marker),
// );
// kakao.maps.event.addListener(marker, 'mouseout', () => {
//   infoWindow.close();
// });
