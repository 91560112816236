/**
 * @file RouteDepartureInfo.tsx
 * @author chad / chad@o2pluss.com
 * @description 경로 최적화 출발지 정보 컴포넌트
 */

import { useCallback, useState, ChangeEvent, useEffect } from 'react';
import { useUserInfo } from '@hooks/recoil/useUserInfo';

import { TiPlus } from 'react-icons/ti';
import Button from '@components/atom/Button';
import Card from '@components/atom/Card';
import DaumPostCode, {
  IGetAddressInfo,
} from '@components/organisms/modal/DaumPostCode';
import Input from '../molecules/Input';

type AddressBtn = 'business' | 'warehouse';

interface IProps {
  className: string;
}

export default function RouteDepartureInfo({ className }: IProps) {
  const BUSINESS = 'business';
  const WAREHOUSR = 'warehouse';

  const { userInfo, setUserInfo } = useUserInfo();

  const [isPostOpen, setIsPostOpen] = useState(false);
  // 출발지 주소 버튼 클릭
  const [clickAddress, setClickAddress] = useState<AddressBtn>(BUSINESS);
  // 주소 어디에 넣을지 분기처리
  const [addressBranch, setAddressBranch] = useState<AddressBtn | null>(null);
  // 물류창고 주소 유무
  const [isWarehouseAddress, setIsWarehouseAddress] = useState<boolean>(false);

  /** 사업장 주소 업데이트 */
  const updateAddress = useCallback(
    (address: IGetAddressInfo) => {
      addressBranch === BUSINESS
        ? setUserInfo({
            ...userInfo,
            businessAddress: address.roadAddress,
          })
        : setUserInfo({
            ...userInfo,
            warehouseAddress: address.roadAddress,
          });

      setAddressBranch(null);
    },
    [addressBranch, setUserInfo, userInfo],
  );

  /** 상세 주소 입력 */
  const onChangeDetailInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      clickAddress === BUSINESS
        ? setUserInfo({
            ...userInfo,
            businessAddressDetail: value,
          })
        : setUserInfo({
            ...userInfo,
            warehouseAddressDetail: value,
          });
    },
    [clickAddress, setUserInfo, userInfo],
  );

  /** 물류창고 주소 추가 버튼 클릭 */
  const onClickAddWarehouseBtn = useCallback(() => {
    setIsPostOpen(true);
    setAddressBranch(WAREHOUSR);
  }, []);

  /** 사업장 주소 input 클릭 */
  const onClickBusinessBtn = useCallback(() => {
    setIsPostOpen(true);
    setAddressBranch(BUSINESS);
  }, []);

  useEffect(() => {
    setIsWarehouseAddress(userInfo.warehouseAddress ? true : false);
  }, [userInfo.warehouseAddress]);

  return (
    <div className={className}>
      <Card>
        <p className="text-primary-01 font-bold">출발지 정보</p>
        <div className="my-4 flex items-center">
          <Button
            buttonClassName="text-white bg-primary-01 mr-2 h-[32px] text-sm"
            chip
            bold={false}
            text="사업장 주소"
            onClick={() => setClickAddress(BUSINESS)}
          />
          <Button
            buttonClassName={`${
              isWarehouseAddress
                ? 'text-white bg-primary-01'
                : 'text-primary-01 bg-white'
            } h-[32px] text-sm`}
            text={!isWarehouseAddress ? '물류창고 주소 추가' : '물류창고 주소'}
            icon={!isWarehouseAddress ? <TiPlus /> : false}
            chip
            bold={false}
            blackBorder
            onClick={() => {
              !isWarehouseAddress
                ? onClickAddWarehouseBtn()
                : setClickAddress(WAREHOUSR);
            }}
          />
        </div>
        <div className="flex">
          <div
            className="border-solid border-2 p-3 rounded-lg !w-[50%] mr-2 cursor-pointer"
            role="presentation"
            onClick={() => onClickBusinessBtn()}
          >
            {clickAddress === BUSINESS ? (
              <span>{userInfo.businessAddress || '주소가 없습니다.'}</span>
            ) : (
              <span>{userInfo.warehouseAddress || '주소가 없습니다.'}</span>
            )}
          </div>
          <Input
            className="!w-[50%]"
            placholder="상세주소"
            value={
              clickAddress === BUSINESS
                ? userInfo.businessAddressDetail
                : userInfo.warehouseAddressDetail
            }
            onChange={onChangeDetailInput}
          />
        </div>
      </Card>
      <DaumPostCode
        isOpen={isPostOpen}
        getPost={updateAddress}
        onCancel={() => setIsPostOpen(false)}
      />
    </div>
  );
}
