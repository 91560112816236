/**
 * @file Separator.tsx
 * @author chad / chad@o2pluss.com
 * @description 텍스트 섹션, 기타 구성 요소 등을 분리하는 데 사용되는 컴포넌트
 */

import classNames from 'classnames';

interface ISeparatorProps {
  className?: string;
}

export default function Separator({ className }: ISeparatorProps) {
  return <div className={classNames('border border-solid', className)} />;
}
