// import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetRoutePath from '@hooks/useGetRoutePath';
// import unknown from '@assets/images/unknown.png';

import SnbLinkItem from '@components/molecules/SnbLinkItem';
// import Image from '@components/atom/Image';

import { INavItem } from '@interfaces/sideNav.interface';
import { SNB_ROUTE_LIST } from '@constants/navigation';
import {
  useRouteOrder,
  useCompleteOptimization,
} from '@hooks/recoil/useRouteOrder';

import { CiUser } from 'react-icons/ci';

export default function SideNavigation() {
  const navigate = useNavigate();
  const routePath = useGetRoutePath();
  const { setOrderInfoList } = useRouteOrder();
  const { setIsCompleteOptimization } = useCompleteOptimization();

  const navItems: INavItem[] = [
    {
      code: SNB_ROUTE_LIST.ROUTE.CODE,
      label: SNB_ROUTE_LIST.ROUTE.LABEL,
      onClick: () =>
        navigate(SNB_ROUTE_LIST.ROUTE.CODE, {
          state: SNB_ROUTE_LIST.ROUTE.CODE,
        }),
    },
    {
      code: SNB_ROUTE_LIST.RECEIPT.CODE,
      label: SNB_ROUTE_LIST.RECEIPT.LABEL,
      onClick: () =>
        navigate(SNB_ROUTE_LIST.RECEIPT.CODE, {
          state: SNB_ROUTE_LIST.RECEIPT.CODE,
        }),
    },
    {
      code: SNB_ROUTE_LIST.TRANSPORT.CODE,
      label: SNB_ROUTE_LIST.TRANSPORT.LABEL,
      onClick: () =>
        navigate(SNB_ROUTE_LIST.TRANSPORT.CODE, {
          state: SNB_ROUTE_LIST.TRANSPORT.CODE,
        }),
    },
    {
      code: SNB_ROUTE_LIST.RIDER.CODE,
      label: SNB_ROUTE_LIST.RIDER.LABEL,
      onClick: () =>
        navigate(SNB_ROUTE_LIST.RIDER.CODE, {
          state: SNB_ROUTE_LIST.RIDER.CODE,
        }),
    },
    {
      code: SNB_ROUTE_LIST.VEHICLE.CODE,
      label: SNB_ROUTE_LIST.VEHICLE.LABEL,
      onClick: () =>
        navigate(SNB_ROUTE_LIST.VEHICLE.CODE, {
          state: SNB_ROUTE_LIST.VEHICLE.CODE,
        }),
    },
    // {
    //   code: SNB_ROUTE_LIST.ACCOUNT.CODE,
    //   label: SNB_ROUTE_LIST.ACCOUNT.LABEL,
    //   onClick: () =>
    //     navigate(SNB_ROUTE_LIST.ACCOUNT.CODE, {
    //       state: SNB_ROUTE_LIST.ACCOUNT.CODE,
    //     }),
    // },
  ];

  const onClickLogoutBtn = () => {
    setIsCompleteOptimization(false);
    setOrderInfoList([]);
    navigate('/');
  };

  return (
    <div className="min-w-[200px] bg-white overflow-auto flex flex-col justify-between">
      <div className="mb-4">
        <div className="text-center py-8">
          <span className="font-bold text-2xl">루트플로우</span>
        </div>
        <div>
          {navItems.map((item) => (
            <SnbLinkItem
              key={item.code}
              className={
                item.code === routePath[0]
                  ? 'border-solid border-l-4 border-primary-01 text-[#232323]'
                  : 'text-tertiary'
              }
              code={item.code}
              label={item.label}
              onClick={item.onClick}
            />
          ))}
        </div>
      </div>
      <div
        className="flex flex-col items-center mb-8 cursor-pointer"
        role="presentation"
        onClick={onClickLogoutBtn}
      >
        {/* <Image imgClassName="w-[48px]" src={unknown} /> */}
        <CiUser size={'48px'} color="#676767" />
        <span className="text-sm text-secondary-01 mt-2 font-bold">
          로그아웃
        </span>
      </div>
    </div>
  );
}
