/**
 * @file: HistoryRouteDetail.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.22 ~
 * @description: 접수 내역 상세 팝업
 */

import { IRouteExcelData } from '@data/routeExcelUploadData';

import Button from '@components/atom/Button';
import TotalCount from '@components/atom/TotalCount';
import ModalTemplate from '@components/templates/ModalTemplate';
import HitoryDetailTableHeader from '@components/molecules/tableHeader/HitoryDetailTableHeader';
import HistoryDetailTableList from '@components/molecules/tableList/HistoryDetailTableList';
import Card from '@components/atom/Card';
import Separator from '@components/atom/Separator';

interface IDetailItem {
  route_name: string | undefined;
  list_item: IRouteExcelData[] | undefined;
}

interface IProps {
  isOpen: boolean;
  detailItem: IDetailItem;
  closeModal: () => void;
}

export default function HistoryRouteDetail({
  isOpen,
  detailItem,
  closeModal,
}: IProps) {
  return (
    <ModalTemplate isOpen={isOpen} isFullScreen>
      <div className="flex justify-between items-center mb-8">
        <h6 className="text-2xl font-bold">루트플로우</h6>
        <Button
          text="닫기"
          buttonClassName="bg-primary-01 text-white w-[80px] h-[48px]"
          onClick={closeModal}
        />
      </div>
      <p className="text-md font-bold mb-4">{detailItem.route_name}</p>

      <TotalCount className="mb-4" total={detailItem.list_item?.length} />

      <div
        className="overflow-x-scroll"
        style={{ height: 'calc(100% - 160px)' }}
      >
        <HitoryDetailTableHeader />
        <Card cardClassName="overflow-y-scroll w-max !px-3 !py-0">
          {detailItem.list_item?.map((list, index) => (
            <div key={index}>
              <HistoryDetailTableList
                index={String(index + 1).padStart(2, '0')}
                list={list}
              />
              {detailItem.list_item &&
                index + 1 < detailItem.list_item?.length && <Separator />}
            </div>
          ))}
        </Card>
      </div>
    </ModalTemplate>
  );
}
