/**
 * @file: TransportStatusCardList.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.25 ~
 * @description:
 */

interface IProps {
  index: string | number;
  data: IRouteData;
  totalCount: number | undefined;
}

import { IRouteData } from '@data/routeExcelUploadData';
import { DELIVERY_STATUS } from '@constants/common';

export default function TransportStatusCardList({
  index,
  data,
  totalCount,
}: IProps) {
  return (
    <div className="flex mb-3">
      <div className="relative mr-2">
        {totalCount !== index && (
          <div className="absolute border-l border-primary-01 border-dashed h-3 top-6 left-[47%]" />
        )}
        <div
          className={`flex items-center justify-center h-[24px] w-[24px] rounded-xl
          ${
            data.status === DELIVERY_STATUS.DELIVERY
              ? 'bg-primary-01 text-white'
              : data.status === DELIVERY_STATUS.COMPLETE
              ? 'bg-primary-02 text-white'
              : 'bg-white text-primary-01 border-solid border border-primary-01'
          }`}
        >
          <span className="text-xs">{index}</span>
        </div>
      </div>
      <span>{data.recieve_address_1}</span>
    </div>
  );
}
