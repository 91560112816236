/**
 * @file useRouteOrder.tsx
 * @author chad / chad@o2pluss.com
 * @description
 */

import { IRouteExcelData } from '@data/routeExcelUploadData';
import { useRecoilState, RecoilEnv } from 'recoil';
import {
  routeOrderListAtom,
  routeOrderGroupAtom,
  routeSelectedListAtom,
  routeAllSelectedAtom,
  readyToCreateRoute,
  completeOptimization,
} from '@recoil/routeOrderList';

/**
 * 엑셀로 업로드한 경로 최적화 리스트
 */
export const useRouteOrder = () => {
  const [orderInfoList, setOrderInfoList] =
    useRecoilState<IRouteExcelData[]>(routeOrderListAtom);

  return {
    orderInfoList,
    setOrderInfoList,
  };
};

/**
 * 엑셀 업로드한 리스트 그릅화
 */
export const useRouteGroup = () => {
  const [orderGroupList, setOrderGroupList] =
    useRecoilState<any>(routeOrderGroupAtom);

  return {
    orderGroupList,
    setOrderGroupList,
  };
};

/** 선택된 동 리스트 */
export const useRouteSelected = () => {
  const [selectedList, setSelectedList] = useRecoilState<string[]>(
    routeSelectedListAtom,
  );

  return {
    selectedList,
    setSelectedList,
  };
};

/** 동 전체 선택 */
export const useRouteAllSelected = () => {
  const [isAllSelected, setIsAllSelected] =
    useRecoilState<boolean>(routeAllSelectedAtom);

  return {
    isAllSelected,
    setIsAllSelected,
  };
};

/** 경로 최적화 준비 (경로 최적화 버튼 클릭 시) */
export const useReadyToRouteCreate = () => {
  const [isReadyToRouteCreate, setIsReadyToRouteCreate] =
    useRecoilState<boolean>(readyToCreateRoute);

  return {
    isReadyToRouteCreate,
    setIsReadyToRouteCreate,
  };
};

/** 접수 완료 state */
export const useCompleteOptimization = () => {
  const [isCompleteOptimization, setIsCompleteOptimization] =
    useRecoilState<boolean>(completeOptimization);

  return {
    isCompleteOptimization,
    setIsCompleteOptimization,
  };
};

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
