import { useEffect, useState } from 'react';
import { DELIVERY_STATUS } from '@constants/common';
import { riderInfoData } from '@data/riderData';
import { IRiderVehicleInfo } from '@interfaces/info.interface';

import Button from '@components/atom/Button';
import { FaPlus } from 'react-icons/fa6';
import ManagementCard from '@components/molecules/ManagementCard';

type Status = Omit<DELIVERY_STATUS, '운송 완료'>;

export default function RiderMgt() {
  const [selectRiderStatus, setSeletRiderStatus] = useState<Status>(
    DELIVERY_STATUS.ALL,
  );
  const [useRiderData, setUseRiderData] =
    useState<IRiderVehicleInfo[]>(riderInfoData);

  const statusList: string[] = [];

  for (const value of Object.values(DELIVERY_STATUS)) {
    value !== DELIVERY_STATUS.COMPLETE && statusList.push(value);
  }

  /** 기사 관리 필터 */
  const filteredRiderInfo = () => {
    const filtered = riderInfoData.filter(
      (data) =>
        data.status === selectRiderStatus ||
        selectRiderStatus === DELIVERY_STATUS.ALL,
    );

    setUseRiderData(filtered);
  };

  useEffect(() => {
    filteredRiderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRiderStatus]);

  return (
    <div className="w-[45%]">
      <div className="text-right mb-6">
        <Button
          buttonClassName="bg-primary-01 text-white mr-3 h-[48px]"
          text="기사 등록"
          icon={<FaPlus className="mr-1" />}
        />
      </div>

      <div className="mb-6">
        {statusList.map((list, index) => (
          <Button
            key={index}
            buttonClassName="h-[32px] mr-1"
            text={list}
            chip
            grayBorder
            blackBorder={selectRiderStatus === list ? true : false}
            smallPadding
            bold={false}
            onClick={() => {
              setSeletRiderStatus(list);
            }}
          />
        ))}
      </div>

      <div className="grid grid-cols-2 grid-flow-row gap-4">
        {useRiderData.map((data, index) => (
          <ManagementCard
            key={index}
            name={data.name}
            status={data.status}
            number={data.number}
            memo={data.memo}
            isRider
          />
        ))}
      </div>
    </div>
  );
}
