export const ROUTE_TABLE_HEADER = {
  NO: 'No',
  ORDER_NUM: '주문번호',
  ORDER_NAME: '주문자명',
  ORDER_CONTACT: '주문자 연락처',
  RECIEVER: '수취인',
  RECIEVER_CONTACT: '수취인 연락처',
  ADD_CONTACT: '추가 연락처',
  RECEIVE_ADDRESS_1: '수령지 주소1 (기본주소)',
  RECEIVE_ADDRESS_2: '수령지 주소2 (상세주소)',
  DELIVERY_REQ: '배송 시 요청사항',
  ARTICLE: '물품',
  QUANTITY: '수량',
} as const;

export type SnbListType =
  (typeof ROUTE_TABLE_HEADER)[keyof typeof ROUTE_TABLE_HEADER];

export const CREATE_ROUTE_TABLE_HEADER = {
  NO: '',
  TRANSFORT_AREA: '운송 지역',
  ORDER_NUM: '주문 수 (건)',
  ARTICLE_NUM: '물품 수 (개)',
} as const;

export const CREATE_ROUTE_INFO_TABLE_HEADER = {
  NO: 'No',
  ROUTE_NAME: '경로 명',
  RIDER_IN_CHARGE: '담당기사',
  VEHICLE_NUM: '차량번호',
} as const;
