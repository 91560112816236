/**
 * @file CreateRouteList.tsx
 * @author chad / chad@o2pluss.com
 * @description 경로 생성 리스트 컴포넌트
 */

import RouteAccordion from './RouteAccordion';

interface IProps {
  index: number;
  list: any;
}

export default function CreateRouteList({ index, list }: IProps) {
  return (
    <div className="relative flex flex-col">
      <div
        className={`flex items-center bg-[#262e69] text-white px-3 py-1 font-bold text-xs h-[36px] ${
          index === 0 ? 'rounded-t-lg' : ''
        }`}
      >
        <span className="w-[20px] mr-1" />
        <span className="w-[15%] px-2">{''}</span>
        <span className="w-[45%] px-2">{list.sidoName}</span>
        <span className="w-[20%] px-2">{list.orderCount}</span>
        <span className="w-[20%] px-2">{list.quantityCount}</span>
      </div>

      {list.sigungu.map((sigungu: any, index: number) => (
        <RouteAccordion key={index} sigungu={sigungu} />
      ))}
    </div>
  );
}
