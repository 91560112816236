/**
 * @file ContentHeader
 * @author chad / chad@o2pluss.com
 * @description 페이지별 헤드 컴포넌트
 */

import { useEffect, useState } from 'react';
import { SNB_ROUTE_LIST } from '@constants/navigation';
import { useLocation } from 'react-router-dom';

export default function ContentHeader() {
  const { state } = useLocation();

  const [title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<string>('');

  useEffect(() => {
    switch (state) {
      case SNB_ROUTE_LIST.ROUTE.CODE:
        setTitle(SNB_ROUTE_LIST.ROUTE.LABEL);
        setSubTitle('정보가 제대로 입력 되었는지 확인해주세요.');
        break;
      case 'routeCreate':
        setTitle(SNB_ROUTE_LIST.ROUTE.LABEL);
        setSubTitle('경로를 생성할 지역을 선택해주세요.');
        break;
      case SNB_ROUTE_LIST.RECEIPT.CODE:
        setTitle(SNB_ROUTE_LIST.RECEIPT.LABEL);
        setSubTitle('');
        break;
      case SNB_ROUTE_LIST.TRANSPORT.CODE:
        setTitle(SNB_ROUTE_LIST.TRANSPORT.LABEL);
        setSubTitle('');
        break;
      case SNB_ROUTE_LIST.RIDER.CODE:
        setTitle(SNB_ROUTE_LIST.RIDER.LABEL);
        setSubTitle('');
        break;
      case SNB_ROUTE_LIST.VEHICLE.CODE:
        setTitle(SNB_ROUTE_LIST.VEHICLE.LABEL);
        setSubTitle('');
        break;
      case SNB_ROUTE_LIST.ACCOUNT.CODE:
        setTitle(SNB_ROUTE_LIST.ACCOUNT.LABEL);
        setSubTitle('');
        break;
      default:
        setTitle('');
        setSubTitle('');
    }
  }, [state]);

  return (
    <div className="flex flex-col mb-8">
      <span className="font-bold text-2xl">{title}</span>
      {subTitle && <span className="pt-2 text-secondary-01">{subTitle}</span>}
    </div>
  );
}
