/**
 * @file Modal.tsx
 * @author chad / chad@o2pluss.com
 * @description
 */

import { useModal } from '@hooks/useModal';

import ModalTemplate from '@components/templates/ModalTemplate';
import Button from '@components/atom/Button';

export interface IModalProps {
  type?: 'alert' | 'confirm';
  title?: string;
  message: string | React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
}

export default function Modal({
  type = 'alert',
  title,
  message,
  onOk,
  onCancel,
}: IModalProps) {
  const { hideModal } = useModal();

  /** ok 버튼 */
  const onClickOk = async () => {
    if (onOk) {
      const okPromise = async () => onOk();
      await okPromise();
    }
    hideModal();
  };

  /** 취소 버튼 */
  const onClickCancel = async () => {
    if (onCancel) {
      const cancelPromise = async () => onCancel();
      await cancelPromise();
    }
    hideModal();
  };

  return (
    <ModalTemplate isOpen className="w-[500px]">
      <div>
        <p className="text-xl font-bold mb-2">{title}</p>
        <span className="text-secondary-01">{message}</span>
      </div>
      <div className="text-right mt-6">
        {type === 'confirm' && (
          <Button
            buttonClassName="h-[36px] text-sm mr-2"
            text="취소"
            smallPadding
            blackBorder
            onClick={onClickCancel}
          />
        )}
        <Button
          buttonClassName="bg-primary-01 text-white h-[36px] text-sm"
          text="확인"
          smallPadding
          onClick={onClickOk}
        />
      </div>
    </ModalTemplate>
  );
}
