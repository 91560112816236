import { Dispatch, SetStateAction } from 'react';

export interface IRouteData {
  id?: number;
  order_num: string;
  order_name: string;
  order_contact: string;
  reciever: string;
  reciever_contact: string;
  add_contact?: string;
  sido?: string | undefined;
  sigungu?: string | undefined;
  dong?: string | undefined;
  recieve_address_1: string;
  recieve_address_2: string;
  x?: string | number;
  y?: string | number;
  delivery_req: string;
  article: string;
  quantity: number;
  isDelivery?: boolean;
  imageUrl?: string | ArrayBuffer | null;
  status?: string;
  completed_at?: string | null;
}

export interface IRouteExcelData extends IRouteData {
  [key: string]:
    | number
    | string
    | undefined
    | boolean
    | Dispatch<SetStateAction<boolean>>
    | ArrayBuffer
    | null;
}

export const routeExcelData: IRouteExcelData[] = [
  {
    id: 1,
    order_num: '20231201-01',
    order_name: '유미연',
    order_contact: '010-3232-3332',
    reciever: '홍재성',
    reciever_contact: '010-3390-4447',
    add_contact: '010-6142-3094',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '외도1동',
    recieve_address_1: '제주특별자치도 제주시 우정로 60',
    recieve_address_2: '지하 3층 입구',
    jibun: '제주특별자치도 제주시 외도1동 541-13',
    x: 126.4306693,
    y: 33.48750436,
    delivery_req: '로비에 맡겨주세요.',
    article: '핸드폰',
    quantity: 6,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 2,
    order_num: '20231201-02',
    order_name: '예민우',
    order_contact: '010-2462-5846',
    reciever: '김찬우',
    reciever_contact: '010-3863-7133',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '외도1동',
    recieve_address_1: '제주특별자치도 제주시 우정로15길 5',
    recieve_address_2: 'A-3동 2층',
    jibun: '제주특별자치도 제주시 외도1동 639-3',
    x: 126.4317287,
    y: 33.48609192,
    delivery_req: '오시기 전에 연락 주세요.',
    article: '노트북',
    quantity: 13,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 3,
    order_num: '20231201-03',
    order_name: '표명숙',
    order_contact: '010-8638-0575',
    reciever: '서영숙',
    reciever_contact: '010-6125-3249',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '외도1동',
    recieve_address_1: '제주특별자치도 제주시 우정로11길 22',
    recieve_address_2: '외도부영1차 104동 1011호',
    jibun: '제주특별자치도 제주시 외도1동 560-1',
    x: 126.433703,
    y: 33.48644204,
    delivery_req: '',
    article: '가방',
    quantity: 3,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 4,
    order_num: '20231201-04',
    order_name: '안기혁',
    order_contact: '010-2583-3205',
    reciever: '임윤희',
    reciever_contact: '010-9376-8598',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '이호2동',
    recieve_address_1: '제주특별자치도 제주시 오도11길 19-1',
    recieve_address_2: '1층',
    jibun: '제주특별자치도 제주시 이호2동 1095',
    x: 126.4599767,
    y: 33.4899977,
    delivery_req: '없어요',
    article: '책상',
    quantity: 7,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 5,
    order_num: '20231201-05',
    order_name: '권영숙',
    order_contact: '010-6052-9092',
    reciever: '안기혁',
    reciever_contact: '010-7243-1286',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '이호2동',
    recieve_address_1: '제주특별자치도 제주시 대동길 17-1',
    recieve_address_2: '402호',
    jibun: '제주특별자치도 제주시 이호이동 116-3',
    x: 126.4635886,
    y: 33.4933673,
    delivery_req: '',
    article: '과일',
    quantity: 4,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 6,
    order_num: '20231201-07',
    order_name: '황광호',
    order_contact: '010-6843-1582',
    reciever: '예은용',
    reciever_contact: '010-7576-6338',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '도두1동',
    recieve_address_1: '제주특별자치도 제주시 도공로 2',
    recieve_address_2: '3차 503호',
    jibun: '제주특별자치도 제주시 도두일동 2614-5',
    x: 126.4649822,
    y: 33.50612901,
    delivery_req: '',
    article: '옷',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 7,
    order_num: '20231201-06',
    order_name: '홍현식',
    order_contact: '010-7576-6338',
    reciever: '류보영',
    reciever_contact: '010-5488-9194',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '이호2동',
    recieve_address_1: '제주특별자치도 제주시 월랑로 91',
    recieve_address_2: '나동 302호',
    jibun: '제주특별자치도 제주시 이호이동 22',
    x: 126.4705035,
    y: 33.49228629,
    delivery_req: '',
    article: '가전제품',
    quantity: 6,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 8,
    order_num: '20231201-08',
    order_name: '설대영',
    order_contact: '010-5071-7664',
    reciever: '최우준',
    reciever_contact: '010-6843-1582',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '도두1동',
    recieve_address_1: '제주특별자치도 제주시 일주서로 7714',
    recieve_address_2: '5층',
    jibun: '제주특별자치도 제주시 도두일동 2288',
    x: 126.4706157,
    y: 33.49347795,
    delivery_req: '연락주시면 1층으로 나옵니다',
    article: '애견용품',
    quantity: 10,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 9,
    order_num: '20231201-09',
    order_name: '장수민',
    order_contact: '010-7636-1857',
    reciever: '김현수',
    reciever_contact: '010-2529-7634',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '도두2동',
    recieve_address_1: '제주특별자치도 제주시 오일장중길 128',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 도두이동 1106-1',
    x: 126.4800192,
    y: 33.4997582,
    delivery_req: '',
    article: '음향기기',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 10,
    order_num: '20231201-16',
    order_name: '풍연석',
    order_contact: '010-4638-1516',
    reciever: '전서우',
    reciever_contact: '010-5933-7363',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '용담2동',
    recieve_address_1: '제주특별자치도 제주시 공항로 2',
    recieve_address_2: '1층 1번게이트',
    jibun: '제주특별자치도 제주시 용담2동 2002',
    x: 126.4938576,
    y: 33.50579497,
    delivery_req: '',
    article: '가방',
    quantity: 15,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 11,
    order_num: '20231201-13',
    order_name: '홍재성',
    order_contact: '010-5957-3508',
    reciever: '김진영',
    reciever_contact: '010-8804-0081',
    add_contact: '010-3548-0857',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '용담2동',
    recieve_address_1: '제주특별자치도 제주시 용해로 92',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 용담2동 855',
    x: 126.5014587,
    y: 33.50901749,
    delivery_req: '',
    article: '안경',
    quantity: 5,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 12,
    order_num: '20231201-15',
    order_name: '설진미',
    order_contact: '010-4206-6902',
    reciever: '노만옥',
    reciever_contact: '010-2513-5297',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '용담2동',
    recieve_address_1: '제주특별자치도 제주시 용문로 157',
    recieve_address_2: '641동 1호 반석텔레콤',
    jibun: '제주특별자치도 제주시 용담2동 641-1',
    x: 126.509957,
    y: 33.50890083,
    delivery_req: '',
    article: '노트북',
    quantity: 7,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 13,
    order_num: '20231201-14',
    order_name: '손하현',
    order_contact: '010-2261-6922',
    reciever: '손원철',
    reciever_contact: '010-7326-9997',
    add_contact: '010-3956-1701',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '용담2동',
    recieve_address_1: '제주특별자치도 제주시 용담로 90',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 용담2동 359-58',
    x: 126.5121006,
    y: 33.51202158,
    delivery_req: '',
    article: '핸드폰',
    quantity: 10,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 14,
    order_num: '20231201-10',
    order_name: '김성환',
    order_contact: '010-7958-7628',
    reciever: '유미연',
    reciever_contact: '010-6270-3794',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '용담1동',
    recieve_address_1: '제주특별자치도 제주시 서문로 29',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 용담1동 301-5',
    x: 126.5145717,
    y: 33.51056634,
    delivery_req: '3층 유미연 고객님',
    article: '태블릿',
    quantity: 7,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 15,
    order_num: '20231201-12',
    order_name: '권윤미',
    order_contact: '010-8151-1689',
    reciever: '문영은',
    reciever_contact: '010-8638-0575',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '용담1동',
    recieve_address_1: '제주특별자치도 제주시 서문로 57',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 용담1동 129-6',
    x: 126.5172774,
    y: 33.51167761,
    delivery_req: '',
    article: '신발',
    quantity: 14,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 16,
    order_num: '20231201-21',
    order_name: '윤효원',
    order_contact: '010-5178-9746',
    reciever: '손영훈',
    reciever_contact: '010-2462-5846',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '삼도2동',
    recieve_address_1: '제주특별자치도 제주시 무근성5길 18',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 삼도2동 1139-1',
    x: 126.5195577,
    y: 33.51426863,
    delivery_req: '',
    article: '애견용품',
    quantity: 4,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 17,
    order_num: '20231201-22',
    order_name: '문영은',
    order_contact: '010-1431-7237',
    reciever: '한창민',
    reciever_contact: '010-4111-3755',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '이도1동',
    recieve_address_1: '제주특별자치도 제주시 중앙로 70',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 이도1동 1369-1',
    x: 126.5244737,
    y: 33.51202281,
    delivery_req: '',
    article: '음향기기',
    quantity: 12,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 18,
    order_num: '20231201-11',
    order_name: '배종식',
    order_contact: '010-6535-1533',
    reciever: '안원기',
    reciever_contact: '010-3828-6343',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '용담1동',
    recieve_address_1: '제주특별자치도 제주시 남성로4길 1',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 용담1동 2818-9',
    x: 126.5132579,
    y: 33.50217715,
    delivery_req: '',
    article: '서류',
    quantity: 1,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 19,
    order_num: '20231201-18',
    order_name: '유태훈',
    order_contact: '010-4824-0445',
    reciever: '신윤재',
    reciever_contact: '010-2583-3205',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '삼도1동',
    recieve_address_1: '제주특별자치도 제주시 서사로 96',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 삼도1동 530-23',
    x: 126.5196457,
    y: 33.50387836,
    delivery_req: '',
    article: '과일',
    quantity: 3,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 20,
    order_num: '20231201-20',
    order_name: '표태용',
    order_contact: '010-8469-4292',
    reciever: '유승환',
    reciever_contact: '010-7816-7524',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '삼도2동',
    recieve_address_1: '제주특별자치도 제주시 중앙로26길 22',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 삼도2동 1239-10',
    x: 126.5233215,
    y: 33.50553709,
    delivery_req: '',
    article: '옷',
    quantity: 13,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 21,
    order_num: '20231201-17',
    order_name: '홍은정',
    order_contact: '010-9353-9339',
    reciever: '복준하',
    reciever_contact: '010-4206-6902',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '삼도1동',
    recieve_address_1: '제주특별자치도 제주시 서광로 229',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 삼도1동 552-8',
    x: 126.5209751,
    y: 33.5000362,
    delivery_req: '',
    article: '책상',
    quantity: 10,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 22,
    order_num: '20231201-19',
    order_name: '유윤준',
    order_contact: '010-1485-0190',
    reciever: '정민석',
    reciever_contact: '010-9598-4959',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '삼도1동',
    recieve_address_1: '제주특별자치도 제주시 서광로 237-1',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 삼도1동 552-24',
    x: 126.5218884,
    y: 33.50000302,
    delivery_req: '',
    article: '가전제품',
    quantity: 1,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 23,
    order_num: '20231201-23',
    order_name: '권진우',
    order_contact: '010-7967-2146',
    reciever: '하철민',
    reciever_contact: '010-4024-1221',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '이도2동',
    recieve_address_1: '제주특별자치도 제주시 중앙로 279',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 이도2동 1057-3',
    x: 126.5332468,
    y: 33.49546277,
    delivery_req: '',
    article: '태블릿',
    quantity: 13,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 24,
    order_num: '20231201-24',
    order_name: '임정숙',
    order_contact: '010-1245-4380',
    reciever: '오형수',
    reciever_contact: '010-4074-7833',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '화북1동',
    recieve_address_1: '제주특별자치도 제주시 일주동로 254',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 화북1동 1230',
    x: 126.5723692,
    y: 33.51988238,
    delivery_req: '',
    article: '서류',
    quantity: 7,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 25,
    order_num: '20231201-25',
    order_name: '고희수',
    order_contact: '010-1352-7413',
    reciever: '조동일',
    reciever_contact: '010-9210-8551',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '삼양동',
    recieve_address_1: '제주특별자치도 제주시 화삼북로 90',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 삼양동 1909',
    x: 126.582654,
    y: 33.51655645,
    delivery_req: '',
    article: '신발',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 26,
    order_num: '20231201-26',
    order_name: '조규원',
    order_contact: '010-3405-9385',
    reciever: '김미숙',
    reciever_contact: '010-1448-5435',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '아라1동',
    recieve_address_1: '제주특별자치도 제주시 제주대학로 102',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 아라1동 1',
    x: 126.560822,
    y: 33.45265161,
    delivery_req: '',
    article: '안경',
    quantity: 4,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 27,
    order_num: '20231201-27',
    order_name: '노만옥',
    order_contact: '010-3548-0857',
    reciever: '조하영',
    reciever_contact: '010-1851-7664',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '아라2동',
    recieve_address_1: '제주특별자치도 제주시 신설로11길 2-10',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 아라2동 3009-5',
    x: 126.5443405,
    y: 33.49098938,
    delivery_req: '',
    article: '핸드폰',
    quantity: 3,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 28,
    order_num: '20231201-28',
    order_name: '허남혁',
    order_contact: '010-1914-8082',
    reciever: '유연웅',
    reciever_contact: '010-4638-1516',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '오라3동',
    recieve_address_1: '제주특별자치도 제주시 연삼로 120',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 오라3동 2811-3',
    x: 126.5051068,
    y: 33.49285768,
    delivery_req: '',
    article: '노트북',
    quantity: 15,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 29,
    order_num: '20231201-29',
    order_name: '이용욱',
    order_contact: '010-3932-8936',
    reciever: '허태욱',
    reciever_contact: '010-2743-5775',
    add_contact: '010-1448-5435',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '노형동',
    recieve_address_1: '제주특별자치도 제주시 노형로 375',
    recieve_address_2: '베테랑모바일',
    jibun: '제주특별자치도 제주시 노형동 2536-11',
    x: 126.4757322,
    y: 33.48380372,
    delivery_req: '',
    article: '가방',
    quantity: 13,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 30,
    order_num: '20231201-35',
    order_name: '심영훈',
    order_contact: '010-8804-0081',
    reciever: '송지우',
    reciever_contact: '010-7636-1857',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '애월읍',
    recieve_address_1: '제주특별자치도 제주시 애월읍 하귀로 56',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 애월읍 하귀2리 1879-2',
    x: 126.4033069,
    y: 33.48028194,
    delivery_req: '',
    article: '음향기기',
    quantity: 12,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 31,
    order_num: '20231201-31',
    order_name: '강창진',
    order_contact: '010-9376-8598',
    reciever: '오경희',
    reciever_contact: '010-7738-8923',
    add_contact: '010-9910-1309',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '애월읍',
    recieve_address_1: '제주특별자치도 제주시 애월읍 고성서3길 15-5',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 애월읍 고성리 1488-1',
    x: 126.4107586,
    y: 33.46598232,
    delivery_req: '',
    article: '과일',
    quantity: 3,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 32,
    order_num: '20231201-32',
    order_name: '전서우',
    order_contact: '010-3956-1701',
    reciever: '허기현',
    reciever_contact: '010-3405-9385',
    add_contact: '010-7426-8590',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '애월읍',
    recieve_address_1: '제주특별자치도 제주시 애월읍 애조로 405',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 애월읍 광령리 3672-1',
    x: 126.4264867,
    y: 33.47320957,
    delivery_req: '',
    article: '가전제품',
    quantity: 12,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 33,
    order_num: '20231201-34',
    order_name: '김태주',
    order_contact: '010-5685-3516',
    reciever: '송윤미',
    reciever_contact: '010-4902-4995',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '애월읍',
    recieve_address_1: '제주특별자치도 제주시 애월읍 유수암평화길 14-3',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 애월읍 유수암리 1894',
    x: 126.4027879,
    y: 33.44113598,
    delivery_req: '',
    article: '애견용품',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 34,
    order_num: '20231201-33',
    order_name: '하철민',
    order_contact: '010-6273-1340',
    reciever: '설대영',
    reciever_contact: '010-4424-8664',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '애월읍',
    recieve_address_1: '제주특별자치도 제주시 애월읍 소길리 154',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 애월읍 소길리 154',
    x: 126.3895793,
    y: 33.41937209,
    delivery_req: '',
    article: '옷',
    quantity: 9,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 35,
    order_num: '20231201-30',
    order_name: '박은미',
    order_contact: '010-9534-3263',
    reciever: '김태주',
    reciever_contact: '010-8917-5951',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '애월읍',
    recieve_address_1: '제주특별자치도 제주시 애월읍 신엄안3길 99',
    recieve_address_2: '애월차이나',
    jibun: '제주특별자치도 제주시 애월읍 고내리 11-6',
    x: 126.357377,
    y: 33.47229128,
    delivery_req: '빠른배송부탁드립니다',
    article: '책상',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 36,
    order_num: '20231201-36',
    order_name: '유승환',
    order_contact: '010-6142-3094',
    reciever: '손하현',
    reciever_contact: '010-3195-2873',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '한림읍',
    recieve_address_1: '제주특별자치도 제주시 한림읍 귀덕14길 14-5',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 한림읍 귀덕리 1157',
    x: 126.2934545,
    y: 33.44241942,
    delivery_req: '',
    article: '태블릿',
    quantity: 5,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 37,
    order_num: '20231201-37',
    order_name: '예은용',
    order_contact: '010-7738-8923',
    reciever: '고희수',
    reciever_contact: '010-1352-7413',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '한림읍',
    recieve_address_1: '제주특별자치도 제주시 한림읍 한수풀로 146',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 한림읍 대림리 431',
    x: 126.2769396,
    y: 33.42151129,
    delivery_req: '',
    article: '서류',
    quantity: 12,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 38,
    order_num: '20231201-38',
    order_name: '한창민',
    order_contact: '010-3110-4747',
    reciever: '안석주',
    reciever_contact: '010-8395-2988',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '한림읍',
    recieve_address_1: '제주특별자치도 제주시 한림읍 강구로 1',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 한림읍 한림리 968',
    x: 126.2690672,
    y: 33.4120918,
    delivery_req: '',
    article: '신발',
    quantity: 12,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 39,
    order_num: '20231201-39',
    order_name: '성동훈',
    order_contact: '010-1396-1467',
    reciever: '이용욱',
    reciever_contact: '010-7630-6133',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '한림읍',
    recieve_address_1: '제주특별자치도 제주시 한림읍 한림로 329-10',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 한림읍 협재리 2447',
    x: 126.2391566,
    y: 33.39386651,
    delivery_req: '',
    article: '안경',
    quantity: 14,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 40,
    order_num: '20231201-41',
    order_name: '강해빈',
    order_contact: '010-7243-1286',
    reciever: '권윤미',
    reciever_contact: '010-7958-7628',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '한경면',
    recieve_address_1: '제주특별자치도 제주시 한경면 신한로 76',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 한경면 신창리 18-2',
    x: 126.190287,
    y: 33.34664181,
    delivery_req: '',
    article: '노트북',
    quantity: 4,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 41,
    order_num: '20231201-40',
    order_name: '안원기',
    order_contact: '010-2041-4482',
    reciever: '복영수',
    reciever_contact: '010-7426-8590',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '한경면',
    recieve_address_1: '제주특별자치도 제주시 한경면 신한로 176-6',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 한경면 두모리 1830-2',
    x: 126.194196,
    y: 33.33941204,
    delivery_req: '',
    article: '핸드폰',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 42,
    order_num: '20231201-43',
    order_name: '유원진',
    order_contact: '010-6270-3794',
    reciever: '심영훈',
    reciever_contact: '010-5071-7664',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '대정읍',
    recieve_address_1: '제주특별자치도 서귀포시 대정읍 에듀시티로 148',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 대정읍 보성리 2424',
    x: 126.2752703,
    y: 33.2846186,
    delivery_req: '',
    article: '책상',
    quantity: 7,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 43,
    order_num: '20231201-50',
    order_name: '정석환',
    order_contact: '010-7759-5453',
    reciever: '홍은정',
    reciever_contact: '010-1372-6873',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '안덕면',
    recieve_address_1: '제주특별자치도 서귀포시 안덕면 녹차분재로 81',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 안덕면 서광리 1395',
    x: 126.2986303,
    y: 33.28978228,
    delivery_req: '',
    article: '서류',
    quantity: 15,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 44,
    order_num: '20231201-44',
    order_name: '김현수',
    order_contact: '010-8627-1744',
    reciever: '심성원',
    reciever_contact: '010-6142-3094',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '대정읍',
    recieve_address_1: '제주특별자치도 서귀포시 대정읍 글로벌에듀로 382',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 대정읍 보성리 2476-13',
    x: 126.2771664,
    y: 33.28016132,
    delivery_req: '',
    article: '과일',
    quantity: 3,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 45,
    order_num: '20231201-45',
    order_name: '박태지',
    order_contact: '010-1563-8275',
    reciever: '민규원',
    reciever_contact: '010-8343-3499',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '대정읍',
    recieve_address_1: '제주특별자치도 서귀포시 대정읍 신영로 120',
    recieve_address_2: '1층',
    jibun: '제주특별자치도 서귀포시 대정읍 하모리 820-15',
    x: 126.25462,
    y: 33.22247165,
    delivery_req: '고가제품입니다 배송에 유의해주세요.',
    article: '가전제품',
    quantity: 5,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 46,
    order_num: '20231201-42',
    order_name: '권광현',
    order_contact: '010-6285-2405',
    reciever: '임유정',
    reciever_contact: '010-4453-5070',
    add_contact: '010-6886-4339',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '대정읍',
    recieve_address_1: '제주특별자치도 서귀포시 대정읍 하모항구로10번길 23',
    recieve_address_2: '103호',
    jibun: '제주특별자치도 서귀포시 대정읍 하모리 2128',
    x: 126.2510504,
    y: 33.21829891,
    delivery_req: '긴급배송입니다.',
    article: '가방',
    quantity: 9,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 47, // 17
    order_num: '20231201-47',
    order_name: '손지희',
    order_contact: '010-9002-2357',
    reciever: '최도윤',
    reciever_contact: '010-5944-1765',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '안덕면',
    recieve_address_1: '제주특별자치도 서귀포시 안덕면 사계남로 89',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 안덕면 사계리 2172-1',
    x: 126.2993738,
    y: 33.22749046,
    delivery_req: '',
    article: '애견용품',
    quantity: 15,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 48,
    order_num: '20231201-51',
    order_name: '조하영',
    order_contact: '010-4111-3755',
    reciever: '황광호',
    reciever_contact: '010-3070-5803',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '안덕면',
    recieve_address_1: '제주특별자치도 서귀포시 안덕면 화순로 105',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 안덕면 화순리 1075-3',
    x: 126.3351455,
    y: 33.24649634,
    delivery_req: '',
    article: '신발',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 49,
    order_num: '20231201-46',
    order_name: '복영수',
    order_contact: '010-6744-9885',
    reciever: '강만석',
    reciever_contact: '010-6001-6176',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '안덕면',
    recieve_address_1: '제주특별자치도 서귀포시 안덕면 감산중로 5',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 안덕면 감산리 391-1',
    x: 126.3521162,
    y: 33.26016804,
    delivery_req: '',
    article: '옷',
    quantity: 15,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 50,
    order_num: '20231201-48',
    order_name: '추인혁',
    order_contact: '010-6535-5095',
    reciever: '설수근',
    reciever_contact: '010-5685-3516',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '안덕면',
    recieve_address_1:
      '제주특별자치도 서귀포시 안덕면 중산간서로1615번길 258-5',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 안덕면 상창리 2466-2',
    x: 126.3492974,
    y: 33.26276693,
    delivery_req: '',
    article: '음향기기',
    quantity: 6,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 51,
    order_num: '20231201-49',
    order_name: '유연웅',
    order_contact: '010-7426-8590',
    reciever: '박태지',
    reciever_contact: '010-8151-1689',
    add_contact: '010-8804-0081',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '안덕면',
    recieve_address_1: '제주특별자치도 서귀포시 안덕면 산록남로 863',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 안덕면 상천리 산 62-3',
    x: 126.3885872,
    y: 33.31528848,
    delivery_req: '',
    article: '태블릿',
    quantity: 12,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 52,
    order_num: '20231201-52',
    order_name: '복준하',
    order_contact: '010-7326-9997',
    reciever: '조진영',
    reciever_contact: '010-4824-0445',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중문동',
    recieve_address_1: '제주특별자치도 서귀포시 천제연로 183-1',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중문동 2083-2',
    x: 126.4242919,
    y: 33.25184157,
    delivery_req: '',
    article: '안경',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 53,
    order_num: '20231201-53',
    order_name: '배창진',
    order_contact: '010-1448-5435',
    reciever: '이현욱',
    reciever_contact: '010-4196-2567',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중문동',
    recieve_address_1: '제주특별자치도 서귀포시 중문로 72',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중문동 1432-2',
    x: 126.4267614,
    y: 33.25748691,
    delivery_req: '',
    article: '핸드폰',
    quantity: 5,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 54,
    order_num: '20231201-54',
    order_name: '배주아',
    order_contact: '010-3390-4447',
    reciever: '권만수',
    reciever_contact: '010-3548-0857',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중문동',
    recieve_address_1: '제주특별자치도 서귀포시 천제연로 169',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중문동 2114-2',
    x: 126.4227835,
    y: 33.25205517,
    delivery_req: '',
    article: '노트북',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 55,
    order_num: '20231201-55',
    order_name: '허지석',
    order_contact: '010-7289-3123',
    reciever: '예형남',
    reciever_contact: '010-6285-2405',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중문동',
    recieve_address_1: '제주특별자치도 서귀포시 천제연로178번길 14-2',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중문동 2140-16',
    x: 126.4232157,
    y: 33.25086595,
    delivery_req: '',
    article: '가방',
    quantity: 5,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 56,
    order_num: '20231201-56',
    order_name: '탁한빈',
    order_contact: '010-9204-0588',
    reciever: '김경아',
    reciever_contact: '010-1245-4380',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중문동',
    recieve_address_1: '제주특별자치도 서귀포시 천제연로 144',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중문동 2180',
    x: 126.420438,
    y: 33.25276249,
    delivery_req: '',
    article: '책상',
    quantity: 15,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 57,
    order_num: '20231201-57',
    order_name: '허유미',
    order_contact: '010-5488-9194',
    reciever: '고경준',
    reciever_contact: '010-6744-9885',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중문동',
    recieve_address_1: '제주특별자치도 서귀포시 대포로 32-6',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중문동 2456-3',
    x: 126.4313816,
    y: 33.24706039,
    delivery_req: '',
    article: '과일',
    quantity: 10,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 58,
    order_num: '20231201-58',
    order_name: '예미진',
    order_contact: '010-1372-6873',
    reciever: '탁한빈',
    reciever_contact: '010-1485-0190',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중앙동',
    recieve_address_1: '제주특별자치도 서귀포시 중정로 117',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중앙동 251-11',
    x: 126.5688741,
    y: 33.24875333,
    delivery_req: '',
    article: '가전제품',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 59,
    order_num: '20231201-59',
    order_name: '송지우',
    order_contact: '010-4196-2567',
    reciever: '추인혁',
    reciever_contact: '010-7759-5453',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중앙동',
    recieve_address_1: '제주특별자치도 서귀포시 중앙로 70-1',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중앙동 290-5',
    x: 126.5615023,
    y: 33.25088057,
    delivery_req: '',
    article: '옷',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 60,
    order_num: '20231201-60',
    order_name: '이수아',
    order_contact: '010-9801-3683',
    reciever: '윤효원',
    reciever_contact: '010-1914-8082',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중앙동',
    recieve_address_1: '제주특별자치도 서귀포시 중앙로 92',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중앙동 284-3',
    x: 126.5615872,
    y: 33.25276707,
    delivery_req: '',
    article: '애견용품',
    quantity: 5,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 61,
    order_num: '20231201-61',
    order_name: '홍광훈',
    order_contact: '010-6968-4414',
    reciever: '유윤준',
    reciever_contact: '010-5837-6039',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '중앙동',
    recieve_address_1: '제주특별자치도 서귀포시 동문로 12',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 중앙동 286-4',
    x: 126.5624378,
    y: 33.25181898,
    delivery_req: '',
    article: '음향기기',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 62,
    order_num: '20231201-62',
    order_name: '강희우',
    order_contact: '010-4902-4995',
    reciever: '강창진',
    reciever_contact: '010-8022-0926',
    add_contact: '010-2583-3205',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '서홍동',
    recieve_address_1: '제주특별자치도 서귀포시 중앙로101번길 7',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 서홍동 441-5',
    x: 126.5601371,
    y: 33.25352568,
    delivery_req: '',
    article: '태블릿',
    quantity: 1,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 63,
    order_num: '20231201-63',
    order_name: '양재아',
    order_contact: '010-2513-5297',
    reciever: '봉승기',
    reciever_contact: '010-1396-1467',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '서홍동',
    recieve_address_1: '제주특별자치도 서귀포시 홍중로 51',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 서홍동 350-3',
    x: 126.5568688,
    y: 33.25725764,
    delivery_req: '',
    article: '서류',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 64,
    order_num: '20231201-64',
    order_name: '심성원',
    order_contact: '010-9210-8551',
    reciever: '유원진',
    reciever_contact: '010-6968-4414',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '서홍동',
    recieve_address_1: '제주특별자치도 서귀포시 중앙로 153',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 서홍동 314-23',
    x: 126.5599161,
    y: 33.2582351,
    delivery_req: '',
    article: '신발',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 65,
    order_num: '20231201-65',
    order_name: '오형수',
    order_contact: '010-1851-7664',
    reciever: '설문혜',
    reciever_contact: '010-6855-4970',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '서홍동',
    recieve_address_1: '제주특별자치도 서귀포시 일주동로 8702',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 서홍동 442-10',
    x: 126.5586096,
    y: 33.2529966,
    delivery_req: '',
    article: '안경',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 66,
    order_num: '20231201-66',
    order_name: '이현욱',
    order_contact: '010-3070-5803',
    reciever: '권재원',
    reciever_contact: '010-9002-2357',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '동홍동',
    recieve_address_1: '제주특별자치도 서귀포시 중산간동로 8002',
    recieve_address_2: '2층',
    jibun: '제주특별자치도 서귀포시 동홍동 1674',
    x: 126.5617549,
    y: 33.26557795,
    delivery_req: '오시기전에 연락주세요',
    article: '핸드폰',
    quantity: 6,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 67,
    order_num: '20231201-67',
    order_name: '임선혜',
    order_contact: '010-3195-2873',
    reciever: '윤범호',
    reciever_contact: '010-6886-4339',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '동홍동',
    recieve_address_1: '제주특별자치도 서귀포시 동홍로 56',
    recieve_address_2: '3층',
    jibun: '제주특별자치도 서귀포시 동홍동 441-1',
    x: 126.5680981,
    y: 33.25378532,
    delivery_req: '오시기전에 연락주세요',
    article: '노트북',
    quantity: 10,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 68,
    order_num: '20231201-68',
    order_name: '정주아',
    order_contact: '010-3828-6343',
    reciever: '강해빈',
    reciever_contact: '010-6825-1686',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '동홍동',
    recieve_address_1: '제주특별자치도 서귀포시 일주동로 8664',
    recieve_address_2: '해송초밥',
    jibun: '제주특별자치도 서귀포시 동홍동 1542-9',
    x: 126.5626513,
    y: 33.25371934,
    delivery_req: '',
    article: '가방',
    quantity: 10,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 69,
    order_num: '20231201-69',
    order_name: '권재원',
    order_contact: '010-6855-4970',
    reciever: '전홍준',
    reciever_contact: '010-9534-3263',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '동홍동',
    recieve_address_1: '제주특별자치도 서귀포시 동홍북로48번길 10',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 동홍동 82-1',
    x: 126.5731587,
    y: 33.2582992,
    delivery_req: '',
    article: '책상',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 70,
    order_num: '20231201-70',
    order_name: '강우일',
    order_contact: '010-2529-7634',
    reciever: '손지희',
    reciever_contact: '010-9801-3683',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '동홍동',
    recieve_address_1: '제주특별자치도 서귀포시 태평로 509',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 동홍동 179',
    x: 126.5717664,
    y: 33.25068466,
    delivery_req: '',
    article: '과일',
    quantity: 5,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 71,
    order_num: '20231201-71',
    order_name: '손민용',
    order_contact: '010-6125-3249',
    reciever: '표명숙',
    reciever_contact: '010-5178-9746',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '동홍동',
    recieve_address_1: '제주특별자치도 서귀포시 동홍북로 4',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 동홍동 588-2',
    x: 126.5687037,
    y: 33.25901054,
    delivery_req: '',
    article: '가전제품',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 72,
    order_num: '20231201-72',
    order_name: '김경아',
    order_contact: '010-7526-9378',
    reciever: '홍광훈',
    reciever_contact: '010-3359-8912',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '동홍동',
    recieve_address_1: '제주특별자치도 서귀포시 일주동로 8600',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 동홍동 482-17',
    x: 126.5693845,
    y: 33.25494908,
    delivery_req: '',
    article: '옷',
    quantity: 7,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 73,
    order_num: '20231201-73',
    order_name: '권다영',
    order_contact: '010-7630-6133',
    reciever: '풍연석',
    reciever_contact: '010-2261-6922',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '동홍동',
    recieve_address_1: '제주특별자치도 서귀포시 동홍로162번길 3',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 동홍동 1183',
    x: 126.5678161,
    y: 33.26299473,
    delivery_req: '',
    article: '애견용품',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 74,
    order_num: '20231201-74',
    order_name: '백원희',
    order_contact: '010-6001-6176',
    reciever: '표태용',
    reciever_contact: '010-6052-9092',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '조천읍',
    recieve_address_1: '제주특별자치도 제주시 조천읍 번영로 1278-169',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 조천읍 교래리 385-1',
    x: 126.6702793,
    y: 33.44833991,
    delivery_req: '',
    article: '음향기기',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 75,
    order_num: '20231201-75',
    order_name: '고은하',
    order_contact: '010-5837-6039',
    reciever: '고주윤',
    reciever_contact: '010-3232-3332',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '조천읍',
    recieve_address_1: '제주특별자치도 제주시 조천읍 중산간동로 778-8',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 조천읍 대흘리 1122',
    x: 126.6653392,
    y: 33.49217945,
    delivery_req: '',
    article: '태블릿',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 76,
    order_num: '20231201-76',
    order_name: '설수근',
    order_contact: '010-5944-1765',
    reciever: '양재아',
    reciever_contact: '010-1431-7237',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '조천읍',
    recieve_address_1: '제주특별자치도 제주시 조천읍 북흘로 135',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 조천읍 북촌리 82-1',
    x: 126.7018577,
    y: 33.53699553,
    delivery_req: '',
    article: '서류',
    quantity: 4,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 77,
    order_num: '20231201-77',
    order_name: '추은빈',
    order_contact: '010-5933-7363',
    reciever: '서진우',
    reciever_contact: '010-3956-1701',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '조천읍',
    recieve_address_1: '제주특별자치도 제주시 조천읍 선진길 7-9',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 조천읍 선흘리 1947-6',
    x: 126.702726,
    y: 33.45952905,
    delivery_req: '',
    article: '신발',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 78,
    order_num: '20231201-78',
    order_name: '풍인용',
    order_contact: '010-7816-7524',
    reciever: '풍인용',
    reciever_contact: '010-8469-4292',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '조천읍',
    recieve_address_1: '제주특별자치도 제주시 조천읍 조함해안로 525',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 조천읍 함덕리 1004-10',
    x: 126.6692381,
    y: 33.54306232,
    delivery_req: '',
    article: '안경',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 79,
    order_num: '20231201-79',
    order_name: '최도윤',
    order_contact: '010-4024-1221',
    reciever: '배창진',
    reciever_contact: '010-8556-0291',
    add_contact: '010-1396-1467',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '조천읍',
    recieve_address_1: '제주특별자치도 제주시 조천읍 신북로 519',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 조천읍 함덕리 1269-3',
    x: 126.6652509,
    y: 33.54201339,
    delivery_req: '',
    article: '핸드폰',
    quantity: 8,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 80,
    order_num: '20231201-80',
    order_name: '장대선',
    order_contact: '010-5780-7093',
    reciever: '임정숙',
    reciever_contact: '010-5362-3696',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '조천읍',
    recieve_address_1: '제주특별자치도 제주시 조천읍 함덕서4길 21',
    recieve_address_2: '1층 관리사무소',
    jibun: '제주특별자치도 제주시 조천읍 함덕리 1507-1',
    x: 126.6632262,
    y: 33.53823906,
    delivery_req: '',
    article: '노트북',
    quantity: 9,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 81,
    order_num: '20231201-81',
    order_name: '서혜윤',
    order_contact: '010-9598-4959',
    reciever: '김성환',
    reciever_contact: '010-6535-1533',
    add_contact: '010-9534-3263',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '조천읍',
    recieve_address_1: '제주특별자치도 제주시 조천읍 함덕16길 30',
    recieve_address_2: '지하 1층',
    jibun: '제주특별자치도 제주시 조천읍 함덕리 1287-1',
    x: 126.6658738,
    y: 33.54082939,
    delivery_req: '',
    article: '가방',
    quantity: 3,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 82,
    order_num: '20231201-82',
    order_name: '오경희',
    order_contact: '010-8917-5951',
    reciever: '정주아',
    reciever_contact: '010-5780-7093',
    add_contact: '010-3405-8129',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '구좌읍',
    recieve_address_1: '제주특별자치도 제주시 구좌읍 세화2길 9',
    recieve_address_2: '화장품전문점 끝집',
    jibun: '제주특별자치도 제주시 구좌읍 세화리 1424-1',
    x: 126.8560102,
    y: 33.52276939,
    delivery_req: '',
    article: '책상',
    quantity: 13,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 83,
    order_num: '20231201-83',
    order_name: '서남기',
    order_contact: '010-6825-1686',
    reciever: '장대선',
    reciever_contact: '010-3932-8936',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '구좌읍',
    recieve_address_1: '제주특별자치도 제주시 구좌읍 세화3길 27',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 구좌읍 세화리 3647',
    x: 126.8558217,
    y: 33.52594686,
    delivery_req: '',
    article: '과일',
    quantity: 12,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 84,
    order_num: '20231201-84',
    order_name: '하승언',
    order_contact: '010-4008-5170',
    reciever: '허지석',
    reciever_contact: '010-1563-8275',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '구좌읍',
    recieve_address_1: '제주특별자치도 제주시 구좌읍 해맞이해안로 462',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 구좌읍 월정리 536-4',
    x: 126.7962884,
    y: 33.55544086,
    delivery_req: '',
    article: '가전제품',
    quantity: 10,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 85,
    order_num: '20231201-85',
    order_name: '조진영',
    order_contact: '010-6886-4339',
    reciever: '장수민',
    reciever_contact: '010-7967-2146',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '구좌읍',
    recieve_address_1: '제주특별자치도 제주시 구좌읍 비자숲길 55',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 구좌읍 평대리 3161-1',
    x: 126.8105972,
    y: 33.49082296,
    delivery_req: '',
    article: '옷',
    quantity: 9,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 86,
    order_num: '20231201-86',
    order_name: '권만수',
    order_contact: '010-8395-2988',
    reciever: '홍현식',
    reciever_contact: '010-4008-5170',
    add_contact: '010-7215-6864',
    sido: '제주특별자치도',
    sigungu: '제주시',
    dong: '구좌읍',
    recieve_address_1: '제주특별자치도 제주시 구좌읍 일주동로 2670',
    recieve_address_2: '',
    jibun: '제주특별자치도 제주시 구좌읍 행원리 940-3',
    x: 126.8115262,
    y: 33.54306416,
    delivery_req: '',
    article: '애견용품',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 87,
    order_num: '20231201-87',
    order_name: '윤범호',
    order_contact: '010-8022-0926',
    reciever: '배승희',
    reciever_contact: '010-6872-1526',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '성산읍',
    recieve_address_1: '제주특별자치도 서귀포시 성산읍 서성일로 1244',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 성산읍 고성리 1106-13',
    x: 126.9114286,
    y: 33.44681719,
    delivery_req: '',
    article: '음향기기',
    quantity: 4,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 88,
    order_num: '20231201-88',
    order_name: '백성남',
    order_contact: '010-3863-7133',
    reciever: '성동훈',
    reciever_contact: '010-7289-3123',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '성산읍',
    recieve_address_1: '제주특별자치도 서귀포시 성산읍 일주동로 5149',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 성산읍 신산리 1154-18',
    x: 126.8745199,
    y: 33.37759042,
    delivery_req: '',
    article: '태블릿',
    quantity: 7,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 89,
    order_num: '20231201-89',
    order_name: '김대식',
    order_contact: '010-8343-3499',
    reciever: '강희우',
    reciever_contact: '010-9075-5303',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '성산읍',
    recieve_address_1: '제주특별자치도 서귀포시 성산읍 신풍하동로 224-17',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 성산읍 신풍리 188-1',
    x: 126.85959,
    y: 33.35882078,
    delivery_req: '',
    article: '서류',
    quantity: 12,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 90,
    order_num: '20231201-90',
    order_name: '탁현정',
    order_contact: '010-4453-5070',
    reciever: '서홍식',
    reciever_contact: '010-9353-9339',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '성산읍',
    recieve_address_1: '제주특별자치도 서귀포시 성산읍 산성효자로114번길 131-1',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 성산읍 오조리 1052-4',
    x: 126.9083995,
    y: 33.45607525,
    delivery_req: '',
    article: '신발',
    quantity: 4,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 91,
    order_num: '20231201-91',
    order_name: '봉승기',
    order_contact: '010-3349-2706',
    reciever: '설진미',
    reciever_contact: '010-5957-3508',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '표선면',
    recieve_address_1: '제주특별자치도 서귀포시 표선면 한마음초등로 143-11',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 표선면 가시리 347',
    x: 126.7871354,
    y: 33.35143151,
    delivery_req: '',
    article: '안경',
    quantity: 4,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 92,
    order_num: '20231201-92',
    order_name: '이민욱',
    order_contact: '010-8000-9377',
    reciever: '예미진',
    reciever_contact: '010-7526-9378',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '표선면',
    recieve_address_1: '제주특별자치도 서귀포시 표선면 표선중앙로 76',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 표선면 표선리 699',
    x: 126.832166,
    y: 33.3264074,
    delivery_req: '',
    article: '핸드폰',
    quantity: 15,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 93,
    order_num: '20231201-93',
    order_name: '손원철',
    order_contact: '010-4074-7833',
    reciever: '유태훈',
    reciever_contact: '010-9204-0588',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '표선면',
    recieve_address_1: '제주특별자치도 서귀포시 표선면 표선동서로 240-1',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 표선면 표선리 631-1',
    x: 126.8325934,
    y: 33.3253116,
    delivery_req: '',
    article: '노트북',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 94,
    order_num: '20231201-94',
    order_name: '서홍식',
    order_contact: '010-4424-8664',
    reciever: '김혜윤',
    reciever_contact: '010-8000-9377',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '남원읍',
    recieve_address_1: '제주특별자치도 서귀포시 남원읍 태위로 684',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 남원읍 남원리 99-3',
    x: 126.7196449,
    y: 33.27915496,
    delivery_req: '',
    article: '가방',
    quantity: 7,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 95,
    order_num: '20231201-95',
    order_name: '오윤재',
    order_contact: '010-2743-5775',
    reciever: '최수아',
    reciever_contact: '010-3110-4747',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '남원읍',
    recieve_address_1: '제주특별자치도 서귀포시 남원읍 태위로724번길 4',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 남원읍 남원리 47-1',
    x: 126.7235569,
    y: 33.28008582,
    delivery_req: '',
    article: '책상',
    quantity: 2,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 96,
    order_num: '20231201-96',
    order_name: '정민석',
    order_contact: '010-5362-3696',
    reciever: '허유미',
    reciever_contact: '010-2041-4482',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '남원읍',
    recieve_address_1: '제주특별자치도 서귀포시 남원읍 수망리 218-1',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 남원읍 수망리 218-1',
    x: 126.7129512,
    y: 33.32438766,
    delivery_req: '',
    article: '과일',
    quantity: 1,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 97,
    order_num: '20231201-97',
    order_name: '임유정',
    order_contact: '010-3359-8912',
    reciever: '임선혜',
    reciever_contact: '010-8627-1744',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '남원읍',
    recieve_address_1: '제주특별자치도 서귀포시 남원읍 태위로215번길 2',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 남원읍 위미리 1591-1',
    x: 126.6706448,
    y: 33.27500496,
    delivery_req: '',
    article: '가전제품',
    quantity: 5,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 98,
    order_num: '20231201-98',
    order_name: '오현숙',
    order_contact: '010-9075-5303',
    reciever: '추은빈',
    reciever_contact: '010-3349-2706',
    add_contact: '010-7636-1857',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '남원읍',
    recieve_address_1: '제주특별자치도 서귀포시 남원읍 원님로 1-12',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 남원읍 의귀리 755',
    x: 126.7180034,
    y: 33.31328957,
    delivery_req: '',
    article: '옷',
    quantity: 11,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 99,
    order_num: '20231201-99',
    order_name: '허기현',
    order_contact: '010-6872-1526',
    reciever: '손민용',
    reciever_contact: '010-6273-1340',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '남원읍',
    recieve_address_1: '제주특별자치도 서귀포시 남원읍 남조로 264-1',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 남원읍 의귀리 971-10',
    x: 126.7195183,
    y: 33.30278037,
    delivery_req: '',
    article: '애견용품',
    quantity: 14,
    isDelivery: false,
    imageUrl: '',
  },
  {
    id: 100,
    order_num: '20231201-100',
    order_name: '설문혜',
    order_contact: '010-8556-0291',
    reciever: '강우일',
    reciever_contact: '010-6535-5095',
    sido: '제주특별자치도',
    sigungu: '서귀포시',
    dong: '남원읍',
    recieve_address_1: '제주특별자치도 서귀포시 남원읍 516로 918',
    recieve_address_2: '',
    jibun: '제주특별자치도 서귀포시 남원읍 하례리 1876-1',
    x: 126.6010365,
    y: 33.32572976,
    delivery_req: '',
    article: '음향기기',
    quantity: 3,
    isDelivery: false,
    imageUrl: '',
  },
] as const;

export const routeGroupData = [
  {
    no: '1',
    sidoName: '제주',
    orderCount: 100,
    quantityCount: 780,
    sigungu: [
      {
        no: '1-1',
        sigunguName: '제주 (북)',
        orderCount: 29,
        quantityCount: 230,
        dong: [
          {
            dongName: '외도1동',
            orderCount: 3,
            quantityCount: 22,
          },
          {
            dongName: '이호2동',
            orderCount: 3,
            quantityCount: 17,
          },
          {
            dongName: '도두1동',
            orderCount: 2,
            quantityCount: 18,
          },
          {
            dongName: '도두2동',
            orderCount: 1,
            quantityCount: 8,
          },
          {
            dongName: '용담1동',
            orderCount: 3,
            quantityCount: 22,
          },
          {
            dongName: '용담2동',
            orderCount: 4,
            quantityCount: 37,
          },
          {
            dongName: '삼도1동',
            orderCount: 3,
            quantityCount: 14,
          },
          {
            dongName: '삼도2동',
            orderCount: 2,
            quantityCount: 17,
          },
          {
            dongName: '이도1동',
            orderCount: 1,
            quantityCount: 12,
          },
          {
            dongName: '이도2동',
            orderCount: 1,
            quantityCount: 13,
          },
          {
            dongName: '화북1동',
            orderCount: 1,
            quantityCount: 7,
          },
          {
            dongName: '삼양동',
            orderCount: 1,
            quantityCount: 8,
          },
          {
            dongName: '이라1동',
            orderCount: 1,
            quantityCount: 4,
          },
          {
            dongName: '이라2동',
            orderCount: 1,
            quantityCount: 3,
          },
          {
            dongName: '오라3동',
            orderCount: 1,
            quantityCount: 15,
          },
          {
            dongName: '노형동',
            orderCount: 1,
            quantityCount: 13,
          },
        ],
        groupOrderInfo: [
          {
            id: 1,
            order_num: '20231201-01',
            order_name: '유미연',
            order_contact: '010-3232-3332',
            reciever: '홍재성',
            reciever_contact: '010-3390-4447',
            add_contact: '010-6142-3094',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '외도1동',
            recieve_address_1: '제주특별자치도 제주시 우정로 60',
            recieve_address_2: '지하 3층 입구',
            jibun: '제주특별자치도 제주시 외도1동 541-13',
            x: 126.4306693,
            y: 33.48750436,
            delivery_req: '로비에 맡겨주세요.',
            article: '핸드폰',
            quantity: 6,
          },
          {
            id: 2,
            order_num: '20231201-02',
            order_name: '예민우',
            order_contact: '010-2462-5846',
            reciever: '김찬우',
            reciever_contact: '010-3863-7133',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '외도1동',
            recieve_address_1: '제주특별자치도 제주시 우정로15길 5',
            recieve_address_2: 'A-3동 2층',
            jibun: '제주특별자치도 제주시 외도1동 639-3',
            x: 126.4317287,
            y: 33.48609192,
            delivery_req: '오시기 전에 연락 주세요.',
            article: '노트북',
            quantity: 13,
          },
          {
            id: 3,
            order_num: '20231201-03',
            order_name: '표명숙',
            order_contact: '010-8638-0575',
            reciever: '서영숙',
            reciever_contact: '010-6125-3249',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '외도1동',
            recieve_address_1: '제주특별자치도 제주시 우정로11길 22',
            recieve_address_2: '외도부영1차 104동 1011호',
            jibun: '제주특별자치도 제주시 외도1동 560-1',
            x: 126.433703,
            y: 33.48644204,
            delivery_req: '',
            article: '가방',
            quantity: 3,
          },
          {
            id: 4,
            order_num: '20231201-04',
            order_name: '안기혁',
            order_contact: '010-2583-3205',
            reciever: '임윤희',
            reciever_contact: '010-9376-8598',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이호2동',
            recieve_address_1: '제주특별자치도 제주시 오도11길 19-1',
            recieve_address_2: '1층',
            jibun: '제주특별자치도 제주시 이호2동 1095',
            x: 126.4599767,
            y: 33.4899977,
            delivery_req: '없어요',
            article: '책상',
            quantity: 7,
          },
          {
            id: 5,
            order_num: '20231201-05',
            order_name: '권영숙',
            order_contact: '010-6052-9092',
            reciever: '안기혁',
            reciever_contact: '010-7243-1286',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이호2동',
            recieve_address_1: '제주특별자치도 제주시 대동길 17-1',
            recieve_address_2: '402호',
            jibun: '제주특별자치도 제주시 이호이동 116-3',
            x: 126.4635886,
            y: 33.4933673,
            delivery_req: '',
            article: '과일',
            quantity: 4,
          },
          {
            id: 6,
            order_num: '20231201-07',
            order_name: '황광호',
            order_contact: '010-6843-1582',
            reciever: '예은용',
            reciever_contact: '010-7576-6338',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '도두1동',
            recieve_address_1: '제주특별자치도 제주시 도공로 2',
            recieve_address_2: '3차 503호',
            jibun: '제주특별자치도 제주시 도두일동 2614-5',
            x: 126.4649822,
            y: 33.50612901,
            delivery_req: '',
            article: '옷',
            quantity: 8,
          },
          {
            id: 7,
            order_num: '20231201-06',
            order_name: '홍현식',
            order_contact: '010-7576-6338',
            reciever: '류보영',
            reciever_contact: '010-5488-9194',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이호2동',
            recieve_address_1: '제주특별자치도 제주시 월랑로 91',
            recieve_address_2: '나동 302호',
            jibun: '제주특별자치도 제주시 이호이동 22',
            x: 126.4705035,
            y: 33.49228629,
            delivery_req: '',
            article: '가전제품',
            quantity: 6,
          },
          {
            id: 8,
            order_num: '20231201-08',
            order_name: '설대영',
            order_contact: '010-5071-7664',
            reciever: '최우준',
            reciever_contact: '010-6843-1582',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '도두1동',
            recieve_address_1: '제주특별자치도 제주시 일주서로 7714',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 도두일동 2288',
            x: 126.4706157,
            y: 33.49347795,
            delivery_req: '연락주시면 1층으로 나옵니다',
            article: '애견용품',
            quantity: 10,
          },
          {
            id: 9,
            order_num: '20231201-09',
            order_name: '장수민',
            order_contact: '010-7636-1857',
            reciever: '김현수',
            reciever_contact: '010-2529-7634',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '도두2동',
            recieve_address_1: '제주특별자치도 제주시 오일장중길 128',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 도두이동 1106-1',
            x: 126.4800192,
            y: 33.4997582,
            delivery_req: '',
            article: '음향기기',
            quantity: 8,
          },
          {
            id: 10,
            order_num: '20231201-16',
            order_name: '풍연석',
            order_contact: '010-4638-1516',
            reciever: '전서우',
            reciever_contact: '010-5933-7363',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담2동',
            recieve_address_1: '제주특별자치도 제주시 공항로 2',
            recieve_address_2: '1층 1번게이트',
            jibun: '제주특별자치도 제주시 용담2동 2002',
            x: 126.4938576,
            y: 33.50579497,
            delivery_req: '',
            article: '가방',
            quantity: 15,
          },
          {
            id: 11,
            order_num: '20231201-13',
            order_name: '홍재성',
            order_contact: '010-5957-3508',
            reciever: '김진영',
            reciever_contact: '010-8804-0081',
            add_contact: '010-3548-0857',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담2동',
            recieve_address_1: '제주특별자치도 제주시 용해로 92',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담2동 855',
            x: 126.5014587,
            y: 33.50901749,
            delivery_req: '',
            article: '안경',
            quantity: 5,
          },
          {
            id: 12,
            order_num: '20231201-15',
            order_name: '설진미',
            order_contact: '010-4206-6902',
            reciever: '노만옥',
            reciever_contact: '010-2513-5297',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담2동',
            recieve_address_1: '제주특별자치도 제주시 용문로 157',
            recieve_address_2: '641동 1호 반석텔레콤',
            jibun: '제주특별자치도 제주시 용담2동 641-1',
            x: 126.509957,
            y: 33.50890083,
            delivery_req: '',
            article: '노트북',
            quantity: 7,
          },
          {
            id: 13,
            order_num: '20231201-14',
            order_name: '손하현',
            order_contact: '010-2261-6922',
            reciever: '손원철',
            reciever_contact: '010-7326-9997',
            add_contact: '010-3956-1701',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담2동',
            recieve_address_1: '제주특별자치도 제주시 용담로 90',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담2동 359-58',
            x: 126.5121006,
            y: 33.51202158,
            delivery_req: '',
            article: '핸드폰',
            quantity: 10,
          },
          {
            id: 14,
            order_num: '20231201-10',
            order_name: '김성환',
            order_contact: '010-7958-7628',
            reciever: '유미연',
            reciever_contact: '010-6270-3794',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담1동',
            recieve_address_1: '제주특별자치도 제주시 서문로 29',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담1동 301-5',
            x: 126.5145717,
            y: 33.51056634,
            delivery_req: '3층 유미연 고객님',
            article: '태블릿',
            quantity: 7,
          },
          {
            id: 15,
            order_num: '20231201-12',
            order_name: '권윤미',
            order_contact: '010-8151-1689',
            reciever: '문영은',
            reciever_contact: '010-8638-0575',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담1동',
            recieve_address_1: '제주특별자치도 제주시 서문로 57',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담1동 129-6',
            x: 126.5172774,
            y: 33.51167761,
            delivery_req: '',
            article: '신발',
            quantity: 14,
          },
          {
            id: 16,
            order_num: '20231201-21',
            order_name: '윤효원',
            order_contact: '010-5178-9746',
            reciever: '손영훈',
            reciever_contact: '010-2462-5846',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도2동',
            recieve_address_1: '제주특별자치도 제주시 무근성5길 18',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도2동 1139-1',
            x: 126.5195577,
            y: 33.51426863,
            delivery_req: '',
            article: '애견용품',
            quantity: 4,
          },
          {
            id: 17,
            order_num: '20231201-22',
            order_name: '문영은',
            order_contact: '010-1431-7237',
            reciever: '한창민',
            reciever_contact: '010-4111-3755',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이도1동',
            recieve_address_1: '제주특별자치도 제주시 중앙로 70',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 이도1동 1369-1',
            x: 126.5244737,
            y: 33.51202281,
            delivery_req: '',
            article: '음향기기',
            quantity: 12,
          },
          {
            id: 18,
            order_num: '20231201-11',
            order_name: '배종식',
            order_contact: '010-6535-1533',
            reciever: '안원기',
            reciever_contact: '010-3828-6343',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '용담1동',
            recieve_address_1: '제주특별자치도 제주시 남성로4길 1',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 용담1동 2818-9',
            x: 126.5132579,
            y: 33.50217715,
            delivery_req: '',
            article: '서류',
            quantity: 1,
          },
          {
            id: 19,
            order_num: '20231201-18',
            order_name: '유태훈',
            order_contact: '010-4824-0445',
            reciever: '신윤재',
            reciever_contact: '010-2583-3205',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도1동',
            recieve_address_1: '제주특별자치도 제주시 서사로 96',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도1동 530-23',
            x: 126.5196457,
            y: 33.50387836,
            delivery_req: '',
            article: '과일',
            quantity: 3,
          },
          {
            id: 20,
            order_num: '20231201-20',
            order_name: '표태용',
            order_contact: '010-8469-4292',
            reciever: '유승환',
            reciever_contact: '010-7816-7524',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도2동',
            recieve_address_1: '제주특별자치도 제주시 중앙로26길 22',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도2동 1239-10',
            x: 126.5233215,
            y: 33.50553709,
            delivery_req: '',
            article: '옷',
            quantity: 13,
          },
          {
            id: 21,
            order_num: '20231201-17',
            order_name: '홍은정',
            order_contact: '010-9353-9339',
            reciever: '복준하',
            reciever_contact: '010-4206-6902',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도1동',
            recieve_address_1: '제주특별자치도 제주시 서광로 229',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도1동 552-8',
            x: 126.5209751,
            y: 33.5000362,
            delivery_req: '',
            article: '책상',
            quantity: 10,
          },
          {
            id: 22,
            order_num: '20231201-19',
            order_name: '유윤준',
            order_contact: '010-1485-0190',
            reciever: '정민석',
            reciever_contact: '010-9598-4959',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼도1동',
            recieve_address_1: '제주특별자치도 제주시 서광로 237-1',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼도1동 552-24',
            x: 126.5218884,
            y: 33.50000302,
            delivery_req: '',
            article: '가전제품',
            quantity: 1,
          },
          {
            id: 23,
            order_num: '20231201-23',
            order_name: '권진우',
            order_contact: '010-7967-2146',
            reciever: '하철민',
            reciever_contact: '010-4024-1221',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '이도2동',
            recieve_address_1: '제주특별자치도 제주시 중앙로 279',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 이도2동 1057-3',
            x: 126.5332468,
            y: 33.49546277,
            delivery_req: '',
            article: '태블릿',
            quantity: 13,
          },
          {
            id: 24,
            order_num: '20231201-24',
            order_name: '임정숙',
            order_contact: '010-1245-4380',
            reciever: '오형수',
            reciever_contact: '010-4074-7833',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '화북1동',
            recieve_address_1: '제주특별자치도 제주시 일주동로 254',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 화북1동 1230',
            x: 126.5723692,
            y: 33.51988238,
            delivery_req: '',
            article: '서류',
            quantity: 7,
          },
          {
            id: 25,
            order_num: '20231201-25',
            order_name: '고희수',
            order_contact: '010-1352-7413',
            reciever: '조동일',
            reciever_contact: '010-9210-8551',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '삼양동',
            recieve_address_1: '제주특별자치도 제주시 화삼북로 90',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 삼양동 1909',
            x: 126.582654,
            y: 33.51655645,
            delivery_req: '',
            article: '신발',
            quantity: 8,
          },
          {
            id: 26,
            order_num: '20231201-26',
            order_name: '조규원',
            order_contact: '010-3405-9385',
            reciever: '김미숙',
            reciever_contact: '010-1448-5435',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '아라1동',
            recieve_address_1: '제주특별자치도 제주시 제주대학로 102',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 아라1동 1',
            x: 126.560822,
            y: 33.45265161,
            delivery_req: '',
            article: '안경',
            quantity: 4,
          },
          {
            id: 27,
            order_num: '20231201-27',
            order_name: '노만옥',
            order_contact: '010-3548-0857',
            reciever: '조하영',
            reciever_contact: '010-1851-7664',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '아라2동',
            recieve_address_1: '제주특별자치도 제주시 신설로11길 2-10',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 아라2동 3009-5',
            x: 126.5443405,
            y: 33.49098938,
            delivery_req: '',
            article: '핸드폰',
            quantity: 3,
          },
          {
            id: 28,
            order_num: '20231201-28',
            order_name: '허남혁',
            order_contact: '010-1914-8082',
            reciever: '유연웅',
            reciever_contact: '010-4638-1516',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '오라3동',
            recieve_address_1: '제주특별자치도 제주시 연삼로 120',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 오라3동 2811-3',
            x: 126.5051068,
            y: 33.49285768,
            delivery_req: '',
            article: '노트북',
            quantity: 15,
          },
          {
            id: 29,
            order_num: '20231201-29',
            order_name: '이용욱',
            order_contact: '010-3932-8936',
            reciever: '허태욱',
            reciever_contact: '010-2743-5775',
            add_contact: '010-1448-5435',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '노형동',
            recieve_address_1: '제주특별자치도 제주시 노형로 375',
            recieve_address_2: '베테랑모바일',
            jibun: '제주특별자치도 제주시 노형동 2536-11',
            x: 126.4757322,
            y: 33.48380372,
            delivery_req: '',
            article: '가방',
            quantity: 13,
          },
        ],
      },
      {
        no: '1-2',
        sigunguName: '제주 (동)',
        orderCount: 27,
        quantityCount: 147,
        dong: [
          {
            dongName: '조천읍',
            orderCount: 8,
            quantityCount: 41,
          },
          {
            dongName: '구좌읍',
            orderCount: 5,
            quantityCount: 55,
          },
          {
            dongName: '성산읍',
            orderCount: 4,
            quantityCount: 27,
          },
          {
            dongName: '표선면',
            orderCount: 3,
            quantityCount: 21,
          },
          {
            dongName: '납원읍',
            orderCount: 7,
            quantityCount: 43,
          },
        ],
        groupOrderInfo: [
          {
            id: 74,
            order_num: '20231201-74',
            order_name: '백원희',
            order_contact: '010-6001-6176',
            reciever: '표태용',
            reciever_contact: '010-6052-9092',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '조천읍',
            recieve_address_1: '제주특별자치도 제주시 조천읍 번영로 1278-169',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 조천읍 교래리 385-1',
            x: 126.6702793,
            y: 33.44833991,
            delivery_req: '',
            article: '음향기기',
            quantity: 2,
          },
          {
            id: 75,
            order_num: '20231201-75',
            order_name: '고은하',
            order_contact: '010-5837-6039',
            reciever: '고주윤',
            reciever_contact: '010-3232-3332',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '조천읍',
            recieve_address_1: '제주특별자치도 제주시 조천읍 중산간동로 778-8',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 조천읍 대흘리 1122',
            x: 126.6653392,
            y: 33.49217945,
            delivery_req: '',
            article: '태블릿',
            quantity: 2,
          },
          {
            id: 76,
            order_num: '20231201-76',
            order_name: '설수근',
            order_contact: '010-5944-1765',
            reciever: '양재아',
            reciever_contact: '010-1431-7237',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '조천읍',
            recieve_address_1: '제주특별자치도 제주시 조천읍 북흘로 135',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 조천읍 북촌리 82-1',
            x: 126.7018577,
            y: 33.53699553,
            delivery_req: '',
            article: '서류',
            quantity: 4,
          },
          {
            id: 77,
            order_num: '20231201-77',
            order_name: '추은빈',
            order_contact: '010-5933-7363',
            reciever: '서진우',
            reciever_contact: '010-3956-1701',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '조천읍',
            recieve_address_1: '제주특별자치도 제주시 조천읍 선진길 7-9',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 조천읍 선흘리 1947-6',
            x: 126.702726,
            y: 33.45952905,
            delivery_req: '',
            article: '신발',
            quantity: 11,
          },
          {
            id: 78,
            order_num: '20231201-78',
            order_name: '풍인용',
            order_contact: '010-7816-7524',
            reciever: '풍인용',
            reciever_contact: '010-8469-4292',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '조천읍',
            recieve_address_1: '제주특별자치도 제주시 조천읍 조함해안로 525',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 조천읍 함덕리 1004-10',
            x: 126.6692381,
            y: 33.54306232,
            delivery_req: '',
            article: '안경',
            quantity: 2,
          },
          {
            id: 79,
            order_num: '20231201-79',
            order_name: '최도윤',
            order_contact: '010-4024-1221',
            reciever: '배창진',
            reciever_contact: '010-8556-0291',
            add_contact: '010-1396-1467',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '조천읍',
            recieve_address_1: '제주특별자치도 제주시 조천읍 신북로 519',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 조천읍 함덕리 1269-3',
            x: 126.6652509,
            y: 33.54201339,
            delivery_req: '',
            article: '핸드폰',
            quantity: 8,
          },
          {
            id: 80,
            order_num: '20231201-80',
            order_name: '장대선',
            order_contact: '010-5780-7093',
            reciever: '임정숙',
            reciever_contact: '010-5362-3696',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '조천읍',
            recieve_address_1: '제주특별자치도 제주시 조천읍 함덕서4길 21',
            recieve_address_2: '1층 관리사무소',
            jibun: '제주특별자치도 제주시 조천읍 함덕리 1507-1',
            x: 126.6632262,
            y: 33.53823906,
            delivery_req: '',
            article: '노트북',
            quantity: 9,
          },
          {
            id: 81,
            order_num: '20231201-81',
            order_name: '서혜윤',
            order_contact: '010-9598-4959',
            reciever: '김성환',
            reciever_contact: '010-6535-1533',
            add_contact: '010-9534-3263',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '조천읍',
            recieve_address_1: '제주특별자치도 제주시 조천읍 함덕16길 30',
            recieve_address_2: '지하 1층',
            jibun: '제주특별자치도 제주시 조천읍 함덕리 1287-1',
            x: 126.6658738,
            y: 33.54082939,
            delivery_req: '',
            article: '가방',
            quantity: 3,
          },
          {
            id: 82,
            order_num: '20231201-82',
            order_name: '오경희',
            order_contact: '010-8917-5951',
            reciever: '정주아',
            reciever_contact: '010-5780-7093',
            add_contact: '010-3405-8129',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '구좌읍',
            recieve_address_1: '제주특별자치도 제주시 구좌읍 세화2길 9',
            recieve_address_2: '화장품전문점 끝집',
            jibun: '제주특별자치도 제주시 구좌읍 세화리 1424-1',
            x: 126.8560102,
            y: 33.52276939,
            delivery_req: '',
            article: '책상',
            quantity: 13,
          },
          {
            id: 83,
            order_num: '20231201-83',
            order_name: '서남기',
            order_contact: '010-6825-1686',
            reciever: '장대선',
            reciever_contact: '010-3932-8936',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '구좌읍',
            recieve_address_1: '제주특별자치도 제주시 구좌읍 세화3길 27',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 구좌읍 세화리 3647',
            x: 126.8558217,
            y: 33.52594686,
            delivery_req: '',
            article: '과일',
            quantity: 12,
          },
          {
            id: 84,
            order_num: '20231201-84',
            order_name: '하승언',
            order_contact: '010-4008-5170',
            reciever: '허지석',
            reciever_contact: '010-1563-8275',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '구좌읍',
            recieve_address_1: '제주특별자치도 제주시 구좌읍 해맞이해안로 462',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 구좌읍 월정리 536-4',
            x: 126.7962884,
            y: 33.55544086,
            delivery_req: '',
            article: '가전제품',
            quantity: 10,
          },
          {
            id: 85,
            order_num: '20231201-85',
            order_name: '조진영',
            order_contact: '010-6886-4339',
            reciever: '장수민',
            reciever_contact: '010-7967-2146',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '구좌읍',
            recieve_address_1: '제주특별자치도 제주시 구좌읍 비자숲길 55',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 구좌읍 평대리 3161-1',
            x: 126.8105972,
            y: 33.49082296,
            delivery_req: '',
            article: '옷',
            quantity: 9,
          },
          {
            id: 86,
            order_num: '20231201-86',
            order_name: '권만수',
            order_contact: '010-8395-2988',
            reciever: '홍현식',
            reciever_contact: '010-4008-5170',
            add_contact: '010-7215-6864',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '구좌읍',
            recieve_address_1: '제주특별자치도 제주시 구좌읍 일주동로 2670',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 구좌읍 행원리 940-3',
            x: 126.8115262,
            y: 33.54306416,
            delivery_req: '',
            article: '애견용품',
            quantity: 11,
          },
          {
            id: 87,
            order_num: '20231201-87',
            order_name: '윤범호',
            order_contact: '010-8022-0926',
            reciever: '배승희',
            reciever_contact: '010-6872-1526',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '성산읍',
            recieve_address_1: '제주특별자치도 서귀포시 성산읍 서성일로 1244',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 성산읍 고성리 1106-13',
            x: 126.9114286,
            y: 33.44681719,
            delivery_req: '',
            article: '음향기기',
            quantity: 4,
          },
          {
            id: 88,
            order_num: '20231201-88',
            order_name: '백성남',
            order_contact: '010-3863-7133',
            reciever: '성동훈',
            reciever_contact: '010-7289-3123',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '성산읍',
            recieve_address_1: '제주특별자치도 서귀포시 성산읍 일주동로 5149',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 성산읍 신산리 1154-18',
            x: 126.8745199,
            y: 33.37759042,
            delivery_req: '',
            article: '태블릿',
            quantity: 7,
          },
          {
            id: 89,
            order_num: '20231201-89',
            order_name: '김대식',
            order_contact: '010-8343-3499',
            reciever: '강희우',
            reciever_contact: '010-9075-5303',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '성산읍',
            recieve_address_1:
              '제주특별자치도 서귀포시 성산읍 신풍하동로 224-17',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 성산읍 신풍리 188-1',
            x: 126.85959,
            y: 33.35882078,
            delivery_req: '',
            article: '서류',
            quantity: 12,
          },
          {
            id: 90,
            order_num: '20231201-90',
            order_name: '탁현정',
            order_contact: '010-4453-5070',
            reciever: '서홍식',
            reciever_contact: '010-9353-9339',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '성산읍',
            recieve_address_1:
              '제주특별자치도 서귀포시 성산읍 산성효자로114번길 131-1',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 성산읍 오조리 1052-4',
            x: 126.9083995,
            y: 33.45607525,
            delivery_req: '',
            article: '신발',
            quantity: 4,
          },
          {
            id: 91,
            order_num: '20231201-91',
            order_name: '봉승기',
            order_contact: '010-3349-2706',
            reciever: '설진미',
            reciever_contact: '010-5957-3508',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '표선면',
            recieve_address_1:
              '제주특별자치도 서귀포시 표선면 한마음초등로 143-11',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 표선면 가시리 347',
            x: 126.7871354,
            y: 33.35143151,
            delivery_req: '',
            article: '안경',
            quantity: 4,
          },
          {
            id: 92,
            order_num: '20231201-92',
            order_name: '이민욱',
            order_contact: '010-8000-9377',
            reciever: '예미진',
            reciever_contact: '010-7526-9378',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '표선면',
            recieve_address_1: '제주특별자치도 서귀포시 표선면 표선중앙로 76',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 표선면 표선리 699',
            x: 126.832166,
            y: 33.3264074,
            delivery_req: '',
            article: '핸드폰',
            quantity: 15,
          },
          {
            id: 93,
            order_num: '20231201-93',
            order_name: '손원철',
            order_contact: '010-4074-7833',
            reciever: '유태훈',
            reciever_contact: '010-9204-0588',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '표선면',
            recieve_address_1:
              '제주특별자치도 서귀포시 표선면 표선동서로 240-1',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 표선면 표선리 631-1',
            x: 126.8325934,
            y: 33.3253116,
            delivery_req: '',
            article: '노트북',
            quantity: 2,
          },
          {
            id: 94,
            order_num: '20231201-94',
            order_name: '서홍식',
            order_contact: '010-4424-8664',
            reciever: '김혜윤',
            reciever_contact: '010-8000-9377',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '남원읍',
            recieve_address_1: '제주특별자치도 서귀포시 남원읍 태위로 684',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 남원읍 남원리 99-3',
            x: 126.7196449,
            y: 33.27915496,
            delivery_req: '',
            article: '가방',
            quantity: 7,
          },
          {
            id: 95,
            order_num: '20231201-95',
            order_name: '오윤재',
            order_contact: '010-2743-5775',
            reciever: '최수아',
            reciever_contact: '010-3110-4747',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '남원읍',
            recieve_address_1: '제주특별자치도 서귀포시 남원읍 태위로724번길 4',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 남원읍 남원리 47-1',
            x: 126.7235569,
            y: 33.28008582,
            delivery_req: '',
            article: '책상',
            quantity: 2,
          },
          {
            id: 96,
            order_num: '20231201-96',
            order_name: '정민석',
            order_contact: '010-5362-3696',
            reciever: '허유미',
            reciever_contact: '010-2041-4482',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '남원읍',
            recieve_address_1: '제주특별자치도 서귀포시 남원읍 수망리 218-1',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 남원읍 수망리 218-1',
            x: 126.7129512,
            y: 33.32438766,
            delivery_req: '',
            article: '과일',
            quantity: 1,
          },
          {
            id: 97,
            order_num: '20231201-97',
            order_name: '임유정',
            order_contact: '010-3359-8912',
            reciever: '임선혜',
            reciever_contact: '010-8627-1744',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '남원읍',
            recieve_address_1: '제주특별자치도 서귀포시 남원읍 태위로215번길 2',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 남원읍 위미리 1591-1',
            x: 126.6706448,
            y: 33.27500496,
            delivery_req: '',
            article: '가전제품',
            quantity: 5,
          },
          {
            id: 98,
            order_num: '20231201-98',
            order_name: '오현숙',
            order_contact: '010-9075-5303',
            reciever: '추은빈',
            reciever_contact: '010-3349-2706',
            add_contact: '010-7636-1857',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '남원읍',
            recieve_address_1: '제주특별자치도 서귀포시 남원읍 원님로 1-12',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 남원읍 의귀리 755',
            x: 126.7180034,
            y: 33.31328957,
            delivery_req: '',
            article: '옷',
            quantity: 11,
          },
          {
            id: 99,
            order_num: '20231201-99',
            order_name: '허기현',
            order_contact: '010-6872-1526',
            reciever: '손민용',
            reciever_contact: '010-6273-1340',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '남원읍',
            recieve_address_1: '제주특별자치도 서귀포시 남원읍 남조로 264-1',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 남원읍 의귀리 971-10',
            x: 126.7195183,
            y: 33.30278037,
            delivery_req: '',
            article: '애견용품',
            quantity: 14,
          },
          {
            id: 100,
            order_num: '20231201-100',
            order_name: '설문혜',
            order_contact: '010-8556-0291',
            reciever: '강우일',
            reciever_contact: '010-6535-5095',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '남원읍',
            recieve_address_1: '제주특별자치도 서귀포시 남원읍 516로 918',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 남원읍 하례리 1876-1',
            x: 126.6010365,
            y: 33.32572976,
            delivery_req: '',
            article: '음향기기',
            quantity: 3,
          },
        ],
      },
      {
        no: '1-3',
        sigunguName: '제주 (서)',
        orderCount: 22,
        quantityCount: 193,
        dong: [
          {
            dongName: '애월읍',
            orderCount: 6,
            quantityCount: 49,
          },
          {
            dongName: '한림읍',
            orderCount: 4,
            quantityCount: 43,
          },
          {
            dongName: '한경면',
            orderCount: 2,
            quantityCount: 6,
          },
          {
            dongName: '대정읍',
            orderCount: 4,
            quantityCount: 24,
          },
          {
            dongName: '안덕면',
            orderCount: 6,
            quantityCount: 71,
          },
        ],
        groupOrderInfo: [
          {
            id: 30,
            order_num: '20231201-35',
            order_name: '심영훈',
            order_contact: '010-8804-0081',
            reciever: '송지우',
            reciever_contact: '010-7636-1857',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 하귀로 56',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 하귀2리 1879-2',
            x: 126.4033069,
            y: 33.48028194,
            delivery_req: '',
            article: '음향기기',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 31,
            order_num: '20231201-31',
            order_name: '강창진',
            order_contact: '010-9376-8598',
            reciever: '오경희',
            reciever_contact: '010-7738-8923',
            add_contact: '010-9910-1309',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 고성서3길 15-5',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 고성리 1488-1',
            x: 126.4107586,
            y: 33.46598232,
            delivery_req: '',
            article: '과일',
            quantity: 3,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 32,
            order_num: '20231201-32',
            order_name: '전서우',
            order_contact: '010-3956-1701',
            reciever: '허기현',
            reciever_contact: '010-3405-9385',
            add_contact: '010-7426-8590',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 애조로 405',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 광령리 3672-1',
            x: 126.4264867,
            y: 33.47320957,
            delivery_req: '',
            article: '가전제품',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 33,
            order_num: '20231201-34',
            order_name: '김태주',
            order_contact: '010-5685-3516',
            reciever: '송윤미',
            reciever_contact: '010-4902-4995',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 유수암평화길 14-3',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 유수암리 1894',
            x: 126.4027879,
            y: 33.44113598,
            delivery_req: '',
            article: '애견용품',
            quantity: 11,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 34,
            order_num: '20231201-33',
            order_name: '하철민',
            order_contact: '010-6273-1340',
            reciever: '설대영',
            reciever_contact: '010-4424-8664',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 소길리 154',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 애월읍 소길리 154',
            x: 126.3895793,
            y: 33.41937209,
            delivery_req: '',
            article: '옷',
            quantity: 9,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 35,
            order_num: '20231201-30',
            order_name: '박은미',
            order_contact: '010-9534-3263',
            reciever: '김태주',
            reciever_contact: '010-8917-5951',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '애월읍',
            recieve_address_1: '제주특별자치도 제주시 애월읍 신엄안3길 99',
            recieve_address_2: '애월차이나',
            jibun: '제주특별자치도 제주시 애월읍 고내리 11-6',
            x: 126.357377,
            y: 33.47229128,
            delivery_req: '빠른배송부탁드립니다',
            article: '책상',
            quantity: 2,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 36,
            order_num: '20231201-36',
            order_name: '유승환',
            order_contact: '010-6142-3094',
            reciever: '손하현',
            reciever_contact: '010-3195-2873',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한림읍',
            recieve_address_1: '제주특별자치도 제주시 한림읍 귀덕14길 14-5',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한림읍 귀덕리 1157',
            x: 126.2934545,
            y: 33.44241942,
            delivery_req: '',
            article: '태블릿',
            quantity: 5,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 37,
            order_num: '20231201-37',
            order_name: '예은용',
            order_contact: '010-7738-8923',
            reciever: '고희수',
            reciever_contact: '010-1352-7413',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한림읍',
            recieve_address_1: '제주특별자치도 제주시 한림읍 한수풀로 146',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한림읍 대림리 431',
            x: 126.2769396,
            y: 33.42151129,
            delivery_req: '',
            article: '서류',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 38,
            order_num: '20231201-38',
            order_name: '한창민',
            order_contact: '010-3110-4747',
            reciever: '안석주',
            reciever_contact: '010-8395-2988',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한림읍',
            recieve_address_1: '제주특별자치도 제주시 한림읍 강구로 1',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한림읍 한림리 968',
            x: 126.2690672,
            y: 33.4120918,
            delivery_req: '',
            article: '신발',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 39,
            order_num: '20231201-39',
            order_name: '성동훈',
            order_contact: '010-1396-1467',
            reciever: '이용욱',
            reciever_contact: '010-7630-6133',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한림읍',
            recieve_address_1: '제주특별자치도 제주시 한림읍 한림로 329-10',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한림읍 협재리 2447',
            x: 126.2391566,
            y: 33.39386651,
            delivery_req: '',
            article: '안경',
            quantity: 14,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 40,
            order_num: '20231201-41',
            order_name: '강해빈',
            order_contact: '010-7243-1286',
            reciever: '권윤미',
            reciever_contact: '010-7958-7628',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한경면',
            recieve_address_1: '제주특별자치도 제주시 한경면 신한로 76',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한경면 신창리 18-2',
            x: 126.190287,
            y: 33.34664181,
            delivery_req: '',
            article: '노트북',
            quantity: 4,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 41,
            order_num: '20231201-40',
            order_name: '안원기',
            order_contact: '010-2041-4482',
            reciever: '복영수',
            reciever_contact: '010-7426-8590',
            sido: '제주특별자치도',
            sigungu: '제주시',
            dong: '한경면',
            recieve_address_1: '제주특별자치도 제주시 한경면 신한로 176-6',
            recieve_address_2: '',
            jibun: '제주특별자치도 제주시 한경면 두모리 1830-2',
            x: 126.194196,
            y: 33.33941204,
            delivery_req: '',
            article: '핸드폰',
            quantity: 2,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 42,
            order_num: '20231201-43',
            order_name: '유원진',
            order_contact: '010-6270-3794',
            reciever: '심영훈',
            reciever_contact: '010-5071-7664',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '대정읍',
            recieve_address_1: '제주특별자치도 서귀포시 대정읍 에듀시티로 148',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 대정읍 보성리 2424',
            x: 126.2752703,
            y: 33.2846186,
            delivery_req: '',
            article: '책상',
            quantity: 7,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 43,
            order_num: '20231201-50',
            order_name: '정석환',
            order_contact: '010-7759-5453',
            reciever: '홍은정',
            reciever_contact: '010-1372-6873',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 녹차분재로 81',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 서광리 1395',
            x: 126.2986303,
            y: 33.28978228,
            delivery_req: '',
            article: '서류',
            quantity: 15,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 44,
            order_num: '20231201-44',
            order_name: '김현수',
            order_contact: '010-8627-1744',
            reciever: '심성원',
            reciever_contact: '010-6142-3094',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '대정읍',
            recieve_address_1:
              '제주특별자치도 서귀포시 대정읍 글로벌에듀로 382',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 대정읍 보성리 2476-13',
            x: 126.2771664,
            y: 33.28016132,
            delivery_req: '',
            article: '과일',
            quantity: 3,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 45,
            order_num: '20231201-45',
            order_name: '박태지',
            order_contact: '010-1563-8275',
            reciever: '민규원',
            reciever_contact: '010-8343-3499',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '대정읍',
            recieve_address_1: '제주특별자치도 서귀포시 대정읍 신영로 120',
            recieve_address_2: '1층',
            jibun: '제주특별자치도 서귀포시 대정읍 하모리 820-15',
            x: 126.25462,
            y: 33.22247165,
            delivery_req: '고가제품입니다 배송에 유의해주세요.',
            article: '가전제품',
            quantity: 5,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 46,
            order_num: '20231201-42',
            order_name: '권광현',
            order_contact: '010-6285-2405',
            reciever: '임유정',
            reciever_contact: '010-4453-5070',
            add_contact: '010-6886-4339',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '대정읍',
            recieve_address_1:
              '제주특별자치도 서귀포시 대정읍 하모항구로10번길 23',
            recieve_address_2: '103호',
            jibun: '제주특별자치도 서귀포시 대정읍 하모리 2128',
            x: 126.2510504,
            y: 33.21829891,
            delivery_req: '긴급배송입니다.',
            article: '가방',
            quantity: 9,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 47, // 17
            order_num: '20231201-47',
            order_name: '손지희',
            order_contact: '010-9002-2357',
            reciever: '최도윤',
            reciever_contact: '010-5944-1765',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 사계남로 89',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 사계리 2172-1',
            x: 126.2993738,
            y: 33.22749046,
            delivery_req: '',
            article: '애견용품',
            quantity: 15,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 48,
            order_num: '20231201-51',
            order_name: '조하영',
            order_contact: '010-4111-3755',
            reciever: '황광호',
            reciever_contact: '010-3070-5803',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 화순로 105',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 화순리 1075-3',
            x: 126.3351455,
            y: 33.24649634,
            delivery_req: '',
            article: '신발',
            quantity: 8,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 49,
            order_num: '20231201-46',
            order_name: '복영수',
            order_contact: '010-6744-9885',
            reciever: '강만석',
            reciever_contact: '010-6001-6176',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 감산중로 5',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 감산리 391-1',
            x: 126.3521162,
            y: 33.26016804,
            delivery_req: '',
            article: '옷',
            quantity: 15,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 50,
            order_num: '20231201-48',
            order_name: '추인혁',
            order_contact: '010-6535-5095',
            reciever: '설수근',
            reciever_contact: '010-5685-3516',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1:
              '제주특별자치도 서귀포시 안덕면 중산간서로1615번길 258-5',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 상창리 2466-2',
            x: 126.3492974,
            y: 33.26276693,
            delivery_req: '',
            article: '음향기기',
            quantity: 6,
            isDelivery: false,
            imageUrl: '',
          },
          {
            id: 51,
            order_num: '20231201-49',
            order_name: '유연웅',
            order_contact: '010-7426-8590',
            reciever: '박태지',
            reciever_contact: '010-8151-1689',
            add_contact: '010-8804-0081',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '안덕면',
            recieve_address_1: '제주특별자치도 서귀포시 안덕면 산록남로 863',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 안덕면 상천리 산 62-3',
            x: 126.3885872,
            y: 33.31528848,
            delivery_req: '',
            article: '태블릿',
            quantity: 12,
            isDelivery: false,
            imageUrl: '',
          },
        ],
      },
      {
        no: '1-4',
        sigunguName: '제주 (남)',
        orderCount: 22,
        quantityCount: 170,
        dong: [
          {
            dongName: '중문동',
            orderCount: 6,
            quantityCount: 45,
          },
          {
            dongName: '중앙동',
            orderCount: 4,
            quantityCount: 29,
          },
          {
            dongName: '서홍동',
            orderCount: 4,
            quantityCount: 31,
          },
          {
            dongName: '동홍동',
            orderCount: 8,
            quantityCount: 65,
          },
        ],
        groupOrderInfo: [
          {
            id: 52,
            order_num: '20231201-52',
            order_name: '복준하',
            order_contact: '010-7326-9997',
            reciever: '조진영',
            reciever_contact: '010-4824-0445',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중문동',
            recieve_address_1: '제주특별자치도 서귀포시 천제연로 183-1',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중문동 2083-2',
            x: 126.4242919,
            y: 33.25184157,
            delivery_req: '',
            article: '안경',
            quantity: 8,
          },
          {
            id: 53,
            order_num: '20231201-53',
            order_name: '배창진',
            order_contact: '010-1448-5435',
            reciever: '이현욱',
            reciever_contact: '010-4196-2567',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중문동',
            recieve_address_1: '제주특별자치도 서귀포시 중문로 72',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중문동 1432-2',
            x: 126.4267614,
            y: 33.25748691,
            delivery_req: '',
            article: '핸드폰',
            quantity: 5,
          },
          {
            id: 54,
            order_num: '20231201-54',
            order_name: '배주아',
            order_contact: '010-3390-4447',
            reciever: '권만수',
            reciever_contact: '010-3548-0857',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중문동',
            recieve_address_1: '제주특별자치도 서귀포시 천제연로 169',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중문동 2114-2',
            x: 126.4227835,
            y: 33.25205517,
            delivery_req: '',
            article: '노트북',
            quantity: 2,
          },
          {
            id: 55,
            order_num: '20231201-55',
            order_name: '허지석',
            order_contact: '010-7289-3123',
            reciever: '예형남',
            reciever_contact: '010-6285-2405',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중문동',
            recieve_address_1: '제주특별자치도 서귀포시 천제연로178번길 14-2',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중문동 2140-16',
            x: 126.4232157,
            y: 33.25086595,
            delivery_req: '',
            article: '가방',
            quantity: 5,
          },
          {
            id: 56,
            order_num: '20231201-56',
            order_name: '탁한빈',
            order_contact: '010-9204-0588',
            reciever: '김경아',
            reciever_contact: '010-1245-4380',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중문동',
            recieve_address_1: '제주특별자치도 서귀포시 천제연로 144',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중문동 2180',
            x: 126.420438,
            y: 33.25276249,
            delivery_req: '',
            article: '책상',
            quantity: 15,
          },
          {
            id: 57,
            order_num: '20231201-57',
            order_name: '허유미',
            order_contact: '010-5488-9194',
            reciever: '고경준',
            reciever_contact: '010-6744-9885',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중문동',
            recieve_address_1: '제주특별자치도 서귀포시 대포로 32-6',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중문동 2456-3',
            x: 126.4313816,
            y: 33.24706039,
            delivery_req: '',
            article: '과일',
            quantity: 10,
          },
          {
            id: 58,
            order_num: '20231201-58',
            order_name: '예미진',
            order_contact: '010-1372-6873',
            reciever: '탁한빈',
            reciever_contact: '010-1485-0190',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중앙동',
            recieve_address_1: '제주특별자치도 서귀포시 중정로 117',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중앙동 251-11',
            x: 126.5688741,
            y: 33.24875333,
            delivery_req: '',
            article: '가전제품',
            quantity: 11,
          },
          {
            id: 59,
            order_num: '20231201-59',
            order_name: '송지우',
            order_contact: '010-4196-2567',
            reciever: '추인혁',
            reciever_contact: '010-7759-5453',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중앙동',
            recieve_address_1: '제주특별자치도 서귀포시 중앙로 70-1',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중앙동 290-5',
            x: 126.5615023,
            y: 33.25088057,
            delivery_req: '',
            article: '옷',
            quantity: 2,
          },
          {
            id: 60,
            order_num: '20231201-60',
            order_name: '이수아',
            order_contact: '010-9801-3683',
            reciever: '윤효원',
            reciever_contact: '010-1914-8082',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중앙동',
            recieve_address_1: '제주특별자치도 서귀포시 중앙로 92',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중앙동 284-3',
            x: 126.5615872,
            y: 33.25276707,
            delivery_req: '',
            article: '애견용품',
            quantity: 5,
          },
          {
            id: 61,
            order_num: '20231201-61',
            order_name: '홍광훈',
            order_contact: '010-6968-4414',
            reciever: '유윤준',
            reciever_contact: '010-5837-6039',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '중앙동',
            recieve_address_1: '제주특별자치도 서귀포시 동문로 12',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 중앙동 286-4',
            x: 126.5624378,
            y: 33.25181898,
            delivery_req: '',
            article: '음향기기',
            quantity: 11,
          },
          {
            id: 62,
            order_num: '20231201-62',
            order_name: '강희우',
            order_contact: '010-4902-4995',
            reciever: '강창진',
            reciever_contact: '010-8022-0926',
            add_contact: '010-2583-3205',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '서홍동',
            recieve_address_1: '제주특별자치도 서귀포시 중앙로101번길 7',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 서홍동 441-5',
            x: 126.5601371,
            y: 33.25352568,
            delivery_req: '',
            article: '태블릿',
            quantity: 1,
          },
          {
            id: 63,
            order_num: '20231201-63',
            order_name: '양재아',
            order_contact: '010-2513-5297',
            reciever: '봉승기',
            reciever_contact: '010-1396-1467',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '서홍동',
            recieve_address_1: '제주특별자치도 서귀포시 홍중로 51',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 서홍동 350-3',
            x: 126.5568688,
            y: 33.25725764,
            delivery_req: '',
            article: '서류',
            quantity: 11,
          },
          {
            id: 64,
            order_num: '20231201-64',
            order_name: '심성원',
            order_contact: '010-9210-8551',
            reciever: '유원진',
            reciever_contact: '010-6968-4414',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '서홍동',
            recieve_address_1: '제주특별자치도 서귀포시 중앙로 153',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 서홍동 314-23',
            x: 126.5599161,
            y: 33.2582351,
            delivery_req: '',
            article: '신발',
            quantity: 11,
          },
          {
            id: 65,
            order_num: '20231201-65',
            order_name: '오형수',
            order_contact: '010-1851-7664',
            reciever: '설문혜',
            reciever_contact: '010-6855-4970',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '서홍동',
            recieve_address_1: '제주특별자치도 서귀포시 일주동로 8702',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 서홍동 442-10',
            x: 126.5586096,
            y: 33.2529966,
            delivery_req: '',
            article: '안경',
            quantity: 8,
          },
          {
            id: 66,
            order_num: '20231201-66',
            order_name: '이현욱',
            order_contact: '010-3070-5803',
            reciever: '권재원',
            reciever_contact: '010-9002-2357',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '동홍동',
            recieve_address_1: '제주특별자치도 서귀포시 중산간동로 8002',
            recieve_address_2: '2층',
            jibun: '제주특별자치도 서귀포시 동홍동 1674',
            x: 126.5617549,
            y: 33.26557795,
            delivery_req: '오시기전에 연락주세요',
            article: '핸드폰',
            quantity: 6,
          },
          {
            id: 67,
            order_num: '20231201-67',
            order_name: '임선혜',
            order_contact: '010-3195-2873',
            reciever: '윤범호',
            reciever_contact: '010-6886-4339',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '동홍동',
            recieve_address_1: '제주특별자치도 서귀포시 동홍로 56',
            recieve_address_2: '3층',
            jibun: '제주특별자치도 서귀포시 동홍동 441-1',
            x: 126.5680981,
            y: 33.25378532,
            delivery_req: '오시기전에 연락주세요',
            article: '노트북',
            quantity: 10,
          },
          {
            id: 68,
            order_num: '20231201-68',
            order_name: '정주아',
            order_contact: '010-3828-6343',
            reciever: '강해빈',
            reciever_contact: '010-6825-1686',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '동홍동',
            recieve_address_1: '제주특별자치도 서귀포시 일주동로 8664',
            recieve_address_2: '해송초밥',
            jibun: '제주특별자치도 서귀포시 동홍동 1542-9',
            x: 126.5626513,
            y: 33.25371934,
            delivery_req: '',
            article: '가방',
            quantity: 10,
          },
          {
            id: 69,
            order_num: '20231201-69',
            order_name: '권재원',
            order_contact: '010-6855-4970',
            reciever: '전홍준',
            reciever_contact: '010-9534-3263',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '동홍동',
            recieve_address_1: '제주특별자치도 서귀포시 동홍북로48번길 10',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 동홍동 82-1',
            x: 126.5731587,
            y: 33.2582992,
            delivery_req: '',
            article: '책상',
            quantity: 8,
          },
          {
            id: 70,
            order_num: '20231201-70',
            order_name: '강우일',
            order_contact: '010-2529-7634',
            reciever: '손지희',
            reciever_contact: '010-9801-3683',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '동홍동',
            recieve_address_1: '제주특별자치도 서귀포시 태평로 509',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 동홍동 179',
            x: 126.5717664,
            y: 33.25068466,
            delivery_req: '',
            article: '과일',
            quantity: 5,
          },
          {
            id: 71,
            order_num: '20231201-71',
            order_name: '손민용',
            order_contact: '010-6125-3249',
            reciever: '표명숙',
            reciever_contact: '010-5178-9746',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '동홍동',
            recieve_address_1: '제주특별자치도 서귀포시 동홍북로 4',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 동홍동 588-2',
            x: 126.5687037,
            y: 33.25901054,
            delivery_req: '',
            article: '가전제품',
            quantity: 11,
          },
          {
            id: 72,
            order_num: '20231201-72',
            order_name: '김경아',
            order_contact: '010-7526-9378',
            reciever: '홍광훈',
            reciever_contact: '010-3359-8912',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '동홍동',
            recieve_address_1: '제주특별자치도 서귀포시 일주동로 8600',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 동홍동 482-17',
            x: 126.5693845,
            y: 33.25494908,
            delivery_req: '',
            article: '옷',
            quantity: 7,
          },
          {
            id: 73,
            order_num: '20231201-73',
            order_name: '권다영',
            order_contact: '010-7630-6133',
            reciever: '풍연석',
            reciever_contact: '010-2261-6922',
            sido: '제주특별자치도',
            sigungu: '서귀포시',
            dong: '동홍동',
            recieve_address_1: '제주특별자치도 서귀포시 동홍로162번길 3',
            recieve_address_2: '',
            jibun: '제주특별자치도 서귀포시 동홍동 1183',
            x: 126.5678161,
            y: 33.26299473,
            delivery_req: '',
            article: '애견용품',
            quantity: 8,
          },
        ],
      },
    ],
  },
];
