/**
 * @file: HistoryDetailTableList.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.22 ~
 * @description: 접수 내역 상세 테이블 리스트 컴포넌트
 */

import Badge from '@components/atom/Badge';
import { IRouteExcelData } from '@data/routeExcelUploadData';
import classNames from 'classnames';
import dayjs from 'dayjs';

interface IProps {
  className?: string;
  index?: string | number;
  list: IRouteExcelData | undefined;
}

export default function HistoryDetailTableList({
  index,
  list,
  className,
}: IProps) {
  return (
    <div
      className={classNames(
        'flex py-1 items-center text-xs bg-white',
        className,
      )}
    >
      <div className="px-2 py-1 w-[96px]">{index}</div>
      <div className="px-2 py-1 w-[280px]">{list?.order_num}</div>
      <div className="px-2 py-1 w-[96px]">
        <Badge useDeliveryStatus status={list?.status} />
      </div>
      <div className="px-2 py-1 w-[140px]">{list?.order_name}</div>
      <div className="px-2 py-1 w-[140px]">{list?.order_contact}</div>
      <div className="px-2 py-1 w-[140px]">{list?.reciever}</div>
      <div className="px-2 py-1 w-[140px]">{list?.reciever_contact}</div>
      <div className="px-2 py-1 w-[140px]">{list?.add_contact}</div>
      <div className="px-2 py-1 w-[280px]">{list?.recieve_address_1}</div>
      <div className="px-2 py-1 w-[280px]">{list?.recieve_address_2}</div>
      <div className="px-2 py-1 w-[280px]">{list?.delivery_req}</div>
      <div className="px-2 py-1 w-[280px]">{list?.article}</div>
      <div className="px-2 py-1 w-[96px]">{list?.quantity}</div>
      <div className="px-2 py-1 w-[140px]">
        {list?.completed_at
          ? dayjs(list?.completed_at).format('YYYY-MM-DD')
          : '-'}
      </div>
    </div>
  );
}
