import { useSetRecoilState } from 'recoil';
import { modalAtom } from '@recoil/modal';
import { IModalProps } from '@components/organisms/modal/Modal';

export function useModal() {
  const setModal = useSetRecoilState(modalAtom);

  const showModal = (modalProps: IModalProps) => {
    setModal(modalProps);
  };

  const hideModal = () => {
    setModal(null);
  };
  return {
    showModal,
    hideModal,
  };
}
