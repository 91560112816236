import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '@hooks/recoil/useUserInfo';
import { userInfoData } from '@data/userInfoData';
import { SNB_ROUTE_LIST } from '@constants/navigation';
import { useModal } from '@hooks/useModal';

import Input from '@components/molecules/Input';
import Button from '@components/atom/Button';
// import ServiceShortcut from '@components/molecules/ServiceShortcut';

// import toodeeLogo from '@assets/images/toodee_newLogo.png';
// import unknown from '@assets/images/unknown.png';

export default function Login() {
  const navigate = useNavigate();

  const { setUserInfo } = useUserInfo();
  const { showModal } = useModal();

  const [inputLoginInfo, setInputLoginInfo] = useState({
    email: '',
    password: '',
  });

  /** input 입력 */
  const onChangeLoginInput = (e: any) => {
    const { value, name } = e.target;

    setInputLoginInfo({
      ...inputLoginInfo,
      [name]: value,
    });
  };

  const loginEvent = () => {
    navigate('/route-optimization', { state: SNB_ROUTE_LIST.ROUTE.CODE });
    setUserInfo(userInfoData);
  };

  /** 로그인 버튼 클릭 */
  const onClickLoginBtn = () => {
    if (!inputLoginInfo.email || !inputLoginInfo.password) {
      showModal({
        title: '계정정보를 입력해주세요!',
        message: '로그인 정보가 필요해요',
      });
      return;
    }

    if (
      inputLoginInfo.email !== userInfoData.email ||
      inputLoginInfo.password !== userInfoData.password
    ) {
      showModal({
        title: '계정정보를 다시 확인해주세요!',
        message: '이메일 또는 비밀번호를 다시 확인해주세요',
      });
      return;
    }

    loginEvent();
  };

  const enterKeyByLogin = (e: any) => {
    if (e.key !== 'Enter') return;
    else onClickLoginBtn();
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <h1 className="font-bold text-center mb-24 text-5xl">루트플로우</h1>
        <Input
          className="mb-4 py-2"
          name="email"
          label="이메일"
          placholder="example@example.com"
          value={inputLoginInfo.email}
          onChange={onChangeLoginInput}
          onKeyDown={enterKeyByLogin}
        />
        <Input
          className="py-2"
          name="password"
          type="password"
          label="비밀번호"
          placholder="영어, 숫자, 특수문자 포함 8자 이상"
          value={inputLoginInfo.password}
          onChange={onChangeLoginInput}
          onKeyDown={enterKeyByLogin}
        />
        <Button
          text={'로그인'}
          buttonClassName="my-8 w-full bg-primary-01 text-white h-[56px]"
          onClick={onClickLoginBtn}
          onKeyDown={enterKeyByLogin}
        />
        {/* <div className="flex justify-between font-bold pb-3">
          <Link to="/">회원가입</Link>
          <Link to="/" className="text-secondary-01">
            아이디/비밀번호 찾기
          </Link>
        </div> */}
      </div>

      {/* <div>
        <p className="font-bold text-sm pb-4">이런 서비스도 제공하고 있어요!</p>
        <ServiceShortcut
          src={toodeeLogo}
          serviceTitle="오늘의 배송 투디"
          serviceSubtitle="퀵 접수부터 택배접수까지 한번에!"
          link="/"
        />
        <ServiceShortcut
          src={unknown}
          serviceTitle="카고링스"
          serviceSubtitle="화물 오더 접수부터 운송까지 한번에!"
          link="/"
        />
      </div> */}
    </div>
  );
}
