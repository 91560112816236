import { atom } from 'recoil';
import { IRouteExcelData } from '@data/routeExcelUploadData';
import { RECOIL_KEY } from '@constants/recoilKeys';

/** 업로드한 엑셀 데이터 저장소 */
export const routeOrderListAtom = atom<IRouteExcelData[]>({
  key: RECOIL_KEY.ROUTE_ORDER_LIST_KEY,
  default: [],
});

/** 그룹화한 엑셀 데이터 저장소 */
export const routeOrderGroupAtom = atom({
  key: RECOIL_KEY.ROUTE_GROUP_LIST_KEY,
  default: [],
});

/** 선택한 동 리스트 */
export const routeSelectedListAtom = atom<string[]>({
  key: RECOIL_KEY.ROUTE_SELECTED_LIST,
  default: [],
});

/** 동 전체 선택 */
export const routeAllSelectedAtom = atom<boolean>({
  key: RECOIL_KEY.ROUTE_ALL_SELECTED,
  default: false,
});

/** 경로 최적화 준비 (경로 최적화 버튼 클릭 시) */
export const readyToCreateRoute = atom<boolean>({
  key: RECOIL_KEY.READY_TO_CREATE_ROUTE,
  default: false,
});

/** 경로 최적화 완료 */
export const completeOptimization = atom<boolean>({
  key: RECOIL_KEY.COMPLETE_OPTIMIZATION,
  default: false,
});
