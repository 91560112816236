import { VEHICLE_STATUS } from '@constants/common';
import { IRiderVehicleInfo } from '@interfaces/info.interface';

export const vehicleInfoData: IRiderVehicleInfo[] = [
  {
    id: 1,
    value: '92무 5784',
    name: '윙바디 1호기',
    status: VEHICLE_STATUS.DELIVERY,
    number: '92무 5784',
    memo: '냉동 / 냉장 / 덮개 있음',
  },
  {
    id: 2,
    value: '91배 6255',
    name: '탑차 3호기',
    status: VEHICLE_STATUS.DELIVERY,
    number: '91배 6255',
    memo: '언덕에서 좀 힘듦',
  },
  {
    id: 3,
    value: '82바 9786',
    name: '제일빠른포르쉐',
    status: VEHICLE_STATUS.WAIT,
    number: '82바 9786',
    memo: null,
  },
  {
    id: 4,
    value: '97배 5545',
    name: '람보르기니',
    status: VEHICLE_STATUS.WAIT,
    number: '97배 5545',
    memo: null,
  },
] as const;
