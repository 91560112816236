/**
 * @file CreateRouteInfoList
 * @author chad / chad@o2pluss.com
 * @description 경로최적화 경로 생성 정보 테이블 리스트
 */

import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { IRouteExcelData } from '@data/routeExcelUploadData';
import { ISelectedRouteInfo } from '@components/organisms/RouteCreateWithMap';

import Button from '@components/atom/Button';
import AssignSelect, {
  ISelectedValue,
} from '@components/organisms/modal/AssignSelect';
import CreateRouteInfoDetail from '@components/organisms/modal/CreateRouteInfoDetail';
import RouteListUpdate from '@components/organisms/modal/RouteListUpdate';
import { useModal } from '@hooks/useModal';

interface IProps {
  index: string | number;
  no: string | number;
  routeName: string;
  assignRider: string;
  assignVehicle: string;
  groupOrderInfo: IRouteExcelData[];
  selectedRouteList: ISelectedRouteInfo[];
  setSelectedRouteList: Dispatch<SetStateAction<ISelectedRouteInfo[]>>;
}

export default function CreateRouteInfoList({
  index,
  no,
  routeName,
  assignRider,
  selectedRouteList,
  setSelectedRouteList,
  assignVehicle,
}: IProps) {
  const { showModal } = useModal();

  const [isOpenAssignRider, setIsOpenAssignRide] = useState<boolean>(false);
  const [isOpenAssignVehicle, setIsOpenAssignVehicle] =
    useState<boolean>(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState<boolean>(false);
  const [isOpenRouteInfoDetail, setIsOpenRouteInfoDetail] =
    useState<boolean>(false);

  const [targetIndex, setTargetIndex] = useState<number | null>(Number(index));
  const [targetInfo, setTargetInfo] = useState<ISelectedRouteInfo>();

  /** 타겟 인덱스 가져오기 */
  const getTarget = (e: MouseEvent<HTMLButtonElement> | undefined) => {
    setTargetIndex(Number(e?.currentTarget.dataset.id));
  };

  /** 담당 기사, 차량번호 배정 */
  const changeAssignValue = (value: ISelectedValue) => {
    const setChangeValue: any = selectedRouteList.map((list, index) => {
      if (value.type === 'rider' && index === targetIndex) {
        return {
          ...list,
          assignRider: value.value,
        };
      } else if (value.type === 'vehicle' && index === targetIndex) {
        return {
          ...list,
          assignVehicle: value.value,
        };
      } else {
        return {
          ...list,
        };
      }
    });

    setSelectedRouteList(setChangeValue);
  };

  /** 경로 삭제 */
  const deleteRouteList = useCallback(() => {
    showModal({
      type: 'confirm',
      title: '경로 삭제',
      message: '경로를 삭제 하시겠습니까?',
      onOk: () => {
        const deleteItem = selectedRouteList.filter((list, index) => {
          return index !== targetIndex;
        });

        setSelectedRouteList(deleteItem);
      },
    });
  }, [selectedRouteList, setSelectedRouteList, showModal, targetIndex]);

  /** 상세정보 데이터 */
  const detailInfo = useCallback(() => {
    const detailInfoItem = selectedRouteList.filter(
      (list, index) => index === targetIndex,
    );

    setTargetInfo(detailInfoItem[0]);
    setIsOpenRouteInfoDetail(true);
  }, [selectedRouteList, targetIndex]);

  return (
    <div className="flex items-center py-1 px-3">
      <span className="px-2 text-xs w-[10%]">{no}</span>
      <span className="px-2 text-xs w-[40%]">{routeName}</span>

      <span className="px-2 text-xs w-[15%]">
        {assignRider || (
          <Button
            setData={index}
            buttonClassName="bg-primary-01 text-white h-[28px]"
            text="기사 배정"
            smallPadding
            bold={false}
            onClick={(e) => {
              setIsOpenAssignRide(true);
              getTarget(e);
            }}
          />
        )}
      </span>

      <span className="px-2 text-xs w-[15%]">
        {assignVehicle || (
          <Button
            setData={index}
            buttonClassName="bg-primary-01 text-white h-[28px]"
            text="차량 배정"
            smallPadding
            bold={false}
            onClick={(e) => {
              setIsOpenAssignVehicle(true);
              getTarget(e);
            }}
          />
        )}
      </span>

      <div className="w-[20%] flex justify-end">
        <Button
          setData={index}
          buttonClassName="bg-primary-01 text-white h-[28px] text-xs mr-1"
          text="수정"
          smallPadding
          bold={false}
          onClick={(e) => {
            setIsOpenUpdateModal(true);
            getTarget(e);
          }}
        />
        <Button
          setData={index}
          buttonClassName="bg-primary-01 text-white h-[28px] text-xs mr-1"
          text="삭제"
          smallPadding
          bold={false}
          onClick={(e) => {
            getTarget(e);
            deleteRouteList();
          }}
        />
        <Button
          setData={index}
          buttonClassName="text-primary-01 h-[28px] text-xs"
          text="상세정보"
          blackBorder
          smallPadding
          bold={false}
          onClick={(e) => {
            getTarget(e);
            detailInfo();
          }}
        />
      </div>

      <AssignSelect
        type="rider"
        isOpen={isOpenAssignRider}
        title="기사 배정"
        setValue={(value) => changeAssignValue(value)}
        onCancel={() => setIsOpenAssignRide(false)}
      />

      <AssignSelect
        type="vehicle"
        isOpen={isOpenAssignVehicle}
        title="차량 배정"
        setValue={(value) => changeAssignValue(value)}
        onCancel={() => setIsOpenAssignVehicle(false)}
      />

      <RouteListUpdate
        isOpen={isOpenUpdateModal}
        index={Number(index)}
        defaultValue={{ routeName, assignRider, assignVehicle }}
        selectedRouteList={selectedRouteList}
        setSelectedRouteList={setSelectedRouteList}
        closeModal={() => setIsOpenUpdateModal(false)}
      />

      {targetInfo && (
        <CreateRouteInfoDetail
          isOpen={isOpenRouteInfoDetail}
          detailInfo={targetInfo}
          closeModal={() => setIsOpenRouteInfoDetail(false)}
        />
      )}
    </div>
  );
}
