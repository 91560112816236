/**
 * @file RouteOptimization.tsx
 * @author chad / chad@o2pluss.com
 * @description 경로 최적화 페이지
 */

import { useState, useEffect } from 'react';
import { TiPlus } from 'react-icons/ti';
import { useRouteOrder, useRouteGroup } from '@hooks/recoil/useRouteOrder';
import { routeExcelData, routeGroupData } from '@data/routeExcelUploadData';

import Button from '@components/atom/Button';
import Card from '@components/atom/Card';
import TotalCount from '@components/atom/TotalCount';
import RouteTableHeader from '@components/molecules/tableHeader/RouteTableHeader';
import RouteFileUpload from '@components/organisms/modal/RouteFileUpload';
import RouteTableList from '@components/molecules/tableList/RouteTableList';
import Separator from '@components/atom/Separator';
import RouteDepartureInfo from '@components/organisms/RouteDepartureInfo';
import { useNavigate } from 'react-router-dom';

export default function RouteOptimization() {
  const { orderInfoList, setOrderInfoList } = useRouteOrder();
  const { setOrderGroupList } = useRouteGroup();

  const navigate = useNavigate();

  const [isNextBtnState, setisNextBtnState] = useState<boolean>(false);
  const [isOpenFileUpload, setIsOpenFileUpload] = useState<boolean>(false);
  const [isAddOrder, setIsAddOrder] = useState<boolean>(false);

  const onClickNextBtn = () => {
    setOrderGroupList(routeGroupData);
    navigate('create', { state: 'routeCreate' });
  };

  useEffect(() => {
    orderInfoList.length > 0
      ? setisNextBtnState(true)
      : setisNextBtnState(false);
  }, [orderInfoList]);

  useEffect(() => {
    setOrderInfoList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex items-center justify-end mb-6">
        <Button
          buttonClassName="text-primary-01 bg-white mr-3 h-[48px]"
          text="파일 업로드"
          icon={<TiPlus />}
          blackBorder
          onClick={() => setIsOpenFileUpload(true)}
        />
        <Button
          buttonClassName={`text-white h-[48px] ${
            isNextBtnState ? 'bg-primary-01' : 'bg-primary-02'
          }`}
          text="다음"
          disabled={isNextBtnState ? false : true}
          onClick={onClickNextBtn}
        />
      </div>

      <RouteDepartureInfo className="mb-8" />

      <div className="flex items-center justify-between mb-6">
        <span className="font-bold">오더 정보</span>
        <Button
          buttonClassName="text-primary-01 bg-white h-[48px]"
          text="오더 추가"
          icon={<TiPlus />}
          blackBorder
          disabled={isNextBtnState ? false : true}
          onClick={() => setIsAddOrder(true)}
        />
      </div>

      <TotalCount className="mb-4" total={orderInfoList?.length || 0} />

      <div
        className="overflow-x-scroll"
        style={{ height: 'calc(100% - 490px)' }}
      >
        <RouteTableHeader />
        {orderInfoList && orderInfoList.length > 0 ? (
          <Card cardClassName="overflow-y-scroll w-max !px-3 !py-0">
            {isAddOrder && (
              <div>
                <RouteTableList
                  isAddOrder={isAddOrder}
                  setIsAddOrder={setIsAddOrder}
                  order_num={''}
                  order_name={''}
                  order_contact={''}
                  reciever={''}
                  reciever_contact={''}
                  add_contact={''}
                  recieve_address_1={''}
                  recieve_address_2={''}
                  delivery_req={''}
                  article={''}
                  quantity={0}
                />
                <Separator />
              </div>
            )}
            {orderInfoList.map((list, index) => {
              return (
                <div key={index}>
                  <RouteTableList
                    no={String(index + 1).padStart(2, '0')}
                    id={list.id}
                    order_num={list.order_num}
                    order_name={list.order_name}
                    order_contact={list.order_contact}
                    reciever={list.reciever}
                    reciever_contact={list.reciever_contact}
                    add_contact={list.add_contact}
                    recieve_address_1={list.recieve_address_1}
                    recieve_address_2={list.recieve_address_2}
                    delivery_req={list.delivery_req}
                    article={list.article}
                    quantity={list.quantity}
                  />
                  {index + 1 < orderInfoList.length && <Separator />}
                </div>
              );
            })}
          </Card>
        ) : (
          <div
            className="flex items-center justify-center"
            style={{ height: 'calc(100% - 45px)' }}
          >
            <span className="text-sm text-tertiary">
              파일 업로드를 통해 경로를 생성해주세요
            </span>
          </div>
        )}
      </div>

      <RouteFileUpload
        isOpen={isOpenFileUpload}
        setOrderInfoList={() => setOrderInfoList(routeExcelData)}
        onCancel={() => setIsOpenFileUpload(false)}
      />
    </>
  );
}
