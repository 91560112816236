/**
 * @file RouteCreateWithMap
 * @author chad / chad@o2pluss.com
 * @description 경로최적화 경로 생성 컴포넌트
 */

import {
  useReadyToRouteCreate,
  useRouteGroup,
  useCompleteOptimization,
} from '@hooks/recoil/useRouteOrder';
import { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { IRouteExcelData } from '@data/routeExcelUploadData';
import { randomNumber } from '@utils/createRandomNum';
import { useModal } from '@hooks/useModal';
import { useNavigate } from 'react-router-dom';

import CreateRouteInfoList from '@components/molecules/tableList/CreateRouteInfoList';
import Separator from '@components/atom/Separator';
import KakaoMap from '@components/atom/KakaoMap';
import Button from '@components/atom/Button';
import Input from '@components/molecules/Input';
import { FaBox } from 'react-icons/fa';
import { MdContentPaste } from 'react-icons/md';
import CreateRouteInfoTableHeader from '@components/molecules/tableHeader/CreateRouteInfoTableHeader';

export interface ISelectedRouteInfo {
  routeName: string;
  sigunguName: string;
  orderCount: number;
  quantityCount: number;
  assignRider: string;
  assignVehicle: string;
  groupOrderInfo: IRouteExcelData[];
}

export default function RouteCreateWithMap() {
  const { isReadyToRouteCreate, setIsReadyToRouteCreate } =
    useReadyToRouteCreate();
  const { orderGroupList } = useRouteGroup();
  const { showModal } = useModal();
  const navigate = useNavigate();
  const { setIsCompleteOptimization } = useCompleteOptimization();

  /** 경로 최적화 버튼 눌러서 가져온 경로 리스트 */
  const [routeCreateList, setRouteCreateList] = useState<string[]>([]);
  /** 경로 선택해서 가져온 단일 정보 */
  const [selectedRouteInfo, setSelectedRouteInfo] =
    useState<ISelectedRouteInfo>({
      routeName: '',
      sigunguName: '',
      orderCount: 0,
      quantityCount: 0,
      assignRider: '',
      assignVehicle: '',
      groupOrderInfo: [],
    });

  /** 생성된 경로 리스트 */
  const [selectedRouteList, setSelectedRouteList] = useState<
    ISelectedRouteInfo[]
  >([]);

  /** input 값(경로명) 가져오기 */
  const onChangeInputValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setSelectedRouteInfo({
        ...selectedRouteInfo,
        [name]: value,
      });
    },
    [selectedRouteInfo],
  );

  /** 경로생성 지역 리스트 구하기 */
  const getRouteCreateList = useCallback(() => {
    const sigunguList: any[] = [];

    orderGroupList.forEach((sido: any) => {
      sido.sigungu.forEach((sigungu: any) => {
        sigunguList.push(sigungu);
      });
    });

    setRouteCreateList(sigunguList);
  }, [orderGroupList]);

  /** 경로 생성 버튼 클릭 */
  const onClickCreateRouteBtn = useCallback(() => {
    // 경로 명이 없을 경우 난수 추가
    setSelectedRouteList((prev) => [
      ...prev,
      {
        ...selectedRouteInfo,
        routeName:
          selectedRouteInfo.routeName ||
          `${selectedRouteInfo.sigunguName}_${randomNumber(1000, 9999)}`,
      },
    ]);

    setSelectedRouteInfo({
      ...selectedRouteInfo,
      routeName: '',
    });
  }, [selectedRouteInfo]);

  /**
   * 배정 안된 경로가 있는지 확인, 있으면 true 반환
   * @returns boolean
   */
  const isNotAssignValue = useCallback(() => {
    let isNotValue: boolean = false;
    selectedRouteList.forEach((list) => {
      !list.assignRider || !list.assignVehicle
        ? (isNotValue = true)
        : (isNotValue = false);
    });

    return isNotValue;
  }, [selectedRouteList]);

  /** 접수하기 버튼 클릭 */
  const onClickToReceipt = useCallback(() => {
    isNotAssignValue()
      ? showModal({
          title: '경로 정보를 확인해주세요',
          message: '기사님과 차량 배정이 되지 않은 경로가 있습니다.',
        })
      : showModal({
          title: '접수가 완료되었습니다',
          message: '접수 내역에서 접수 현황을 확인하실 수 있습니다.',
          onOk: () => {
            navigate('route-optimization');
            setIsCompleteOptimization(true);
          },
        });
  }, [isNotAssignValue, navigate, setIsCompleteOptimization, showModal]);

  useEffect(() => {
    getRouteCreateList();
  }, [getRouteCreateList]);

  useEffect(() => {
    return () => {
      setIsReadyToRouteCreate(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white w-[55%] px-12 py-8">
      <p className="font-bold mb-8">경로 생성</p>
      {isReadyToRouteCreate ? (
        routeCreateList.map((list: any, index: number) => {
          return (
            <Button
              key={index}
              buttonClassName="text-primary-01 text-sm h-[32px] mr-2"
              text={list.sigunguName}
              blackBorder
              bold={false}
              chip
              onClick={() =>
                setSelectedRouteInfo({
                  sigunguName: list.sigunguName,
                  orderCount: list.orderCount,
                  quantityCount: list.quantityCount,
                  groupOrderInfo: list.groupOrderInfo,
                  routeName: '',
                  assignRider: '',
                  assignVehicle: '',
                })
              }
            />
          );
        })
      ) : (
        <Button
          buttonClassName="text-secondary-02 text-sm h-[32px]"
          text="운송지역"
          grayBorder
          bold={false}
          chip
        />
      )}

      <div className="my-4 flex">
        <Input
          className="h-[48px]"
          name="routeName"
          value={selectedRouteInfo.routeName || ''}
          onChange={onChangeInputValue}
        />
        <Button text="경로 초기화" buttonClassName="mx-3" grayBorder />
        <Button
          text="경로 생성"
          buttonClassName={`text-white ${
            selectedRouteInfo.sigunguName ? 'bg-primary-01' : 'bg-primary-02'
          }`}
          disabled={selectedRouteInfo.sigunguName ? false : true}
          onClick={onClickCreateRouteBtn}
        />
      </div>

      {/* 지도 */}
      <div className="h-[300px] rounded-xl overflow-hidden">
        <KakaoMap
          className="relative"
          routeList={selectedRouteInfo.groupOrderInfo}
        >
          <div className="absolute flex items-center justify-center z-10 m-4 p-2 rounded-3xl overflow-hidden bg-white shadow-lg">
            <div className="flex items-center mr-3">
              <MdContentPaste className="mr-1" size="20px" color="#676767" />
              <span>{selectedRouteInfo.orderCount} 건</span>
            </div>
            <div className="flex items-center">
              <FaBox className="mr-1" size="15px" color="#676767" />
              <span>{selectedRouteInfo.quantityCount} 개</span>
            </div>
          </div>
        </KakaoMap>
      </div>

      <div className="flex justify-between items-center my-8">
        <span className="font-bold">경로 정보</span>
        <Button
          text="접수하기"
          buttonClassName={`text-white h-[48px] ${
            selectedRouteList.length > 0 ? 'bg-primary-01' : 'bg-primary-02'
          }`}
          disabled={selectedRouteList.length > 0 ? false : true}
          onClick={onClickToReceipt}
        />
      </div>

      <p>경로 총 {selectedRouteList.length} 개</p>

      <CreateRouteInfoTableHeader className="mt-4 mb-3" />

      <div
        className="overflow-y-scroll"
        style={{ height: 'calc(100% - 670px)' }}
      >
        {selectedRouteList.length ? (
          selectedRouteList.map((list, index) => {
            return (
              <div key={index} className="bg-[#FCFCFC] rounded-lg">
                <CreateRouteInfoList
                  index={index}
                  no={String(index + 1).padStart(2, '0')}
                  assignRider={list.assignRider}
                  assignVehicle={list.assignVehicle}
                  routeName={list.routeName}
                  groupOrderInfo={list.groupOrderInfo}
                  selectedRouteList={selectedRouteList}
                  setSelectedRouteList={setSelectedRouteList}
                />
                <Separator />
              </div>
            );
          })
        ) : (
          <div className="flex items-center justify-center h-full">
            <span className="text-sm text-secondary-02">
              경로를 생성해주세요.
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
