import { DELIVERY_STATUS } from '@constants/common';
import { IRiderVehicleInfo } from '@interfaces/info.interface';

export const riderInfoData: IRiderVehicleInfo[] = [
  {
    id: 1,
    value: '이찬우 9384',
    name: '이찬우',
    status: DELIVERY_STATUS.DELIVERY,
    number: '010-4853-9384',
    memo: '연락 잘되시고, 누구보다 빠르게 배송',
  },
  {
    id: 2,
    value: '김길수 4885',
    name: '김길수',
    status: DELIVERY_STATUS.WAIT,
    number: '010-3234-4885',
    memo: '성실하게 일하십니다.',
  },
  {
    id: 3,
    value: '이진명 5757',
    name: '이진명',
    status: DELIVERY_STATUS.WAIT,
    number: '010-9923-5757',
    memo: null,
  },
  {
    id: 4,
    value: '조진기 0937',
    name: '조진기',
    status: DELIVERY_STATUS.WAIT,
    number: '010-4001-0937',
    memo: null,
  },
] as const;
