/**
 * @file useGetRouterPath.tsx
 * @author chad / chad@o2pluss.com
 * @description route path를 구하기 위한 hook
 */

import { useLocation } from 'react-router-dom';

/**
 * route path를 구하기 위한 hook
 * @returns array
 */
export default function useGetRoutePath() {
  const { pathname } = useLocation();

  const path = pathname.split('/').slice(1);

  return path;
}
