/**
 * @file CreateRouteInfoTableHeader
 * @author chad / chad@o2pluss.com
 * @description 경로 최적화 경로 생성 테이블 헤더
 */

import Card from '@components/atom/Card';
import { CREATE_ROUTE_INFO_TABLE_HEADER } from '@constants/routeOptimization';
import classNames from 'classnames';

interface IProps {
  className?: string;
}

export default function CreateRouteInfoTableHeader({ className }: IProps) {
  const TABLE_HEADER: string[] = [];

  for (const value of Object.values(CREATE_ROUTE_INFO_TABLE_HEADER)) {
    TABLE_HEADER.push(value);
  }

  return (
    <Card
      cardClassName={classNames(
        'flex mb-3 !px-3 py-1 !bg-[#FCFCFC] items-center h-[36px]',
        className,
      )}
    >
      {TABLE_HEADER.map((value, index) => {
        return (
          <div
            className={`px-2 text-secondary-01 text-xs ${
              index === 0 ? 'w-[10%]' : index === 1 ? 'w-[40%]' : 'w-[15%]'
            }`}
            key={value}
          >
            {value}
          </div>
        );
      })}
    </Card>
  );
}
