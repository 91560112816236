export const RECEIPT_HISTORY_TABLE_HEADER = {
  NO: 'No',
  RECEIPT_NUM: '접수 번호',
  ROUTE_NAME: '경로명',
  ORDER_NUM: '주문 수 (건)',
  ARTICLE_NUM: '물품 수 (개)',
  IS_SHOW: '노출여부',
  DELIVERY_STATUS: '운송상태',
  RIDER_IN_CHARGE: '담당기사',
  VEHICLE_NUM: '차량번호',
  STARTED_AT: '운송 시작일',
  COMPLETE_AT: '운송 종료일',
} as const;

export type RECEIPT_HISTORY_TABLE_HEADER =
  (typeof RECEIPT_HISTORY_TABLE_HEADER)[keyof typeof RECEIPT_HISTORY_TABLE_HEADER];

export const HISTORY_DETAIL_TABLE_HEADER = {
  NO: 'No',
  ORDER_NUM: '주문번호',
  STATUS: '운송상태',
  ORDER_NAME: '주문자명',
  ORDER_CONTACT: '주문자 연락처',
  RECIEVER: '수취인',
  RECIEVER_CONTACT: '수취인 연락처',
  ADD_CONTACT: '추가 연락처',
  RECEIVE_ADDRESS_1: '수령지 주소1 (기본주소)',
  RECEIVE_ADDRESS_2: '수령지 주소2 (상세주소)',
  DELIVERY_REQ: '배송 시 요청사항',
  ARTICLE: '물품',
  QUANTITY: '수량',
  COMPLETE_AT: '운송 종료일',
} as const;

export type HISTORY_DETAIL_TABLE_HEADER =
  (typeof HISTORY_DETAIL_TABLE_HEADER)[keyof typeof HISTORY_DETAIL_TABLE_HEADER];
