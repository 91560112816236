/**
 * @file: HistoryTableHeader.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.20 ~
 * @description: 접수내역 리스트 테이블 헤더 컴포넌트
 */

import { RECEIPT_HISTORY_TABLE_HEADER } from '@constants/receiptHistory';
import classNames from 'classnames';

import Card from '@components/atom/Card';

export default function HistoryTableHeader() {
  const TABLE_HEADER: RECEIPT_HISTORY_TABLE_HEADER[] = [];
  for (const value of Object.values(RECEIPT_HISTORY_TABLE_HEADER)) {
    TABLE_HEADER.push(value);
  }

  return (
    <Card cardClassName="flex mb-3 !px-3 py-2 !bg-[#FCFCFC] w-min">
      {TABLE_HEADER.map((value, index) => (
        <div
          className={classNames(
            'px-2 text-secondary-01 text-xs',
            index === 1 || index === 9
              ? 'w-[140px]'
              : index === 10
              ? 'w-[230px]'
              : index === 2
              ? 'w-[338px]'
              : 'w-[96px]',
          )}
          key={index}
        >
          {value}
        </div>
      ))}
    </Card>
  );
}
