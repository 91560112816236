// export const USER_INFO_KEY = 'userInfoKey';
// export const ROUTE_ORDER_LIST_KEY = 'routeOrderListKey';
// export const ROUTE_GROUP_LIST_KEY = 'routeGroupListKey';
// export const ROUTE_SELECTED_LIST = 'routeSelectedList';
// export const ROUTE_ALL_SELECTED = 'routeAllSelected';

export const RECOIL_KEY = {
  USER_INFO_KEY: 'userInfoKey',
  ROUTE_ORDER_LIST_KEY: 'routeOrderListKey',
  ROUTE_GROUP_LIST_KEY: 'routeGroupListKey',
  ROUTE_SELECTED_LIST: 'routeSelectedList',
  ROUTE_ALL_SELECTED: 'routeAllSelected',
  READY_TO_CREATE_ROUTE: 'readyToCreateRoute',
  SELECTED_TRANSPORT: 'selectedTransport',
  COMPLETE_OPTIMIZATION: 'completeOptimization',
};
