/**
 * @file Button.tsx
 * @author chad / chad@o2pluss.com
 * @description 버튼 컴포넌트
 */

import React, { ButtonHTMLAttributes, MouseEvent } from 'react';
import classNames from 'classnames';

type Type = 'submit' | 'reset' | 'button';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: Type;
  text: string | React.ReactNode;
  buttonClassName?: string;
  blackBorder?: boolean;
  grayBorder?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement | boolean;
  bold?: boolean;
  chip?: boolean;
  smallPadding?: boolean;
  setData?: number | string;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
}

export default function Button({
  type = 'button',
  setData,
  buttonClassName,
  text,
  icon,
  blackBorder,
  grayBorder,
  disabled,
  bold = true,
  chip,
  smallPadding,
  onClick,
}: IProps) {
  return (
    <>
      <button
        data-id={setData}
        type={type}
        className={classNames(
          `cursor-pointer ${smallPadding ? 'px-2' : 'px-4'} 
          ${chip ? 'rounded-2xl' : 'rounded-lg'} ${bold ? 'font-bold' : ''} ${
            blackBorder ? 'border-solid border border-primary-01' : ''
          } ${grayBorder ? 'border-solid border border-caption' : ''}`,
          buttonClassName,
        )}
        disabled={disabled}
        onClick={onClick}
      >
        <div className="flex items-center justify-center whitespace-nowrap">
          {icon && <span className="mr-1">{icon}</span>}
          <span>{text}</span>
        </div>
      </button>
    </>
  );
}
