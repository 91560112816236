/**
 * @file RouteFileUpload.tsx
 * @author chad / chad@o2pluss.com
 * @description
 */

import { useCallback, useState } from 'react';
import { Oval } from 'react-loader-spinner';

import Button from '@components/atom/Button';
import ModalTemplate from '@components/templates/ModalTemplate';
import { BiSolidDownload } from 'react-icons/bi';
import DndFileUpload from '@components/molecules/DndFileUpload';

interface IProps {
  isOpen: boolean;
  setOrderInfoList: () => void;
  onCancel: () => void;
}

export default function RouteFileUpload({
  isOpen,
  setOrderInfoList,
  onCancel,
}: IProps) {
  const [spinnerState, setSpinnerState] = useState<boolean>(false);
  const [uploadFileName, setUploadFileName] = useState<string | null>('');

  /** 업로드 버튼 클릭 */
  const onClickUploadBtn = useCallback(() => {
    if (!uploadFileName) return;

    setSpinnerState(true);

    setTimeout(() => {
      setSpinnerState(false);
      onCancel();
      setUploadFileName('');
      setOrderInfoList();
    }, 1000);
  }, [onCancel, uploadFileName, setOrderInfoList]);

  return (
    <ModalTemplate isOpen={isOpen}>
      <div className="w-[500px]">
        <h6 className="font-bold text-primary-01 text-xl mb-2">경로 최적화</h6>
        <p className="text-secondary-01 mb-6">
          엑셀을 업로드해주세요. <br /> 가이드에 벗어난 파일의 경우 경로
          최적화가 진행되지 않습니다.
        </p>
        <Button
          buttonClassName="text-primary-01 bg-white mr-3 h-[36px]"
          text="접수 양식 다운로드"
          icon={<BiSolidDownload />}
          blackBorder
          smallPadding
        />
        <DndFileUpload
          className="my-3"
          uploadFileName={uploadFileName}
          setUploadFileName={setUploadFileName}
        />
        <p className="mb-6 text-secondary-01 text-sm">
          파일은 최대 1개, 파일 용량 100MB, 오더 100개까지 업로드 가능합니다.
        </p>
        <div className="flex justify-end">
          <Button
            buttonClassName="text-primary-01 bg-white mr-2 h-[36px] text-sm"
            text="취소"
            blackBorder
            smallPadding
            onClick={onCancel}
          />
          <Button
            buttonClassName={`text-white bg-primary-01 h-[36px] w-[80px] text-sm ${
              uploadFileName ? '' : 'bg-primary-02'
            }`}
            text={
              spinnerState ? (
                <Oval
                  height={20}
                  strokeWidth={6}
                  color="white"
                  secondaryColor="black"
                />
              ) : (
                '파일 업로드'
              )
            }
            disabled={uploadFileName ? false : true}
            smallPadding
            onClick={onClickUploadBtn}
          />
        </div>
      </div>
    </ModalTemplate>
  );
}
