export interface IUserInfo {
  id: number;
  email: string;
  password: string | number;
  businessAddress: string;
  businessAddressDetail: string;
  bAddressX: string | number;
  bAddressY: string | number;
  warehouseAddress?: string;
  warehouseAddressDetail?: string;
  wAddressX?: string | number;
  wAddressY?: string | number;
}

export const userInfoData: IUserInfo = {
  id: 1,
  email: 'busykim2@eatthecompany.com',
  password: 'o2pluss123',
  businessAddress: '제주특별자치도 제주시 애월읍 상귀서길 22',
  businessAddressDetail: '1층',
  bAddressX: 126.404477,
  bAddressY: 33.4701177,
  warehouseAddress: '',
  warehouseAddressDetail: '',
  wAddressX: '',
  wAddressY: '',
} as const;
