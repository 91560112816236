import { VEHICLE_STATUS } from '@constants/common';
import { useState, useEffect } from 'react';
import { IRiderVehicleInfo } from '@interfaces/info.interface';
import { vehicleInfoData } from '@data/vehicleData';

import Button from '@components/atom/Button';
import { FaPlus } from 'react-icons/fa6';
import ManagementCard from '@components/molecules/ManagementCard';

export default function VehicleMgt() {
  const [selectVehicleStatus, setSeletVehicleStatus] = useState<string>(
    VEHICLE_STATUS.ALL,
  );

  const [useVehicleData, setUseVehicleData] =
    useState<IRiderVehicleInfo[]>(vehicleInfoData);

  const statusList: string[] = [];

  for (const value of Object.values(VEHICLE_STATUS)) {
    statusList.push(value);
  }

  /** 기사 관리 필터 */
  const filteredVehicleInfo = () => {
    const filtered = vehicleInfoData.filter(
      (data) =>
        data.status === selectVehicleStatus ||
        selectVehicleStatus === VEHICLE_STATUS.ALL,
    );

    setUseVehicleData(filtered);
  };

  useEffect(() => {
    filteredVehicleInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectVehicleStatus]);

  return (
    <div className="w-[45%]">
      <div className="text-right mb-6">
        <Button
          buttonClassName="bg-primary-01 text-white mr-3 h-[48px]"
          text="차량 등록"
          icon={<FaPlus className="mr-1" />}
        />
      </div>

      <div className="mb-6">
        {statusList.map((list, index) => (
          <Button
            key={index}
            buttonClassName="h-[32px] mr-1"
            text={list}
            chip
            grayBorder
            blackBorder={selectVehicleStatus === list ? true : false}
            smallPadding
            bold={false}
            onClick={() => setSeletVehicleStatus(list)}
          />
        ))}
      </div>

      <div className="grid grid-cols-2 grid-flow-row gap-4">
        {useVehicleData.map((data, index) => (
          <ManagementCard
            key={index}
            name={data.name}
            status={data.status}
            number={data.number}
            memo={data.memo}
          />
        ))}
      </div>
    </div>
  );
}
