/**
 * @file: ManagementCard.tsx
 * @author: chad / chad@o2pluss.com
 * @since: 2024.02.27 ~
 * @description: 기사, 차량 관리에 쓰이는 카드 컴포넌트
 */

import { DELIVERY_STATUS, VEHICLE_STATUS } from '@constants/common';

// import { IoMdMore } from 'react-icons/io';
import { FiTruck } from 'react-icons/fi';
import { MdPhone } from 'react-icons/md';
import Card from '@components/atom/Card';

interface IProps {
  isRider?: boolean;
  name: string;
  status: string;
  number: string | number;
  memo: string | null;
  onClickMoreBtn?: () => void;
}

export default function ManagementCard({
  isRider = false,
  name,
  status,
  number,
  memo,
}: IProps) {
  return (
    <Card cardClassName="h-[204px]">
      <div className="flex justify-between items-center mb-1">
        <span className="font-bold">{name}</span>
        {/* <IoMdMore
          className="font-bold cursor-pointer"
          size="24px"
          onClick={onClickMoreBtn}
        /> */}
      </div>

      <div className="flex items-center mb-4">
        <div
          className={`w-2 h-2 rounded-xl mr-1 ${
            status === DELIVERY_STATUS.DELIVERY ||
            status === VEHICLE_STATUS.DELIVERY
              ? 'bg-blue-500'
              : 'bg-gray-400'
          }`}
        />
        <span className="text-sm">{status}</span>
      </div>

      <div className="flex gap-1 items-center text-[#676767] mb-2">
        {isRider ? <MdPhone /> : <FiTruck />}
        <span className="text-sm">{number}</span>
      </div>

      <div className="text-[#676767] text-sm">{memo ? memo : '메모없음'}</div>
    </Card>
  );
}
