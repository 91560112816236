/**
 * @file CreateRouteTableHeader
 * @author chad / chad@o2pluss.com
 * @description
 */

import { CREATE_ROUTE_TABLE_HEADER } from '@constants/routeOptimization';

import Card from '@components/atom/Card';
import Checkbox from '@components/atom/Checkbox';
import {
  useRouteAllSelected,
  useRouteGroup,
  useRouteSelected,
} from '@hooks/recoil/useRouteOrder';
import { ChangeEvent, useCallback, useEffect } from 'react';

export default function CreateRouteTableHeader() {
  const { isAllSelected, setIsAllSelected } = useRouteAllSelected();
  const { orderGroupList } = useRouteGroup();
  const { setSelectedList } = useRouteSelected();

  const TABLE_HEADER: string[] = [];

  for (const value of Object.values(CREATE_ROUTE_TABLE_HEADER)) {
    TABLE_HEADER.push(value);
  }

  /** 전체 선택 */
  const allSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const dongList: string[] = [];

    orderGroupList.forEach((sido_item: any) => {
      sido_item.sigungu.forEach((sigungu_item: any) => {
        sigungu_item.dong.forEach((dong_item: any) => {
          dongList.push(dong_item.dongName);
        });
      });
    });

    checked ? allChecked(dongList) : unAllChecked();
  };

  /** 전체 선택 시 전역변수에 값 넣기 */
  const allChecked = useCallback(
    (dongList: string[]) => {
      setSelectedList(dongList);
      setIsAllSelected(true);
    },
    [setIsAllSelected, setSelectedList],
  );

  /** 전체 선택 해제 */
  const unAllChecked = useCallback(() => {
    setSelectedList([]);
    setIsAllSelected(false);
  }, [setIsAllSelected, setSelectedList]);

  useEffect(() => {
    return () => {
      setSelectedList([]);
      setIsAllSelected(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card cardClassName="flex mb-3 !px-3 py-1 !bg-[#FCFCFC] items-center h-[36px]">
      <Checkbox checked={isAllSelected} onChange={(e) => allSelected(e)} />
      {TABLE_HEADER.map((value, index) => {
        return (
          <div
            className={`px-2 text-secondary-01 text-xs ${
              index === 0 ? 'w-[15%]' : index === 1 ? 'w-[45%]' : 'w-[20%]'
            }`}
            key={value}
          >
            {value}
          </div>
        );
      })}
    </Card>
  );
}
